import checkout from "./form";

const {
  formField: {
    firstName,
    lastName,
    state,
    gender,
    email,
    phone,
    address1,
    address2,
    city,
    zip,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [state.name]: "",
  [email.name]: "",
  [phone.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zip.name]: "",
  [gender.name]: ""
};

export default initialValues;
