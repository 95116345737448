import React from 'react'
import { Card } from '@mui/material';

import MediIDCardBG from "assets/images/medi-id-card-bg.svg"
import brandLandScape from "assets/images/brand-logo-landscape.svg"
import { formatPhoneNumber } from 'utils/constant';
import { QRCode } from 'layouts/pages/wallet/components/GenerateCard/QRCode';

export const CardFront = ({ data }) => {

    return (
        <div>
            <Card p={0} style={{ border: '1px solid #AAAAAA', boxShadow: 'none', backgroundImage: `url(${MediIDCardBG})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'left bottom', backgroundSize: 'contain' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', padding: '15px 20px' }}>
                    <div>
                        <div style={{ marginBottom: 10 }}>
                            <img src={brandLandScape} alt="MediDocProBrand" style={{ width: 100 }} />
                        </div>
                        <div style={{ color: '#344767', fontSize: 9.5, fontWeight: 300 }}>Member Name</div>
                        <div style={{ display: 'flex', height: 120, flexDirection: 'column', alignItems: 'self-start', justifyContent: 'space-between' }}>
                            <div>
                                <h4 style={{ fontWeight: 700, fontSize: 17, color: '#344767', textTransform: "capitalize" }}>
                                    {data?.FirstName} {data?.LastName}
                                </h4>
                                <h6 style={{ fontWeight: 700, color: '#344767', fontSize: 11 }}>{data ? data?.SMSCapableNumber : null}</h6>
                            </div>
                            <div>
                                <div style={{ color: '#344767', fontSize: 9.5, fontWeight: 300, marginTop: 10 }}>Emergency Contact</div>
                                <div>
                                    <h6 style={{ color: '#344767', fontWeight: 700, textTransform: "capitalize", marginRight: 10, fontSize: 11 }}>
                                        {data?.EmergencyContact?.EmergencyContact_FirstName} {data?.EmergencyContact?.EmergencyContact_LastName}
                                    </h6>
                                    <h6 style={{ color: '#344767', fontWeight: 700, fontSize: 11, marginTop: 5 }}>
                                        {data ? data?.EmergencyContact?.EmergencyContact_Phone : null}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: 115 }}>
                        {data ? <QRCode UserId={data?.Aspnet_UserId} text="Scan for Profile" /> : null}
                    </div>
                </div>
            </Card>
        </div>
    )
}
