// read date to display local time on UI
export const fixTimezoneOffset = (date) => {
  if (!date) return "";
  return moment.utc(date).local().format();
};

export const localTimeTimeUTC = (date = "") => {
  if (!date || !moment.utc(date).isValid()) {
    return "";
  }

  return moment.utc(date).local().format("MM/DD/YYYY h:mm A");
};

export const downloadJSONToCSVWithFileName = (data, fileName) => {
  let blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};