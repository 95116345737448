import { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material"
import { Form, Formik } from "formik";

import SoftBox from "components/SoftBox"
import SoftButton from "components/SoftButton";
import FormField from "../FormField";
import form from "../schemas/form";
import validations from "../schemas/validations";
import initialValues from "../schemas/initialValues";
import { StateList } from "layouts/authentication/enrollment/components/PersonalInfo/StateList";
import { request } from "redux/actions/request";
import { fetchUserInformation } from "redux/actions/users";
import { useDispatch } from "react-redux";

const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]

function EmergencyFormField(props) {
    const { values, errors, touched, setFieldValue, formField, setErrors, data, isProfile } = props
    const [selectedStatus, setSelectedStatus] = useState(null);
    const statusOptions = StateList;
    const { firstName, lastName, phone, address1, address2, city, state, email, zip } = formField;

    useEffect(() => {
        preFilledEmergencyContact()
    }, [data])

    function preFilledEmergencyContact() {
        if (data && data.EmergencyContact) {
            let state = StateList.filter(x => x.value === data.EmergencyContact.EmergencyContact_State)
            setFieldValue("firstName", data.EmergencyContact.EmergencyContact_FirstName)
            setFieldValue("lastName", data.EmergencyContact.EmergencyContact_LastName)
            setFieldValue("email", data.EmergencyContact.EmergencyContact_Email)
            setFieldValue("phone", data.EmergencyContact.EmergencyContact_Phone)
            setFieldValue("address1", data.EmergencyContact.EmergencyContact_Street1)
            setFieldValue("address2", data.EmergencyContact.EmergencyContact_Street2)
            setFieldValue("city", data.EmergencyContact.EmergencyContact_City)
            setFieldValue("state", state.length ? { label: state[0].label, value: state[0].value } : null)
            setFieldValue("zip", data.EmergencyContact.EmergencyContact_PostalCode)
            setSelectedStatus(state.length ? { label: state[0].label, value: state[0].value } : null)
            setTimeout(() => setErrors({}), 0)
        }
    }

    return (
        <SoftBox>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                type={firstName.type}
                                label={firstName.label}
                                name={firstName.name}
                                value={values.firstName}
                                placeholder={firstName.placeholder}
                                error={errors.firstName && touched.firstName}
                                success={values.firstName.length > 0 && !errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                type={lastName.type}
                                label={lastName.label}
                                name={lastName.name}
                                value={values.lastName}
                                placeholder={lastName.placeholder}
                                error={errors.lastName && touched.lastName}
                                success={values.lastName.length > 0 && !errors.lastName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <FormField
                        fieldType="phone"
                        id={phone.name}
                        helper="US Number Only"
                        type={phone.type}
                        name={phone.name}
                        setFieldValue={setFieldValue}
                        label={phone.label}
                        value={values.phone}
                        placeholder={phone.placeholder}
                        error={errors.phone && touched.phone}
                        success={values.phone.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={values.email}
                        placeholder={email.placeholder}
                        error={errors.email && touched.email}
                        success={values.email.length > 0 && !errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <FormField
                        type={address1.type}
                        label={address1.label}
                        name={address1.name}
                        value={values.address1}
                        placeholder={address1.placeholder}
                        error={errors.address1 && touched.address1}
                        success={values.address1.length > 0 && !errors.address1}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <SoftBox mt={4}>
                        <FormField
                            type={address2.type}
                            name={address2.name}
                            value={values.address2}
                            label=""
                            placeholder={address2.placeholder}
                        />
                    </SoftBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={city.type}
                        name={city.name}
                        value={values.city}
                        label=""
                        placeholder={city.placeholder}
                        error={errors.city && touched.city}
                        success={values.city.length > 0 && !errors.city}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <FormField
                                fieldType="select"
                                type={state.type}
                                label={""}
                                name={state.name}
                                options={statusOptions}
                                defaultValue={selectedStatus}
                                value={selectedStatus}
                                onChange={(value) => {
                                    setSelectedStatus(value)
                                    setFieldValue("state", value)
                                }}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                placeholder={state.placeholder}
                                error={errors.state && touched.state}
                                success={values.state.length > 0 && !errors.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField
                                type={zip.type}
                                name={zip.name}
                                label=""
                                value={values.zip}
                                placeholder={zip.placeholder}
                                error={errors.zip && touched.zip}
                                success={values.zip.length > 0 && !errors.zip}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

function EmergencyForm(props) {
    const { setOpen, data, setVisible, setAlert } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { formField } = form;
    const currentValidation = validations[0]

    const handleSubmit = async (values, actions) => {
        setLoading(true)
        let payload = {
            UserId: data.Aspnet_UserId,
            Contact_LastName: values.lastName,
            Contact_FirstName: values.firstName,
            Contact_Email: values.email,
            Contact_Phone: values.phone.toString(),
            Contact_Street1: values.address1,
            Contact_Street2: values.address2,
            Contact_City: values.city,
            Contact_State: values.state.value,
            Contact_PostalCode: values.zip.toString(),
            Contact_Type: "EmergencyContact"
        }
        if (data.EmergencyContact) payload.ContactId = data.EmergencyContact.EmergencyContact_ID;
        request(
            data.EmergencyContact
                ? "User/UpdateUserContact"
                : "User/AddUserContact",
            data.EmergencyContact
                ? "PUT"
                : "POST", payload)
            .then(async () => {
                await dispatch(fetchUserInformation(data?.EmailAddress));
                setAlert({
                    heading: "Changes Successfully Saved",
                    text: "Your emergency information has been saved.",
                    isError: false
                })
                setLoading(false)
                setOpen(false)
                setVisible(true)
            })
            .catch((error) => {
                setAlert({
                    heading: "Error",
                    text: error,
                    isError: true
                })
                setVisible(true)
                // setVisible(false)
                setLoading(false)
            })
    }

    return (
        <Formik initialValues={initialValues} validationSchema={currentValidation} onSubmit={handleSubmit}>
            {({ values, errors, touched, setFieldValue, setErrors }) => (
                <Form id="EmergencyContact" autoComplete="off">
                    <EmergencyFormField values={values} errors={errors} touched={touched} setFieldValue={setFieldValue} formField={formField} setErrors={setErrors} data={data} />
                    <SoftBox display="flex" justifyContent="end" mt={2}>
                        <SoftButton
                            variant="text"
                            color="dark"
                            type="button"
                            onClick={() => setOpen(false)}
                            size="medium"
                            style={{ color: "#333333", textTransform: "capitalize", marginTop: 5, fontWeight: 500 }}
                            circular={true}>Cancel</SoftButton>
                        <SoftButton
                            variant="gradient"
                            color="info"
                            size="medium"
                            type="submit"
                            disabled={loading}
                            style={{ textTransform: "capitalize", fontWeight: 500, marginLeft: 10 }}
                            circular={true}>
                            {loading && <CircularProgress size={14} color="info" />}
                            {!loading && "Save Changes"}
                        </SoftButton>
                    </SoftBox>
                </Form>
            )}
        </Formik>
    )
}

export default EmergencyForm