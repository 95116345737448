export const Second = () => {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.71582" width="25" height="25" rx="12.5" fill="white" />
            <path d="M8.416 19.2158H17.152V16.8798H13.104C15.28 14.5278 16.656 12.7838 16.656 11.0238C16.656 8.67182 14.976 7.00782 12.4 7.00782C11.072 7.00782 9.824 7.50382 9.088 8.14382V10.7518C9.76 9.99982 10.8 9.40782 11.936 9.40782C13.184 9.40782 13.968 10.2558 13.968 11.2958C13.968 12.4958 13.504 13.3118 8.416 19.2158Z" fill="url(#paint0_linear_3067_4165)" />
            <rect x="0.5" y="0.71582" width="25" height="25" rx="12.5" stroke="url(#paint1_linear_3067_4165)" />
            <defs>
                <linearGradient id="paint0_linear_3067_4165" x1="8" y1="1.21582" x2="25.0414" y2="8.31641" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B5E2" />
                    <stop offset="1" stopColor="#032FCA" />
                </linearGradient>
                <linearGradient id="paint1_linear_3067_4165" x1="0" y1="0.21582" x2="26" y2="26.2158" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B5E2" />
                    <stop offset="1" stopColor="#032FCA" />
                </linearGradient>
            </defs>
        </svg>

    )
}