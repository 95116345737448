import { useEffect, useState } from 'react'
import { Card } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'

const AlertPop = (props) => {
    const { children, heading, isWarning, isDanger, isOpen, timer, toggle } = props
    const [visible, setVisible] = useState(isOpen);

    useEffect(() => {
        setVisible(isOpen);
        if (timer && isOpen) {
            let _setTime = setTimeout(() => {
                setVisible(!visible);
                if (toggle) toggle(false);
            }, timer);

            return () => {
                clearTimeout(_setTime);
            };
        }
    }, [isOpen, visible, timer, toggle])

    return (
        <>{visible && <Card style={{ position: 'fixed', zIndex: 9999, maxWidth: '35%', minWidth: 350, top: 20, right: 20, boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: 8, background: isWarning ? '#FEEBC8' : isDanger ? '#FED7D7' : '#C6F6D5' }}>
            <SoftBox px={6} py={3}>
                <SoftBox style={{ position: 'absolute', left: 18 }}>
                    {isWarning
                        ? <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.0509 15.7083L11.062 0.872402C10.4958 -0.179473 8.98734 -0.179473 8.42063 0.872402L0.432194 15.7083C0.309246 15.9367 0.247606 16.193 0.25329 16.4523C0.258974 16.7116 0.331787 16.965 0.464622 17.1877C0.597458 17.4105 0.785777 17.595 1.0112 17.7232C1.23662 17.8514 1.49144 17.9191 1.75079 17.9194H17.73C17.9895 17.9195 18.2447 17.8521 18.4704 17.7241C18.6961 17.596 18.8848 17.4115 19.0179 17.1887C19.151 16.9659 19.224 16.7124 19.2298 16.4529C19.2356 16.1934 19.174 15.9369 19.0509 15.7083ZM9.74156 15.6225C9.55614 15.6225 9.37489 15.5676 9.22072 15.4646C9.06655 15.3615 8.94638 15.2151 8.87543 15.0438C8.80447 14.8725 8.7859 14.684 8.82208 14.5022C8.85825 14.3203 8.94754 14.1532 9.07865 14.0221C9.20976 13.891 9.37681 13.8017 9.55867 13.7656C9.74052 13.7294 9.92902 13.748 10.1003 13.8189C10.2716 13.8899 10.4181 14.01 10.5211 14.1642C10.6241 14.3184 10.6791 14.4996 10.6791 14.685C10.6791 14.8082 10.6548 14.9301 10.6077 15.0438C10.5606 15.1576 10.4915 15.2609 10.4045 15.348C10.3174 15.435 10.2141 15.5041 10.1003 15.5512C9.98659 15.5983 9.86468 15.6225 9.74156 15.6225ZM10.7597 6.19365L10.4906 11.9124C10.4906 12.1113 10.4116 12.3021 10.271 12.4427C10.1303 12.5834 9.93954 12.6624 9.74063 12.6624C9.54171 12.6624 9.35095 12.5834 9.2103 12.4427C9.06964 12.3021 8.99063 12.1113 8.99063 11.9124L8.72156 6.19599C8.71552 6.05939 8.73703 5.92298 8.78483 5.79487C8.83263 5.66676 8.90573 5.54959 8.99978 5.45034C9.09383 5.35109 9.20689 5.27179 9.33224 5.21716C9.45759 5.16254 9.59265 5.13371 9.72938 5.1324H9.73922C9.87688 5.13233 10.0131 5.16015 10.1397 5.21418C10.2663 5.26821 10.3807 5.34733 10.4759 5.44677C10.5711 5.54622 10.6451 5.66391 10.6936 5.79276C10.742 5.92161 10.7639 6.05894 10.7578 6.19646L10.7597 6.19365Z" fill="#ED8936" />
                        </svg>
                        : isDanger
                            ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0.249023C4.6239 0.249023 0.25 4.62293 0.25 9.99902C0.25 15.3751 4.6239 19.749 10 19.749C15.3761 19.749 19.75 15.3751 19.75 9.99902C19.75 4.62293 15.3761 0.249023 10 0.249023ZM10.9375 15.2448H9.0625V13.3698H10.9375V15.2448ZM10.75 12.249H9.25L8.96875 4.74902H11.0312L10.75 12.249Z" fill="#C53030" />
                            </svg>
                            : <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 0.249512C4.6239 0.249512 0.25 4.62342 0.25 9.99951C0.25 15.3756 4.6239 19.7495 10 19.7495C15.3761 19.7495 19.75 15.3756 19.75 9.99951C19.75 4.62342 15.3761 0.249512 10 0.249512ZM15.0742 6.73185L8.77422 14.2319C8.70511 14.3142 8.61912 14.3807 8.52207 14.4268C8.42502 14.473 8.31918 14.4978 8.21172 14.4995H8.19906C8.09394 14.4995 7.99 14.4773 7.89398 14.4345C7.79796 14.3917 7.71202 14.3292 7.64172 14.2511L4.94172 11.2511C4.87315 11.1783 4.81981 11.0926 4.78483 10.999C4.74985 10.9054 4.73395 10.8057 4.73805 10.7058C4.74215 10.6059 4.76617 10.5079 4.8087 10.4174C4.85123 10.327 4.91142 10.2459 4.98572 10.1791C5.06002 10.1122 5.14694 10.0609 5.24136 10.0281C5.33578 9.99529 5.43581 9.9817 5.53556 9.98812C5.63531 9.99453 5.73277 10.0208 5.82222 10.0654C5.91166 10.1101 5.99128 10.1721 6.0564 10.2479L8.17937 12.6067L13.9258 5.76717C14.0547 5.61814 14.237 5.52582 14.4335 5.51017C14.6299 5.49452 14.8246 5.55679 14.9754 5.68352C15.1263 5.81026 15.2212 5.99127 15.2397 6.18744C15.2582 6.3836 15.1988 6.57917 15.0742 6.73185Z" fill="#2F855A" />
                            </svg>
                    }
                </SoftBox>
                <SoftBox>
                    <SoftTypography mb={0.3} variant="h6" fontWeight="bold" display="block" fontSize={15}>{heading}</SoftTypography>
                    <SoftTypography variant="caption" fontWeight="regular" display="block" fontSize={12}>{children}</SoftTypography>
                </SoftBox>
                <SoftBox style={{ position: 'absolute', right: 15, top: 5, cursor: 'pointer' }} onClick={() => toggle(false)}>
                    <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.59093 6.99902L13.0441 2.5459C13.2554 2.33492 13.3743 2.04864 13.3745 1.75002C13.3748 1.45139 13.2564 1.1649 13.0455 0.953553C12.8345 0.742209 12.5482 0.623329 12.2496 0.623065C11.951 0.622801 11.6645 0.741175 11.4531 0.952147L6.99999 5.40527L2.54687 0.952147C2.33553 0.740802 2.04888 0.62207 1.75 0.62207C1.45111 0.62207 1.16447 0.740802 0.953123 0.952147C0.741779 1.16349 0.623047 1.45014 0.623047 1.74902C0.623047 2.04791 0.741779 2.33455 0.953123 2.5459L5.40625 6.99902L0.953123 11.4521C0.741779 11.6635 0.623047 11.9501 0.623047 12.249C0.623047 12.5479 0.741779 12.8345 0.953123 13.0459C1.16447 13.2572 1.45111 13.376 1.75 13.376C2.04888 13.376 2.33553 13.2572 2.54687 13.0459L6.99999 8.59277L11.4531 13.0459C11.6645 13.2572 11.9511 13.376 12.25 13.376C12.5489 13.376 12.8355 13.2572 13.0469 13.0459C13.2582 12.8345 13.3769 12.5479 13.3769 12.249C13.3769 11.9501 13.2582 11.6635 13.0469 11.4521L8.59093 6.99902Z" fill="black" />
                    </svg>
                </SoftBox>
            </SoftBox>
        </Card>}</>
    )
}

// function AlertPop({ children, heading, isWarning, isDanger, setOpen }) {

//     return (
//         <Card style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)', borderRadius: 8, background: isWarning ? '#FEEBC8' : isDanger ? '#FED7D7' : '#C6F6D5' }}>
//             <SoftBox px={6} py={3}>
//                 <SoftBox style={{ position: 'absolute', left: 18 }}>
//                     {isWarning && <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M19.0509 15.7083L11.062 0.872402C10.4958 -0.179473 8.98734 -0.179473 8.42063 0.872402L0.432194 15.7083C0.309246 15.9367 0.247606 16.193 0.25329 16.4523C0.258974 16.7116 0.331787 16.965 0.464622 17.1877C0.597458 17.4105 0.785777 17.595 1.0112 17.7232C1.23662 17.8514 1.49144 17.9191 1.75079 17.9194H17.73C17.9895 17.9195 18.2447 17.8521 18.4704 17.7241C18.6961 17.596 18.8848 17.4115 19.0179 17.1887C19.151 16.9659 19.224 16.7124 19.2298 16.4529C19.2356 16.1934 19.174 15.9369 19.0509 15.7083ZM9.74156 15.6225C9.55614 15.6225 9.37489 15.5676 9.22072 15.4646C9.06655 15.3615 8.94638 15.2151 8.87543 15.0438C8.80447 14.8725 8.7859 14.684 8.82208 14.5022C8.85825 14.3203 8.94754 14.1532 9.07865 14.0221C9.20976 13.891 9.37681 13.8017 9.55867 13.7656C9.74052 13.7294 9.92902 13.748 10.1003 13.8189C10.2716 13.8899 10.4181 14.01 10.5211 14.1642C10.6241 14.3184 10.6791 14.4996 10.6791 14.685C10.6791 14.8082 10.6548 14.9301 10.6077 15.0438C10.5606 15.1576 10.4915 15.2609 10.4045 15.348C10.3174 15.435 10.2141 15.5041 10.1003 15.5512C9.98659 15.5983 9.86468 15.6225 9.74156 15.6225ZM10.7597 6.19365L10.4906 11.9124C10.4906 12.1113 10.4116 12.3021 10.271 12.4427C10.1303 12.5834 9.93954 12.6624 9.74063 12.6624C9.54171 12.6624 9.35095 12.5834 9.2103 12.4427C9.06964 12.3021 8.99063 12.1113 8.99063 11.9124L8.72156 6.19599C8.71552 6.05939 8.73703 5.92298 8.78483 5.79487C8.83263 5.66676 8.90573 5.54959 8.99978 5.45034C9.09383 5.35109 9.20689 5.27179 9.33224 5.21716C9.45759 5.16254 9.59265 5.13371 9.72938 5.1324H9.73922C9.87688 5.13233 10.0131 5.16015 10.1397 5.21418C10.2663 5.26821 10.3807 5.34733 10.4759 5.44677C10.5711 5.54622 10.6451 5.66391 10.6936 5.79276C10.742 5.92161 10.7639 6.05894 10.7578 6.19646L10.7597 6.19365Z" fill="#ED8936" />
//                     </svg>}
//                     {isDanger && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M10 0.249023C4.6239 0.249023 0.25 4.62293 0.25 9.99902C0.25 15.3751 4.6239 19.749 10 19.749C15.3761 19.749 19.75 15.3751 19.75 9.99902C19.75 4.62293 15.3761 0.249023 10 0.249023ZM10.9375 15.2448H9.0625V13.3698H10.9375V15.2448ZM10.75 12.249H9.25L8.96875 4.74902H11.0312L10.75 12.249Z" fill="#C53030" />
//                     </svg>
//                     }
//                     {!isWarning || !isDanger && <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M10 0.249512C4.6239 0.249512 0.25 4.62342 0.25 9.99951C0.25 15.3756 4.6239 19.7495 10 19.7495C15.3761 19.7495 19.75 15.3756 19.75 9.99951C19.75 4.62342 15.3761 0.249512 10 0.249512ZM15.0742 6.73185L8.77422 14.2319C8.70511 14.3142 8.61912 14.3807 8.52207 14.4268C8.42502 14.473 8.31918 14.4978 8.21172 14.4995H8.19906C8.09394 14.4995 7.99 14.4773 7.89398 14.4345C7.79796 14.3917 7.71202 14.3292 7.64172 14.2511L4.94172 11.2511C4.87315 11.1783 4.81981 11.0926 4.78483 10.999C4.74985 10.9054 4.73395 10.8057 4.73805 10.7058C4.74215 10.6059 4.76617 10.5079 4.8087 10.4174C4.85123 10.327 4.91142 10.2459 4.98572 10.1791C5.06002 10.1122 5.14694 10.0609 5.24136 10.0281C5.33578 9.99529 5.43581 9.9817 5.53556 9.98812C5.63531 9.99453 5.73277 10.0208 5.82222 10.0654C5.91166 10.1101 5.99128 10.1721 6.0564 10.2479L8.17937 12.6067L13.9258 5.76717C14.0547 5.61814 14.237 5.52582 14.4335 5.51017C14.6299 5.49452 14.8246 5.55679 14.9754 5.68352C15.1263 5.81026 15.2212 5.99127 15.2397 6.18744C15.2582 6.3836 15.1988 6.57917 15.0742 6.73185Z" fill="#2F855A" />
//                     </svg>}
//                 </SoftBox>
//                 <SoftBox>
//                     <SoftTypography mb={0.3} variant="h6" fontWeight="bold" display="block" fontSize={15}>{heading}</SoftTypography>
//                     <SoftTypography variant="caption" fontWeight="regular" display="block" fontSize={12}>{children}</SoftTypography>
//                 </SoftBox>
//                 <SoftBox style={{ position: 'absolute', right: 15, top: 5, cursor: 'pointer' }} onClick={() => setOpen(false)}>
//                     <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M8.59093 6.99902L13.0441 2.5459C13.2554 2.33492 13.3743 2.04864 13.3745 1.75002C13.3748 1.45139 13.2564 1.1649 13.0455 0.953553C12.8345 0.742209 12.5482 0.623329 12.2496 0.623065C11.951 0.622801 11.6645 0.741175 11.4531 0.952147L6.99999 5.40527L2.54687 0.952147C2.33553 0.740802 2.04888 0.62207 1.75 0.62207C1.45111 0.62207 1.16447 0.740802 0.953123 0.952147C0.741779 1.16349 0.623047 1.45014 0.623047 1.74902C0.623047 2.04791 0.741779 2.33455 0.953123 2.5459L5.40625 6.99902L0.953123 11.4521C0.741779 11.6635 0.623047 11.9501 0.623047 12.249C0.623047 12.5479 0.741779 12.8345 0.953123 13.0459C1.16447 13.2572 1.45111 13.376 1.75 13.376C2.04888 13.376 2.33553 13.2572 2.54687 13.0459L6.99999 8.59277L11.4531 13.0459C11.6645 13.2572 11.9511 13.376 12.25 13.376C12.5489 13.376 12.8355 13.2572 13.0469 13.0459C13.2582 12.8345 13.3769 12.5479 13.3769 12.249C13.3769 11.9501 13.2582 11.6635 13.0469 11.4521L8.59093 6.99902Z" fill="black" />
//                     </svg>
//                 </SoftBox>
//             </SoftBox>
//         </Card>
//     )
// }

// AlertPop.propTypes = {
//     heading: PropTypes.string.isRequired,
//     children: PropTypes.any,
//     isWarning: PropTypes.boolean,
//     isDanger: PropTypes.boolean,
//     setOpen: PropTypes.any
// };

export default AlertPop;
