import React, { useEffect, useState } from 'react';
import { Card, Tabs, Tab, Box, Tooltip, Grid } from "@mui/material"
import { useSelector } from "react-redux";

import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import SoftButton from "components/SoftButton"
import AccountTab from './AccountTab';
import { Personal } from './Personal';
import { Emergency } from './Emergency';
import { Password } from './Password';

import "./style.css"
import UpdateAccount from '../UpdateAccount';
import AlertPop from 'layouts/authentication/sign-in/basic/components/LoginInfo/Alert';
import BillingInfo from './BillingInfo';
import { stripeSubscriptionCancellation } from 'redux/actions/enrollment/enrollmentAction';
import { useDispatch } from 'react-redux';
import ReusableDialog from 'examples/ReusableDialog';
import moment from 'moment';
import { whitespace } from 'stylis';
import { fontSize } from '@mui/system';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [alert, setAlert] = useState({
    heading: "",
    text: "",
    isError: false
  })

  const [value, setValue] = useState(0)
  const { userInfo: { data } } = useSelector(state => state);
  const [userInfoDetails, setUserInfoDetails] = useState();
  useEffect(() => {
    const getUserDetails = JSON.parse(localStorage.getItem('userInfo'));
    setUserInfoDetails(getUserDetails);
  }, []);

  const convertTimeFormat = (inputDateStr) => {
    const date = moment(inputDateStr);
    const formattedDate = date.format('MM/DD/YYYY');
    return formattedDate;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCancelSubscription = () => {
    const payload = {
      customerId: data?.CustomerId
    }
    dispatch(stripeSubscriptionCancellation(payload)).then((response) => {
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("FullName");
      localStorage.removeItem("Email");
      localStorage.removeItem('userDetails');
      localStorage.removeItem('loginErrResponse');
      setCancelDialogOpen(false);
      window.location.replace(response);
    }).catch((err) => {
      setCancelDialogOpen(false);
    });
  }

  return (
    <SoftBox>
      {visible ? <AlertPop
        isDanger={alert.isError}
        toggle={setVisible}
        timer={7000}
        isOpen={visible}
        heading={alert.heading}>
        {alert.text}
      </AlertPop> : null}
      <SoftBox>
        <ReusableDialog
          // className={!isOverflow ? "is-overflow" : ""}
          isOpen={cancelDialogOpen}
          title="Cancel Subscription"
          handleClose={() => setCancelDialogOpen(false)}
        >
          <SoftBox>
            <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
              You will lose access to your account. Are you sure you want to cancel your subscription?
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={2} width="100%" display="flex" justifyContent="end" gap={2}>
            <SoftButton
              type="button"
              variant="outlined"
              color="info"
              circular={true}
              size="medium"
              onClick={() => setCancelDialogOpen(false)}
            >
              No
            </SoftButton>
            <SoftButton
              type="button"
              variant="gradient"
              color="info"
              circular={true}
              size="medium"
              onClick={handleCancelSubscription}
            >
              Yes
            </SoftButton>
          </SoftBox>
        </ReusableDialog>
        <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
          <SoftBox p={3}>
            <SoftBox justifyContent="space-between" alignItems="center" mb={3}>
              <Grid container>
                <Grid item md={3} xs={12}><SoftTypography textAlign="left" variant="h5" fontWeight="bold" whiteSpace="nowrap">My Account</SoftTypography></Grid>
                <Grid item md={4} xs={12}>{data?.PaymentDate === null && <SoftButton
                  variant="outlined"
                  color="info"
                  type="button"
                  circular={true}
                  size='small'
                  style={{ textTransform: 'capitalize', fontSize: 14, fontWeight: 700, whiteSpace: 'nowrap', marginBottom: '5px' }}
                  onClick={() => setCancelDialogOpen(true)}
                > Cancel Subscription
                </SoftButton>}</Grid>
                <Grid item md={1} xs={12}></Grid>
                <Grid item md={4} xs={12}><SoftButton
                  variant="outlined"
                  color="info"
                  type="button"
                  circular={true}
                  size='small'
                  style={{ textTransform: 'capitalize', fontSize: 14, fontWeight: 700, whiteSpace: 'nowrap' }}
                  onClick={() => setOpen(true)}
                > Update My Account
                </SoftButton></Grid></Grid>
            </SoftBox>

            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderColor: 'divider' }} mb={1}>
                <SoftTypography variant="gradient" fontWeight="bold" color="info">
                  <Tabs value={value} onChange={handleChange} aria-label="Tabs" style={{ backgroundColor: '#fff' }}>
                    <Tab label="Personal Info" style={{fontSize:'12px'}} {...a11yProps(0)} />
                    <Tab label="Emergency Contact Info" style={{fontSize:'12px'}} {...a11yProps(1)} />
                    <Tab label="Payment Info" style={{fontSize:'12px'}} {...a11yProps(2)} />
                    {/* <Tab label="Password" {...a11yProps(3)} /> */}
                  </Tabs>
                </SoftTypography>
              </Box>
              <AccountTab value={value} index={0}>
                <Personal data={data} />
              </AccountTab>
              <AccountTab value={value} index={1}>
                {data && data?.EmergencyContact ? <Emergency data={data} /> : null}
              </AccountTab>
              <AccountTab value={value} index={2}>
                {/* <BillingInfo /> */}
                <SoftBox>
                  <SoftTypography variant="h6" textAlign="left">{`${userInfoDetails?.PaymentDate ? 'Last Payment Date ' + convertTimeFormat(userInfoDetails?.PaymentDate) : 'No payment data available'}`} </SoftTypography>
                </SoftBox>
              </AccountTab>
              <AccountTab value={value} index={3}>
                <Password />
              </AccountTab>
            </Box>
          </SoftBox>
        </Card>
      </SoftBox>
      <SoftBox mt={4}>
        <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
          <SoftBox p={3} display="flex" justifyContent="space-between" alignItems="center">
            <SoftTypography variant="h6" textAlign="left">{`Your next billing date is ${convertTimeFormat(userInfoDetails?.ExpirationDate)}`} </SoftTypography>
            {/* <Tooltip title="Evaluating for a Future Phase" placement="top-end">
              <span>
                <SoftButton
                  variant="outlined"
                  color="info"
                  type="submit"
                  circular={true}
                  size='small'
                  disabled={true}
                  style={{ textTransform: 'capitalize', fontSize: 14, fontWeight: 700 }}
                > Manage Billing
                </SoftButton>
              </span>
            </Tooltip> */}
          </SoftBox>
        </Card>
      </SoftBox>

      {open ? <UpdateAccount data={data} alert={alert} open={open} setOpen={setOpen} setAlert={setAlert} setVisible={setVisible} /> : null}

    </SoftBox>
  );
}