import axios from "axios";
import * as Actions from "../../constants/constants";
import { request } from "../request";

export const fetchRecordApproved = (payload) => (dispatch) => {
    return request(`DocumentRequest/GetApprovedUserDocuments`, "POST", payload)
        .then((response) => {
            dispatch({
                type: Actions.GET_RECORD_APPROVED,
                payload: response.Data,
            });

            return Promise.resolve(response.Data)
        })
        .catch((error) => {
            dispatch({
                type: Actions.GET_RECORD_FAIL,
                payload: error
            })
        });
}

export const fetchUserInfoRecords = (userId) => (dispatch) => {
    // debugger
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}User/GetUserDetailsByQRCode/${userId}`)
        .then(({ data }) => {
            // debugger
            dispatch({
                type: Actions.GET_RECORD_REQUEST,
                payload: data.Data,
            });

            return Promise.resolve(data.Data)
        })
        .catch(error => {
            dispatch({
                type: Actions.FAIL_RECORD_REQUEST,
                payload: error
            })

            return Promise.reject(error)
        })
}