import * as Actions from "../../constants/constants";
import { request } from "../request";

export const fetchDesignatedContact = (userId, contactType) => (dispatch) => {
    return request(`User/GetUserContactListByUserId/${userId}/${contactType}`, "GET", {})
        .then((response) => {
            dispatch({
                type: Actions.GET_DESIGNATED_CONTACT_SUCCESS,
                payload: response.Data,
            });

            return Promise.resolve(response.Data)
        })
        .catch((error) => {
            dispatch({
                type: Actions.GET_DESIGNATED_CONTACT_FAIL,
                payload: error
            })
        });
}