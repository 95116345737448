/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

import { TextField } from "@mui/material";

//  Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftDatePicker from "components/SoftDatePicker";
import SoftPhoneInput from "components/SoftPhoneInput";

function FormField({ label, name, fieldType, helper, ...rest }) {
  return (
    <SoftBox mb={1.5}>
      {/* <SoftInput label={label} name={name} {...rest} /> */}
      {label && <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          fontSize={13.5}
        >
          {label} {helper ? <small style={{ color: "#8b8b8b", fontWeight: 400, fontSize: 10 }}>({helper})</small> : null}
        </SoftTypography>
      </SoftBox>}
      {fieldType === "select"
        ? <Field {...rest} label={label} name={name} as={SoftSelect} />
        : fieldType === "phone"
          ? <Field {...rest} label={label} name={name} as={SoftPhoneInput} />
          : fieldType === "time"
            ? <Field {...rest} label={label} name={name} as={SoftDatePicker} />
            : <Field {...rest} label={label} name={name} as={SoftInput} />
      }

      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
