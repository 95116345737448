import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";
import SoftInput from "components/SoftInput";

export const ViewAccount = (props) => {
  const { open, setOpen, data } = props;
  const userData = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <ReusableDialog
      maxWidth="sm"
      isOpen={open}
      title={
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold" color="dark">
            View Account
          </SoftTypography>
        </SoftBox>
      }
      handleClose={() => setOpen(false)}
    >
      <Grid container rowSpacing={0} mb={3} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={13.5}
                >
                  First Name
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                disabled={true}
                value={data.FirstName}
                placeholder="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={13.5}
                >
                  Last Name
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                disabled={true}
                value={data.LastName}
                placeholder="Last Name"
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={13.5}
                >
                  Phone Number{" "}
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                disabled={true}
                value={data.SMSCapableNumber}
                placeholder="Last Name"
              />
            </Grid> */}
            <Grid item xs={12} sm={12}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={13.5}
                >
                  Email Address
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="email"
                disabled={true}
                value={data.EmailAddress}
                placeholder="Email Address"
              />
            </Grid>
            {/* <Grid item xs={6} sm={6}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize={13.5}
                >
                  Address
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                disabled={true}
                value={data.Street1}
                placeholder="Address"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SoftInput
                type="text"
                disabled={true}
                value={data.Street2}
                placeholder="Address"
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </ReusableDialog>
  );
};
