import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
//  themes
import theme from "assets/theme";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

//  routes
import Basic from "layouts/authentication/sign-in/basic";
import SignUpBasic from "layouts/authentication/sign-up/basic";

//  contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useNavigate } from "react-router";
import useAuth from "config/hooks/useAuth";

// import MainLayout from "config/MainLayout";
// import RequireAuth from "config/RequireAuth";
import SessionTimeOut from "examples/SessionTimeOut";
import Enrollment from "layouts/authentication/enrollment";
import Wallet from "layouts/pages/wallet";
import Profile from "layouts/pages/profile";
import ForgotPassword from "layouts/authentication/forgot-password/basic";
import ResetPassword from "layouts/authentication/reset-password/basic";
import Dashboard from "./layouts/dashboard";
import Docs from "./layouts/pages/mydocs";
import RecordRequest from "layouts/pages/Recordrequest";
import AdminDashboard from "layouts/pages/admindashboard";
import RequestHistory from "layouts/pages/requesthistory";
// import PendingRequest from "layouts/pages/Pendingrequest";
// import RecordRequestApproved from "layouts/pages/Recordrequestapproved";
// import RequestSent from "layouts/pages/Recordrequest/Requestsent";
import UserManagement from "layouts/pages/usermanagement";
import SuccessOrFailureSubscribe from "components/StripeSuccessOrFailureMessage";
import DocumentErrorResponse from "components/DocumentErrorResponse";

// const ROLES = {
//   "User": 2001,
//   "Admin": 5150
// }

export default function App() {
  const { setAuth } = useAuth();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("authToken");
    if (accessToken) {
      const roles = [5150];
      setAuth({
        roles: roles,
        accessToken: accessToken,
      });
      // navigate("/wallet");
    }
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" &&
        pathname !== "/login" &&
        // && pathname !== "/web"
        pathname !== "/enrollment" && (
          <>
            {/* <Sidenav
              color={sidenavColor}
              brand={miniSidenav ? logoSmall : logo}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            /> */}
          </>
        )}
      <Routes>
        <Route exact path="" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Basic />} />
        <Route path="/signup" element={<SignUpBasic />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/stripe-subscription" element={<SuccessOrFailureSubscribe />} />
        <Route path="/document-error-response" element={<DocumentErrorResponse />} />        
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/record-request" element={<RecordRequest />} />
        {/* <Route path="/request-sent" element={<RequestSent/>} /> */}
        {/* <Route path="/record-approved" element={<RecordRequestApproved />} />
        <Route path="/pending-request" element={<PendingRequest />} /> */}

        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route path="/mydocs" element={<MyDocs />} /> */}
        <Route path="/dashboard" element={<Dashboard />}>
          {/* <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            {getRoutes(routes)} */}
          <Route exact path="" element={<Navigate to="/dashboard/profile" />} />
          <Route exact path="profile" element={<Profile />} />
          <Route exact path="mydocs" element={<Docs />} />
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="requests" element={<RequestHistory />} />
          <Route path="users" element={<UserManagement />} />
          {/* <Route exact path="profile" element={<Navigate to="/dashboard/profile" />} /> */}
          {/* <Route exact path="mydocs" element={<Navigate to="/dashboard/mydocs" />} /> */}
          {/* </Route> */}
        </Route>
        {/* <Route path="/" element={<MainLayout />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            {getRoutes(routes)}
            <Route exact path="/" element={<Navigate to="/home" />} />
          </Route>
        </Route> */}
        {/* <Route path="*" element={<Navigate to="/web" replace />} /> */}
      </Routes>

      {pathname !== "/login" && pathname !== "/record-approved" && pathname !== "/record-request" && (
        <>
          <SessionTimeOut />
        </>
      )}
    </ThemeProvider>
  );
}
