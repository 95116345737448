import { CircularProgress } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import "./style.css";

// Set the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const PDFViewer = ({ base64Data, onDocumentLoadSuccess, numPages, isPDF, file }) => {
  return (
    <>
      {isPDF ? (
        <div style={{ height: 600, borderRadius: 8, overflow: "auto" }}>
          <Document
            loading={
              <div
                style={{
                  height: 600,
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={30} color="info" />
              </div>
            }
            file={{ data: atob(base64Data) }} // replace with your base64 encoded pdf data
            onLoadSuccess={onDocumentLoadSuccess}
            className="full-screen"
          >
            {/* <Page pageNumber={1} /> */}
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      ) : (
        <div style={{ height: 600, borderRadius: 8 }} dangerouslySetInnerHTML={{ __html: file }} />
      )}
    </>
  );
};

export default PDFViewer;
