import { Card, IconButton } from '@mui/material';
import { maxWidth } from '@mui/system';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { request } from 'redux/actions/request';
import { useNavigate } from "react-router";
import { useDispatch } from 'react-redux';
import { fetchUserInformation } from 'redux/actions/users';
import { postStripeLogin } from 'redux/actions/loginAction';


const SuccessOrFailureSubscribe = () => {
  const data = {
    success: true
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/wallet";
  const [error, setError] = useState("");
  
  const [loading, setLoading] = useState(false);



  const handleLogin = (values, actions) => {
    localStorage.removeItem('loginErrResponse');
    const getClientRefId = localStorage.getItem("client_reference_id");
    const userEmail = localStorage.getItem("EmailAddress");
    // setError("");
    // let obj = {
    //   username: values.email,
    //   password: values.password,
    //   DeviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
    // };
    // actions.setTouched({});
    // actions.setSubmitting(false);
    let obj = {
      Username: userEmail,
      client_reference_id: getClientRefId
    }
    dispatch(postStripeLogin(obj))
    .then(async (res) => {
      const result = await fetchUserInfo();
      setLoading(true);
      if (result) {
          localStorage.setItem('userInfo', JSON.stringify(result));
          navigate(from, { replace: true });
        }
        // setTimer(7000);
        setLoading(false);
      })
      .catch((err) => {
        // if (localStorage.getItem('loginErrResponse') !== null) {
        //   const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse').length > 0 ? localStorage.getItem('loginErrResponse') : '')
        //   if (loginErrResponse.EmailConfirmed) {
        //     navigate('/enrollment')
        //     return
        //   }
        // }
        // // setEmail(values.email);
        // if (err === "NotVerified") {
        //   setIsActive(true);
        //   setIsWarning(true);
        //   setSuccessHeading(`Email Id has not yet been verified. `);
        //   setIsDanger(false);
        //   setError(err);
        //   setLoading(false);
        //   setTimer(null);
        // } else {
        //   if (localStorage.getItem('loginErrResponse') !== null) {
        //     const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse').length > 0 ? localStorage.getItem('loginErrResponse') : '')
        //     if (!loginErrResponse.EmailConfirmed) {
        //       setIsActive(true);
        //       setIsWarning(true);
        //       setSuccessHeading(`Email Id has not yet been verified. `);
        //       setIsDanger(false);
        //       setError(err);
        //       setLoading(false);
        //       setTimer(null);
        //     }
        //   } else {
        //     // ErrorHandler(err);
        //   }
        //   // setSuccessHeading("Error");
        //   setLoading(false);
        // }
      });
  };


  // const handleLogin = () => {
  //   const getClientRefId = localStorage.getItem("client_reference_id");
  //   const userEmail = localStorage.getItem("EmailAddress");
  //   console.log('getClientRefId', getClientRefId);
  //   console.log('')
  //   const payload = {
  //     Username: userEmail,
  //     client_reference_id: getClientRefId
  //   }
  //   request("Auth/LoginByStripe", "POST", payload)
  //     .then((response) => {
  //       console.log('response', response);
  //       localStorage.setItem('userDetails', JSON.stringify(response.Data));
  //       localStorage.setItem("authToken", response.Data.AuthToken);
  //       localStorage.setItem("refreshToken", response.Data.RefreshToken);
  //       localStorage.setItem("FullName", response.Data.FullName);
  //       localStorage.setItem("Email", response.Data.Email);
  //       const result = fetchUserInfo();
  //       if (result) {
  //         localStorage.setItem('userInfo', JSON.stringify(result));
  //       }
  //       navigate("/wallet");
  //     }) .catch((err) => {
  //       console.log('Err', err);
  //     });
  // }

   // Fetch UserInfo
   async function fetchUserInfo() {
    let email = localStorage.getItem("Email");
    return await dispatch(fetchUserInformation(email));
  }

  return (
    <SoftBox style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2)", padding: '30px', maxWidth: '900px' }}>
        {data.success ?
          <IconButton
            color="success"
            size="50px"
            circular
            sx={{ mr: 2, p: 0 }}
          >
            <SoftTypography textGradient={true} color="success" sx={{ pt: 1.25 }}>
              <span className="material-icons-outlined" style={{ fontSize: 70 }}>
                check_circle
              </span>
            </SoftTypography>
          </IconButton> :
          <IconButton
            color="error"
            size="50px"
            circular
            sx={{ mr: 2, p: 0 }}
          >
            <SoftTypography textGradient={true} color="error" sx={{ pt: 1.25 }}>
              <span className="material-icons-outlined" style={{ fontSize: 70 }}>
                cancel_outlined
              </span>
            </SoftTypography>
          </IconButton>}
        <SoftTypography
          fontWeight="bold"
          fontSize={20}
        >
          {data.success ? 'Thank You for Subscribing.' : 'OOPS! payment failed'}
        </SoftTypography>
        <SoftTypography>
          {data.success ? <SoftTypography
            fontSize={18}>Click here to go to <Link to="">
              <SoftTypography
                variant="caption"
                color="info"
                fontWeight="bold"
                textGradient={true}
                fontSize={18}
                onClick={handleLogin}
              >
                Wallet
              </SoftTypography></Link></SoftTypography> : 'Go back to payment page to activate'}
        </SoftTypography>
      </Card>
    </SoftBox>
  );
};

export default SuccessOrFailureSubscribe;

