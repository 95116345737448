import { authRequest } from "./request";
import {
  LOG_IN_SUCCESS,
  LOGOUT
} from "../constants/constants";

export function postLogin(data) {
  return (dispatch) => {
    return authRequest("Auth/Login", "POST", {
      Username: data.username,
      Password: data.password,
      ReturnUrl: ""
    }).then((response) => {
      dispatch({
        type: LOG_IN_SUCCESS,
        payload: response.Data,
      });
      localStorage.setItem('userDetails', JSON.stringify(response.Data));
      localStorage.setItem("authToken", response.Data.AuthToken);
      localStorage.setItem("refreshToken", response.Data.RefreshToken);
      localStorage.setItem("FullName", response.Data.FullName);
      localStorage.setItem("Email", response.Data.Email);
      return response.Data
    });
  };
}

export function postStripeLogin(data) {
  return (dispatch) => {
    return authRequest("Auth/LoginByStripe", "POST", {
      Username: data.Username,
      client_reference_id: data.client_reference_id,
    }).then((response) => {
      dispatch({
        type: LOG_IN_SUCCESS,
        payload: response.Data,
      });
      localStorage.setItem('userDetails', JSON.stringify(response.Data));
      localStorage.setItem("authToken", response.Data.AuthToken);
      localStorage.setItem("refreshToken", response.Data.RefreshToken);
      localStorage.setItem("FullName", response.Data.FullName);
      localStorage.setItem("Email", response.Data.Email);
      return response.Data
    });
  };
}


export function refreshToken(payLoad) {
  return (dispatch) => {
    return authRequest("Auth/refresh-token", "POST", payLoad).then(
      (response) => {
        dispatch({
          type: LOG_IN_SUCCESS,
          payload: response.Data,
        });
        // debugger
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("FullName");
        localStorage.removeItem("Email");
        localStorage.setItem("authToken", response.Data.AuthToken);
        localStorage.setItem("refreshToken", response.Data.RefreshToken);
        localStorage.setItem("FullName", response.Data.FullName);
        localStorage.setItem("Email", response.Data.Email);
      }
    );
  };
}

export const Logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
