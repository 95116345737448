import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import { Header } from "./Header";
import AdminHeader from "./AdminHeader";
import Footer from "layouts/authentication/components/Footer";

const style = {
  // display: 'flex',
  // minWidth: '0px',
  minHeight: 'calc(100vh - 58px)',
  // flexDirection: 'column',
  // flexGrow: 1,
}

function Dashboard() {
  let userData = JSON.parse(localStorage.getItem("userDetails"));
  return (
    <Grid>
      <SoftBox px={8} py={4} style={style}>
        {userData?.Role && (userData?.Role.toLowerCase() === "administrator" || userData?.Role.toLowerCase() === "superadmin") ? (
          <AdminHeader />
        ) : (
          <Header />
        )}
        <SoftBox mt={5}>
          <Outlet />
        </SoftBox>
      </SoftBox>
      <Footer />
    </Grid>
  );
}

export default Dashboard;
