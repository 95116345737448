import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchQRCode } from "redux/actions/qrcode/qrcodeAction";

export const QRCode = (props) => {
  const { UserId, text } = props;
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    GetQRCode();
  }, []);

  const GetQRCode = async () => {
    let payload = {
      Url: window.location.origin + "/record-request",
      User_Id: UserId,
    };
    const result = await dispatch(fetchQRCode(payload));
    setImageUrl(result);
  };

  return (
    <>
      {imageUrl ? (
        <div
          style={{
            background: "#313860",
            padding: 5,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            borderRadius: 6,
          }}
        >
          <img style={{ width: "100%", height: "auto", borderRadius: 4 }} src={imageUrl} />
          {text ? (
            <span style={{ fontSize: 11, color: "#fff", marginTop: 6, display: "block" }}>
              {text}
            </span>
          ) : null}
        </div>
      ) : (
        <div
          style={{
            minWidth: 145,
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 140,
          }}
        >
          <CircularProgress size={25} color="info" thickness={5} />
        </div>
      )}
    </>
  );
};
