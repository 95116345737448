import { Chip } from "@mui/material";
import classNames from "classnames";
import SoftBox from "components/SoftBox";
import React from "react";

const StatusColumn = ({ status }) => {
  // Icon Renders
  const RenderIcon = (status) => {
    switch (true) {
      case status && status.toLowerCase() === "pending":
        return (
          <span className="material-icons-outlined" style={{ fontSize: 18 }}>
            error_outline
          </span>
        );
      case status && status.toLowerCase() === "approved":
        return (
          <span className="material-icons-outlined" style={{ fontSize: 18 }}>
            check_circle
          </span>
        );
      case status && (status.toLowerCase() === "denied" || status.toLowerCase() === "deny"):
        return (
          <span className="material-icons-outlined" style={{ fontSize: 18 }}>
            close
          </span>
        );
    }
  };
  return (
    <>
      <SoftBox>
        <Chip
          label={(status && (status.toLowerCase() === "deny" || status.toLowerCase() === "denied") ? "Denied" : status) ?? ""}
          size="small"
          color={classNames({
            error: status && (status.toLowerCase() === "denied" || status.toLowerCase() === "deny"),
            default: status && status.toLowerCase() === "pending",
            success: status && status.toLowerCase() === "approved",
          })}
          icon={RenderIcon(status)}
        />
      </SoftBox>
    </>
  );
};

export default StatusColumn;
