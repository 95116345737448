import React from "react";
import { CircularProgress } from "@mui/material";

export const QRCode = ({ data }) => {

  return (
    <>
      {data ? (
        <div
          style={{
            background: "#05CD99",
            padding: 5,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            borderRadius: 6,
          }}
        >
          <img style={{ width: "100%", height: "auto", borderRadius: 4 }} src={data?.Qri} />
          <span style={{ fontSize: 11, color: "#fff", marginTop: 6, display: "block" }}>
            Code Verified
          </span>
        </div>
      ) : (
        <div
          style={{
            width: 145,
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 140,
          }}
        >
          <CircularProgress size={25} color="info" thickness={5} />
        </div>
      )}
    </>
  );
};
