/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

function FormField({ label, name, ...rest }) {
  return (
    <SoftBox mb={1.5}>
      {label && <SoftBox mb={1} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          fontSize={13.5}
        >
          {label}
        </SoftTypography>
      </SoftBox>}
      <SoftBox style={{ position: 'relative' }}>
        <Field {...rest} label={label} name={name} as={SoftInput} />
        <SoftBox style={{ position: 'absolute', top: '100%', right: 0, zIndex: 9 }}>
          {/* <PasswordValidation /> */}
        </SoftBox>
      </SoftBox>
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
