import * as Yup from "yup";
import checkout from "layouts/authentication/enrollment/schemas/form";
import { phoneRegExp } from "utils/constant";

const {
  formField: { firstName, lastName, email, password, repeatPassword, agree, phone, address1, city, state, zip },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [email.name]: Yup.string().required(email.errorMsg).matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, email.invalidMsg),
    // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/, "Password must match to the standard."),
    // [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/, "Password must match to the standard."),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    [agree.name]: Yup.bool().required(agree.invalidMsg).oneOf([true], agree.invalidMsg),
  }),
  Yup.object().shape({
    // [type.name]: Yup.string().required(type.errorMsg),
    [phone.name]: Yup.string().required(phone.errorMsg).matches(phoneRegExp, "Phone number is not valid."),
    [address1.name]: Yup.string().required(address1.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.object().required(state.errorMsg),
    [zip.name]: Yup.string()
      .length(5, "Zip Code is invalid.")
      .matches(/^[0-9]{5}/, "Zip Code is invalid")
      .required(zip.errorMsg)
    // [zip.name]: Yup.string().required(zip.errorMsg).min(5, zip.invalidMsg).matches(/^\d{5}(?:[-\s]\d{4})?$/, "Zip code is invalid.")
  }),
  // Yup.object().shape({
  //   [twitter.name]: Yup.string().required(twitter.errorMsg),
  // }),
];

export default validations;
