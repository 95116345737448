import * as Actions from "../../constants/constants";
import { request } from "../request";

export const fetchRequestedDocumentsList = (payload) => (dispatch) => {
    return request(`AdminDocumentsManagement/GetRequestedDocuments`, "POST", payload)
        .then((response) => {
            dispatch({
                type: Actions.GET_REQUESTED_DOCUMENT_LIST_SUCCESS,
                payload: response.Data,
            });

            return Promise.resolve(response.Data)
        })
        .catch((error) => {
            dispatch({
                type: Actions.GET_REQUESTED_DOCUMENT_LIST_FAIL,
                payload: error
            })
        });
}