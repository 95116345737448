import { useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";
import { request } from "redux/actions/request";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";

export default function ApproveDocument(props) {
  const { data, open, setOpen, setAlert, setVisible, setUpdate } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // share document
  function approveDocumentHandler() {
    setLoading(true);

    let payload;
    if (Array.isArray(data)) {
      payload = data.map((x) => ({
        User_Id: x.User_Id,
        DocumentTypes: x.DocumentTypes,
        Status: "Approved",
        Request_Id: x.DocumentRequest_Id,
      }));
    } else {
      payload = [{
        User_Id: data.User_Id,
        DocumentTypes: data.DocumentTypes,
        Status: "Approved",
        Request_Id: data.DocumentRequest_Id,
      }];
    }
    request("AdminDocumentsManagement/RequestedDocuments", "PUT", payload)
      .then(async () => {
        setUpdate((prev) => !prev);
        setAlert({
          heading: "Request Successfully Approved",
          text: `Request has been sucessfully approved.`,
          isError: false,
          timer: 7000,
        });
        setVisible(true);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        setAlert({
          heading: "Error",
          text: err,
          isError: true,
        });
        setVisible(true);
        setOpen(false);
      });
  }

  // fetch document list
  async function fetchDocuments(count) {
    return await dispatch(fetchDocumentList(count));
  }

  return (
    <ReusableDialog isOpen={open} title="Approve Request" handleClose={() => setOpen(false)}>
      <SoftBox>
        <SoftTypography color="dark" caption="text" fontSize={14} paragraph={true}>
          {data && data.length ? (
            <>Are you sure you want to approve request? You can’t undo this action.</>
          ) : (
            <>
              Are you sure you want to approve request made by {`"${data?.Requestor_FullName}"`}?
              You can’t undo this action.
            </>
          )}
        </SoftTypography>
        <SoftBox display="flex" justifyContent="end" mt={5}>
          <SoftButton
            variant="text"
            color="dark"
            type="button"
            onClick={() => setOpen(false)}
            size="medium"
            style={{ color: "#333333", textTransform: "initial", marginTop: 5, fontWeight: 500 }}
            circular={true}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            size="medium"
            type="submit"
            onClick={approveDocumentHandler}
            disabled={loading}
            style={{ textTransform: "initial", fontWeight: 500, marginLeft: 10 }}
            circular={true}
          >
            {loading && <CircularProgress size={14} color="info" />}
            {!loading && "Approve Request"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </ReusableDialog>
  );
}
