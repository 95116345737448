import { Card, Grid } from "@mui/material"
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import { First } from "./Icons/First"
import CardImage from "./Icons/card.svg"
import { Second } from "./Icons/Second"
import SoftButton from "components/SoftButton"

export const Welcome = ({ setOpen, data }) => {
    return (
        <SoftBox py={3} mb={20}>
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                        <SoftBox p={4}>
                            <SoftTypography mt={2} textAlign="center" variant="h4" fontWeight="bold">Welcome to your MediDoc wallet, <span style={{ textTransform: 'capitalize' }}>{data?.FirstName}</span>!</SoftTypography>
                            <SoftBox mt={2}>
                                <Grid container>
                                    <Grid item xs={12} lg={6}>
                                        <SoftBox display="flex" mt={2}>
                                            <SoftTypography display="inline" mt={0.3}><First /></SoftTypography>
                                            <SoftBox ml={1.5} display="inline">
                                                <SoftTypography variant="h6" fontWeight="bold" color="info" textGradient={true}>Generate your ID card</SoftTypography>
                                                <SoftTypography
                                                    display="block"
                                                    variant="caption"
                                                    fontWeight="light"
                                                    fontSize={14}
                                                    lineHeight={1.5}
                                                >
                                                    It’s your one-stop-shop for managing healthcare docs with ease. You’ll want to ensure that it’s all up-to-date.
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <SoftBox display="flex" justifyContent="center">
                                            <SoftBox px={1} component="img" src={CardImage} />
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} lg={12}>
                                        <SoftBox display="flex" mt={2}>
                                            <SoftTypography display="inline" mt={0.3}><Second /></SoftTypography>
                                            <SoftBox ml={1.5} display="inline">
                                                <SoftTypography variant="h6" fontWeight="bold" color="info" textGradient={true}>Upload any critical documents</SoftTypography>
                                                <SoftTypography
                                                    display="block"
                                                    variant="caption"
                                                    fontWeight="light"
                                                    fontSize={14}
                                                    lineHeight={1.5}
                                                >
                                                    Share any critical documents you have ready by uploading them to your wallet today.
                                                    By doing this, you’ll <strong>always have an indestructible, electronic copy</strong> of the documents related to any type listed below:
                                                </SoftTypography>
                                                <SoftTypography
                                                    mt={2}
                                                    display="block"
                                                    variant="caption"
                                                    fontWeight="light"
                                                    fontSize={14}
                                                >
                                                    <ul style={{ paddingLeft: 22, lineHeight: 1.7 }}>
                                                        <li>Health Care Surrogate</li>
                                                        <li>Power of Attorney</li>
                                                        <li>Do Not Resuscitate</li>
                                                        <li>Identification Documents</li>
                                                        <li>Vaccination Cards</li>
                                                        <li>Medical History</li>
                                                        <li>Other</li>
                                                    </ul>
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <SoftBox mt={4} mb={1} px={2}>
                                    <SoftButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        type="submit"
                                        circular={true}
                                        size='large'
                                        style={{ textTransform: 'capitalize', fontSize: 16, fontWeight: 500 }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Get Started
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}