import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { firstName, lastName, email, state },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [state.name]: Yup.object().required(state.errorMsg),
  }),
];

export default validations;
