import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { CircularProgress, Grid, Checkbox } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import initialValues from "./schemas/initialValues";
import validations from "./schemas/validations";
import form from "./schemas/form";
import { fetchDocumentTypeList } from "redux/actions/wallet/documentTypeAction";

import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import { ContactForm } from "./component/Forms";
import { request } from "redux/actions/request";

import lockopen from "../Recordrequest/icons/lock-open.svg";
import SoftInput from "components/SoftInput";
import { Link } from "react-router-dom";
import { fetehDocumentTypesList } from "redux/actions/wallet/documentTypeAction";

const RequestForm = ({ setSuccess, setEmail, data, isMobile }) => {
  const currentValidation = validations[0];
  const { formField } = form;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [checkboxUpdate, setCheckboxUpdate] = useState(false);
  const [documentList, setDocumentList] = useState(null);
  const [selected, setSelected] = useState(null);
  const [otherTypes, setOtherTypes] = useState(false);
  const [otherDocumentType, setOtherDocumentType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    heading: "",
    text: "",
    isError: false,
  });

  // Document List
  useEffect(() => {
    fetchDocumentType();
  }, []);

  // ** Document List Hook
  useEffect(() => {
    if (documentList) {
      let selectedChecked = documentList.filter((x) => x.checked);
      let otherFiltered = selectedChecked.filter((x) => x.label.toLowerCase() === "other");
      if (otherFiltered.length) setOtherTypes(otherFiltered[0].otherTypeLabel);
      else setOtherTypes(null);
      setSelected(selectedChecked.length);
    }
  }, [checkboxUpdate]);

  // fetch document list
  async function fetchDocumentType() {
    const result = await dispatch(fetehDocumentTypesList(data?.UserId));
    if (result && result !== undefined) {
      let sorted = result.map((x) => ({
        label: x.DocumentType_Name,
        value: x.DocummentType_Id,
        checked: true,
        otherTypeLabel: x.DocumentType_OtherName,
      }));
      let otherFiltered = sorted.filter((x) => x.label.toLowerCase() === "other");
      if (otherFiltered.length) setOtherTypes(otherFiltered[0].otherTypeLabel);
      else setOtherTypes(null);
      setSelected(1);
      setDocumentList(sorted);
    }
  }

  // ** Form Submit Handler
  const handleSubmit = async (values) => {
    if (selected === null || selected === 0) {
      setSelected(0);
      return;
    }
    // if (isOtherSelected && otherDocumentType === null) {
    //     return
    // }
    setLoading(true);
    let selectedDocumentTypeList = documentList.filter((x) => x.checked);
    // let removeOther = selectedDocumentTypeList.filter(x => x.label.toLowerCase() !== "other").map(x => x.label)
    // let newList = ""
    // if (otherDocumentType !== "" && otherDocumentType !== null) newList = removeOther.join(",") + "," + otherDocumentType
    // else newList = removeOther.join(",")
    let types = selectedDocumentTypeList.map((x) => x.label).join(",");
    let payload = {
      User_Id: data.AspnetUserId,
      DocumentTypes: types,
      Requestor_FullName: values.fullName + ' ' + values.lastName,
      FacilityName: values.facilityName,
      Requestor_LicenseNumber: values.licenseNumber,
      Requestor_PhoneNumber: values.phone,
      Requestor_EmailAddress: values.email,
      Requestor_WebsiteUrl: values.company,
      Requestor_Street1: values.address1,
      Requestor_Street2: values.address2,
      Requestor_City: values.city,
      Requestor_State: values.state.value,
      Requestor_Zipcode: values.zip,
    };
    setEmail(values.email);

    request("DocumentRequest/AddorUpdateRequestedDocuments", "POST", payload)
      .then(async () => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setVisible(true);
        setAlert({
          heading: "Error",
          text: error,
          isError: true,
        });
      });
  };
  // ** Handle Click Checkbox
  function controlChecked(list) {
    let newList = documentList;
    let filtered = newList.filter((x) => x.value === list.value);
    let index = newList.findIndex((x) => x.value === list.value);
    newList[index].checked = !filtered[0].checked;
    setDocumentList(newList);
    setCheckboxUpdate(!checkboxUpdate);
  }

  // ** On Change Document Type Event
  function onChangeDocumentType(e) {
    // console.log(e.target.value)
    if (e.target.value !== "") setOtherDocumentType(e.target.value);
    else setOtherDocumentType(null);
  }

  return (
    <SoftBox>
      {visible ? (
        <AlertPop
          isDanger={alert.isError}
          toggle={setVisible}
          timer={7000}
          isOpen={visible}
          heading={alert.heading}
        >
          {alert.text}
        </AlertPop>
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, setErrors }) => (
          <Form id="healthCareRequest" autoComplete="off">
            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="bold" color="info" textGradient={true}>
                1. Please review the list of documents the Member has made available below
              </SoftTypography>
              <SoftTypography fontWeight="light" fontSize={14} lineHeight={1.5} ml={2.5}>
                {" "}
                Please select all that apply:
              </SoftTypography>
            </SoftBox>
            {/* <SoftBox fontSize={11}>
                            <code>{JSON.stringify(isOtherSelected)}</code><br />
                            <code>{JSON.stringify(otherDocumentType)}</code>
                        </SoftBox> */}
            <Grid container spacing={0} pl={3}>
              {documentList &&
                documentList.map((list, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} key={index}>
                      <Checkbox checked={list.checked} onClick={() => controlChecked(list)} />
                      <SoftTypography
                        fontSize={14}
                        variant="caption"
                        fontWeight="regular"
                        onClick={() => controlChecked(list)}
                        sx={{ cursor: "pointer", userSelect: "none" }}
                      >
                        {list.label}
                      </SoftTypography>
                    </Grid>
                  );
                })}
              {/* {otherTypes ? (
                <SoftBox>
                  <SoftTypography
                    fontSize={12}
                    variant="caption"
                    fontWeight="regular"
                  >
                    <strong>Note:</strong> Other documents types {"->"} ({otherTypes})
                  </SoftTypography>
                </SoftBox>
              ) : null} */}
              {/* <Grid item xs={6}>
                                {isOtherSelected ? <SoftInput
                                    name="otherDocumentType"
                                    placeholder="Enter Document Type"
                                    onChange={onChangeDocumentType} /> : null}
                            </Grid> */}
            </Grid>
            {selected === 0 ? (
              <SoftBox mt={1} ml={3}>
                <SoftTypography component="div" variant="caption" color="error">
                  Field is required.
                </SoftTypography>
              </SoftBox>
            ) : null}
            {/* {selected === 0 ? <SoftBox mt={1} ml={3}>
                            <SoftTypography component="div" variant="caption" color="error">
                                Field is required.
                            </SoftTypography>
                        </SoftBox> 
                        : isOtherSelected && otherDocumentType === null ? <SoftBox mt={1} ml={3}>
                            <SoftTypography component="div" variant="caption" color="error">
                                Field is required.
                            </SoftTypography>
                        </SoftBox> : null} */}

            <SoftBox mb={2} mt={3}>
              <SoftTypography variant="h6" fontWeight="bold" color="info" textGradient={true}>
                2. Enter your credentials
              </SoftTypography>
            </SoftBox>
            <Grid pl={2} container spacing={2}>
              <SoftBox pl={isMobile ? 0 : 3}>
                <ContactForm
                  formData={{ values, errors, touched, setFieldValue, setErrors, formField }}
                  isMobile={isMobile}
                />
                <Grid container spacing={2} mt={4}>
                  <Grid item xs={4} sm={2}>
                    <Link to={"/login"}>
                      <SoftButton
                        variant="text"
                        color="dark"
                        type="button"
                        size="large"
                        style={{
                          color: "#333333",
                          textTransform: "capitalize",
                          marginTop: 5,
                          fontWeight: 500,
                        }}
                        circular={true}
                      >
                        Cancel
                      </SoftButton>
                    </Link>
                  </Grid>
                  <Grid item xs={8} sm={10}>
                    <SoftButton
                      fullWidth
                      variant="gradient"
                      color="info"
                      size="medium"
                      type="submit"
                      disabled={
                        loading ||
                        Object.keys(errors).length > 0 ||
                        (selected === 0 && otherDocumentType === null) ||
                        otherDocumentType === ""
                      }
                      circular
                      style={{ textTransform: "capitalize", fontWeight: 600 }}
                    >
                      {loading ? (
                        <CircularProgress size={14} color="light" />
                      ) : (
                        <>
                          {isMobile ? null : <SoftBox px={1} component="img" src={lockopen} />}
                          Submit Request
                        </>
                      )}
                    </SoftButton>
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Form>
        )}
      </Formik>
    </SoftBox>
  );
};

// Requestform.propTypes = {
//     form: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
//   };
export default RequestForm;
