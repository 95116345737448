import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";
import Password from "./Password";
import SoftBox from "components/SoftBox";
import PersonalForm from "./PersonalForm";
import EmergencyForm from "./EmergencyForm";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.Mui-disabled span": {
    color: "#94A3B8",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'light'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: "row",
  padding: 0,
  borderRadius: 24,
  "&.Mui-expanded": {
    backgroundColor: "#91e2ff40",
    padding: "0px 15px 0px 12px",
  },
  "&.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
    color: "#00B5E2",
  },
  "&.Mui-expanded .MuiAccordionSummary-content span": {
    color: "#00B5E2",
  },
  "&.Mui-disabled": {
    backgroundColor: "white",
    opacity: 1,
    borderRadius: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    fontSize: 14,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-content span": {
    fontSize: 15,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  background: "#FFF",
}));

const CustomizedAccordions = (props) => {
  const { setOpen, open, setAlert, setVisible, data } = props;

  const userDetails = localStorage.getItem("userDetails");
  const UserRole = userDetails ? JSON.parse(userDetails).Role : null;

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <ReusableDialog
      maxWidth="md"
      isOpen={open}
      title={
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold" color="dark" mb={0.5}>
            Update Account
          </SoftTypography>
        </SoftBox>
      }
      handleClose={() => setOpen(false)}
    >
      <SoftBox>
        <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} open={true}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SoftTypography variant="caption" fontWeight="bold" color="dark">
              Personal Info
            </SoftTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SoftBox>
              <PersonalForm
                open={open}
                setOpen={setOpen}
                data={data}
                setVisible={setVisible}
                setAlert={setAlert}
              />
            </SoftBox>
          </AccordionDetails>
        </Accordion>
        {(UserRole && UserRole.toLowerCase() === "administrator") ||
        (UserRole && UserRole.toLowerCase() === "superadmin") ? null : (
          <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <SoftTypography variant="caption" fontWeight="bold" color="dark">
                Emergency Contact Info
              </SoftTypography>
            </AccordionSummary>
            <AccordionDetails>
              <SoftBox>
                <EmergencyForm
                  open={open}
                  setOpen={setOpen}
                  data={data}
                  setVisible={setVisible}
                  setAlert={setAlert}
                />
              </SoftBox>
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Accordion disabled expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <SoftTypography variant="caption" fontWeight="bold" color="dark">
              Billing Info
            </SoftTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
              ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <SoftTypography variant="caption" fontWeight="bold" color="dark">
              Password
            </SoftTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SoftBox>
              <Password data={data} setAlert={setAlert} setOpen={setOpen} setVisible={setVisible} />
            </SoftBox>
          </AccordionDetails>
        </Accordion>
      </SoftBox>
    </ReusableDialog>
  );
};

export default CustomizedAccordions;
