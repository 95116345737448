import React from 'react'
import { useNavigate } from 'react-router-dom';

import { Grid, Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

export default function ViewProfile() {
    const navigate = useNavigate()

    return (
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} lg={5}>
                <SoftBox mt={2}>
                    <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                        <SoftBox p={2.5}>
                            <SoftTypography variant="h5" fontWeight="bold" color="info" textGradient={true} mb={2}>Setup complete!</SoftTypography>
                            <SoftTypography variant="caption" fontWeight="medium" display="block" fontSize={14} mb={4}>Your healthcare wallet is all set up now. Click the button below to visit your Profile and continue managing healthcare documentation with ease!</SoftTypography>
                            <SoftButton
                                type="button"
                                variant="gradient"
                                color="info"
                                fullWidth
                                circular={true}
                                size="medium"
                                style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: 16 }}
                                onClick={() => navigate("/dashboard/profile")}
                            >
                                View Your Profile
                            </SoftButton>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </Grid>
        </Grid>
    )
}
