import { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, IconButton, Menu, MenuItem } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Logout } from "redux/actions/loginAction";
import brand from "./logo.svg";
import "./style.css";
import { fetchUserInformation } from "redux/actions/users";
import Account from "./Account";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import MenuIcon from '@mui/icons-material/Menu';

const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState({
    heading: "",
    text: "",
    isError: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    dispatch(Logout());
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("FullName");
    localStorage.removeItem("Email");
    localStorage.removeItem("userDetails");
    navigate("/login");
  };

  async function fetchUserInfo() {
    let email = JSON.parse(localStorage.getItem("userDetails")).Email;
    const result = await dispatch(fetchUserInformation(email));
    setData(result);
    setOpen(true);
  }

  return (
    <>
      <SoftBox>
        {visible ? (
          <AlertPop
            isDanger={alert.isError}
            toggle={setVisible}
            timer={7000}
            isOpen={visible}
            heading={alert.heading}
          >
            {alert.text}
          </AlertPop>
        ) : null}
        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
          <SoftBox display="flex" justifyContent="center">
            <SoftBox px={1} component="img" src={brand} style={{ width: 200 }} />
          </SoftBox>
          <SoftBox display={{ xs: 'none', md: 'flex' }}>
            <Card style={{ borderRadius: 50, boxShadow: "none" }}>
              <SoftBox display="flex" py={1} px={2}>
                <NavLink to="/dashboard/admin" activeclassname="is-active">
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    size="small"
                    circular
                    style={{ textTransform: "capitalize", border: 0, fontSize: 16, fontWeight: 600 }}
                  >
                    Dashboard
                  </SoftButton>
                </NavLink>
                <NavLink to="/dashboard/requests" activeclassname="is-active">
                  <SoftButton
                    variant="outlined"
                    circular
                    size="small"
                    color="dark"
                    style={{
                      marginLeft: 8,
                      textTransform: "capitalize",
                      border: 0,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Request History
                  </SoftButton>
                </NavLink>
                <NavLink
                  to="/dashboard/users"
                  activeclassname="is-active"
                // style={{ pointerEvents: "none" }}
                >
                  <SoftButton
                    variant="outlined"
                    circular
                    size="small"
                    color="dark"
                    style={{
                      marginLeft: 8,
                      textTransform: "capitalize",
                      border: 0,
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    User Management
                  </SoftButton>
                </NavLink>
              </SoftBox>
            </Card>
          </SoftBox>
          <SoftBox alignItems="center" display={{ xs: 'none', md: 'flex' }}>
            <IconButton
              color="info"
              size="large"
              circular
              sx={{ mr: 2, p: 0 }}
              onClick={fetchUserInfo}
            >
              <SoftTypography textGradient={true} color="info" sx={{ pt: 1.25 }}>
                <span className="material-icons-outlined" style={{ fontSize: 30 }}>
                  account_circle
                </span>
              </SoftTypography>
            </IconButton>
            <SoftButton variant="text" onClick={handleLogOut} style={{ textTransform: "capitalize" }}>
              <SoftTypography textGradient={true} color="info" fontWeight="bold" fontSize={16}>
                Sign Out
              </SoftTypography>
            </SoftButton>

          </SoftBox>
          <SoftBox display={{ xs: 'flex', md: 'none' }}>
            <SoftButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
              <MenuIcon />
            </SoftButton>
          </SoftBox>
        </SoftBox>
        {open ? (
          <Account
            data={data}
            open={open}
            setOpen={setOpen}
            setAlert={setAlert}
            setVisible={setVisible}
          />
        ) : null}
      </SoftBox>
      <SoftBox>
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
          <MenuItem onClick={handleMenuClose} component={NavLink} to="/dashboard/admin">
            Dashboard
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={NavLink} to="/dashboard/requests">
            Request History
          </MenuItem>
          <MenuItem onClick={handleMenuClose} component={NavLink} to="/dashboard/users">
            User Management
          </MenuItem>
          <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
        </Menu>
      </SoftBox>
    </>
  );
};

export default AdminHeader;
