import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftButton from 'components/SoftButton'
import SoftTypography from 'components/SoftTypography'
import ReusableDialog from 'examples/ReusableDialog'
import { request } from 'redux/actions/request'
import { fetchDocumentList } from 'redux/actions/wallet/documentAction'

export default function DeleteDocument(props) {
    const { data, open, setOpen, setAlert, setVisible, setClose } = props
    console.log(data)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    // delete document 
    function deleteDocumentHandler() {
        setLoading(true)
        let payload = data.map((x) => x.id)
        request("Document/Documents", "DELETE", payload)
            .then(async () => {
                await fetchDocuments(100)
                setAlert({
                    heading: "Success!",
                    text: "Your document has been successfully deleted.",
                    isError: false,
                    timer: 7000
                })
                setVisible(true)
                setLoading(false)
                setOpen(false)
                if (setClose) setClose(false)
            })
            .catch((err) => {
                setAlert({
                    heading: "Error",
                    text: err,
                    isError: true,
                })
                setVisible(true)
                setOpen(false)
            })
    }

    // fetch document list
    async function fetchDocuments(count) {
        return await dispatch(fetchDocumentList(count))
    }

    return (
        <ReusableDialog isOpen={open} title={data && data.length > 1 ? "Delete Document(s)" : "Delete Document"} handleClose={() => setOpen(false)}>
            <SoftBox>
                <SoftTypography color="dark" caption="text" fontSize={14}>Are you sure you want to delete? You can’t undo this action.</SoftTypography>
                <SoftBox display="flex" justifyContent="end" mt={5}>
                    <SoftButton
                        variant="text"
                        color="dark"
                        type="button"
                        onClick={() => setOpen(false)}
                        size="medium"
                        style={{ color: "#333333", textTransform: "capitalize", marginTop: 5, fontWeight: 500 }}
                        circular={true}>Cancel</SoftButton>
                    <SoftButton
                        variant="gradient"
                        color="info"
                        size="medium"
                        type="submit"
                        onClick={deleteDocumentHandler}
                        disabled={loading}
                        style={{ textTransform: "initial", fontWeight: 500, marginLeft: 10 }}
                        circular={true}>
                        {loading && <CircularProgress size={14} color="info" />}
                        {!loading && `Delete Document${data.length > 1 ? '(s)' : ''}`}
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </ReusableDialog>
    )
}