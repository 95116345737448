import React from "react";
import { Dialog, IconButton, DialogTitle } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const ShowQRCode = ({ title, handleClose, open, children }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <SoftBox as={DialogTitle}>
        <SoftBox display='flex' alignItems='center'>
          <SoftTypography fontWeight="bold" fontSize={16}>
            {title}
          </SoftTypography>
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 16,
                color: "#000000",
                p: 0
              }}
            >
              <span className="material-icons-outlined" style={{ fontSize: 20 }}>
                close
              </span>
            </IconButton>
          ) : null}
        </SoftBox>
      </SoftBox>
      {children}
    </Dialog>
  );
};

export default ShowQRCode;
