import React from "react";
import { Card, Grid } from "@mui/material";

// ** Components and Images
import { QRCode } from "./QRCode";
import MediIDCardBG from "assets/images/medi-id-card-bg.svg";
import brand from "assets/images/brand-logo.svg";
import brandLandScape from "assets/images/brand-logo-landscape.svg";
import { formatPhoneNumber } from "utils/constant";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

export const CardPrint = (props) => {
  const { componentToPrint, data, isZoom } = props;

  const getPageMargins = () => {
    return `@page { margin: 25mm 25mm 25mm 25mm !important; }`;
  };

  return (
    <SoftBox ref={(el) => (componentToPrint.current = el)}>
      <Card
        p={0}
        sx={{
          height: 250,
          width: { xs: '100%', md: 489 },
          border: "1px solid #AAAAAA",
          boxShadow: "none",
          backgroundImage: `url(${MediIDCardBG})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left bottom",
          margin: isZoom ? "0 auto" : "0",
        }}
      >
        <Grid container spacing={2} sx={{ padding: '25px 30px', height: '100%' }}>
          <Grid item xs={7} md={8}>
            <SoftBox mb={2}>
              <img src={brandLandScape} alt="MediDocProBrand" />
            </SoftBox>
            <SoftTypography style={{ color: '#344767', fontSize: 9.5, fontWeight: 300 }}>Member Name</SoftTypography>
            <SoftBox style={{ display: 'flex', height: 120, flexDirection: 'column', alignItems: 'self-start', justifyContent: 'space-between' }}>
              <SoftBox>
                <SoftTypography style={{ fontWeight: 700, fontSize: 17, color: '#344767', textTransform: "capitalize" }}>
                  {data?.FirstName} {data?.LastName}
                </SoftTypography>
                <SoftTypography style={{ fontWeight: 700, color: '#344767', fontSize: 11 }}>{data?.SMSCapableNumber}</SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftTypography style={{ color: '#344767', fontSize: 9.5, fontWeight: 300, marginTop: 10 }}>Emergency Contact</SoftTypography>
                <SoftBox>
                  <SoftTypography style={{ color: '#344767', fontWeight: 700, textTransform: "capitalize", marginRight: 10, fontSize: 11 }}>
                    {data?.EmergencyContact?.EmergencyContact_FirstName} {data?.EmergencyContact?.EmergencyContact_LastName}
                  </SoftTypography>
                  <SoftTypography style={{ color: '#344767', fontWeight: 700, fontSize: 11, marginTop: 5 }}>
                    {data ? data?.EmergencyContact?.EmergencyContact_Phone : null}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={5} md={4} style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
            <SoftBox sx={{ width: { xs: '100%', md: 140 } }}>
              <QRCode text="Scan for Profile" UserId={data?.Aspnet_UserId} />
            </SoftBox>
          </Grid>
        </Grid>
      </Card>

      <Card
        p={0}
        sx={{
          height: 250,
          width: { xs: '100%', md: 489 },
          border: "1px solid #AAAAAA",
          SoftBoxShadow: "none",
          margin: isZoom ? "0 auto" : "20px 0 0 0",
          marginTop: 2,
        }}
      >
        <SoftBox mt="auto" textAlign="center">
          <img src={brand} alt="Brand" style={{ width: 160 }} />
          <SoftTypography fontSize={8.5} fontWeight={400}>Safe and Secure Healthcare Wallet</SoftTypography>
        </SoftBox>
        <SoftBox sx={{ mt: 'auto', padding: '12px 30px', width: '100%', backgroundColor: '#313860', color: '#ffffff' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <SoftTypography fontSize={10} color="#FFFFFF">http://medidocpro.com</SoftTypography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <SoftTypography fontSize={10} color="#FFFFFF">(888) 655-0330</SoftTypography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <SoftTypography fontSize={10} color="#FFFFFF">(888) 655-0330</SoftTypography>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </SoftBox>
  );

  // return (
  //   <div ref={(el) => (componentToPrint.current = el)}>
  //     <Card
  //       p={0}
  //       style={{
  //         height: 250,
  //         width: 489,
  //         border: "1px solid #AAAAAA",
  //         SoftBoxShadow: "none",
  //         backgroundImage: `url(${MediIDCardBG})`,
  //         backgroundRepeat: "no-repeat",
  //         backgroundPosition: "left bottom",
  //         margin: isZoom ? "0 auto" : "0",
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "space-between",
  //           alignItems: "end",
  //           padding: "25px 30px",
  //         }}
  //       >
  //         <div>
  //           <div style={{ marginBottom: 10 }}>
  //             <img src={brandLandScape} alt="MediDocProBrand" />
  //           </div>
  //           <div style={{ color: "#344767", fontSize: 11, fontWeight: 300 }}>Member Name</div>
  //           <div
  //             style={{
  //               display: "flex",
  //               height: 130,
  //               flexDirection: "column",
  //               alignItems: "self-start",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <div>
  //               <h4
  //                 style={{
  //                   fontWeight: 700,
  //                   fontSize: 22,
  //                   color: "#344767",
  //                   textTransform: "capitalize",
  //                 }}
  //               >
  //                 {data?.FirstName} {data?.LastName}
  //               </h4>
  //               <h6 style={{ fontWeight: 700, color: "#344767" }}>{data?.SMSCapableNumber}</h6>
  //             </div>
  //             <div>
  //               <div style={{ color: "#344767", fontSize: 11, fontWeight: 300, marginTop: 15 }}>
  //                 Emergency Contact
  //               </div>
  //               <div>
  //                 <h6
  //                   style={{
  //                     color: "#344767",
  //                     fontWeight: 700,
  //                     textTransform: "capitalize",
  //                     marginRight: 10,
  //                   }}
  //                 >
  //                   {data?.EmergencyContact?.EmergencyContact_FirstName}{" "}
  //                   {data?.EmergencyContact?.EmergencyContact_LastName}
  //                 </h6>
  //                 <h6 style={{ color: "#344767", fontWeight: 700, marginTop: 5 }}>
  //                   {data ? data?.EmergencyContact?.EmergencyContact_Phone : null}
  //                 </h6>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <div style={{ width: 140 }}>
  //           <QRCode text="Scan for Profile" UserId={data?.Aspnet_UserId} />
  //         </div>
  //       </div>
  //     </Card>
  //     <Card
  //       p={0}
  //       style={{
  //         height: 250,
  //         width: 489,
  //         border: "1px solid #AAAAAA",
  //         SoftBoxShadow: "none",
  //         margin: isZoom ? "0 auto" : "20px 0 0 0",
  //         marginTop: 20,
  //       }}
  //     >
  //       <div style={{ marginTop: "auto", textAlign: "center" }}>
  //         <div>
  //           <img src={brand} alt="Brand" style={{ width: 160 }} />
  //         </div>
  //         <div style={{ fontSize: 8.5, fontWeight: 400 }}>Safe and Secure Healthcare Wallet</div>
  //       </div>
  //       <div
  //         style={{
  //           marginTop: "auto",
  //           padding: "12px 30px",
  //           width: "100%",
  //           backgroundColor: "#313860",
  //         }}
  //       >
  //         <div style={{ display: "flex", justifyContent: "space-between" }}>
  //           <div style={{ fontSize: 10, color: "#e9ecef" }}>http://medidocpro.com</div>
  //           <div style={{ fontSize: 10, color: "#e9ecef" }}>
  //             {/* {data?.SMSCapableNumber} */} (888) 655-0330
  //           </div>
  //           <div style={{ fontSize: 10, color: "#e9ecef" }}>
  //             {/* {data?.EmergencyContact?.EmergencyContact_Phone} */} (888) 655-0330
  //           </div>
  //         </div>
  //       </div>
  //     </Card>
  //   </div>
  // );
  
};
