const form = {
  formId: "login",
  formField: {
    password: {
      name: "password",
      label: "new password",
      type: "password",
      placeholder: "Enter New Password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 8 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "confirm new password",
      type: "password",
      placeholder: "Re-Enter New Password",
      errorMsg: "Confirm Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
  },
};

export default form;
