import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';

const useViewport = () => {
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1020px)');
  const isLaptop = useMediaQuery('(min-width: 1021px) and (max-width: 1366px)');
  const isDesktop = useMediaQuery('(min-width: 1367px)');
  const isMobile = useMemo(() => !(isTablet || isLaptop || isDesktop), [isTablet, isLaptop, isDesktop]);
  
  return { isMobile, isTablet, isLaptop, isDesktop };
};

export default useViewport;