import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";

const Complete = ({ open, onBackList, creatingRole }) => {
  return (
    <ReusableDialog maxWidth="sm" isOpen={open}>
      <SoftBox textAlign="center">
        <SoftTypography color="success">
          <span className="material-icons-outlined" style={{ fontSize: 120 }}>
            check_circle_outline
          </span>
        </SoftTypography>
        <SoftTypography variant="h5" fontWeight="bold" mb={3}>
          {(creatingRole && creatingRole.toLowerCase() === 'administrator')
            ? "New Admin Enrolled!"
            : "New Member Enrolled!"}
        </SoftTypography>
        {/* <SoftTypography
          variant="caption"
          fontWeight="light"
          fontSize={14}
          style={{ color: "#94A3B8" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </SoftTypography> */}
        <SoftBox mt={3}>
          <SoftButton
            variant="gradient"
            color="info"
            circular
            onClick={onBackList}
            style={{ textTransform: "capitalize", fontWeight: 500, minWidth: 300 }}
          >
            Back to User List
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </ReusableDialog>
  );
};

export default Complete;
