import { useState } from "react";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";

import { request } from "redux/actions/request";

import SoftButton from "components/SoftButton";
import ReusableDialog from "examples/ReusableDialog";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import UserForm from "./UserForm";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";

import initialValues from "layouts/authentication/enrollment/schemas/initialValues";
import form from "layouts/authentication/enrollment/schemas/form";

// import { phoneRegExp } from "utils/constant";


const CreateNewAdmin = ({ open, setOpen, submitHandlerEvent, setUpdate, role }) => {
  // ** States
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [agree, setAgree] = useState(false);
  const { formId, formField } = form;
  const validation = Yup.object().shape({
    firstName: Yup.string()
      .required("Field is required.")
      .matches(
        /^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/,
        "Alphabetical characters only."
      ),
    lastName: Yup.string()
      .required("Field is required.")
      .matches(
        /^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/,
        "Alphabetical characters only."
      ),
    email: Yup.string()
      .required("Field is required.")
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Your email address is invalid"),
    // phone: Yup.string().required("Field is required.").matches(phoneRegExp, "Phone number is not valid."),
    password: Yup.string()
      .required("Field is required.")
      .min(8, "Your password should be more than 8 characters.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
        "Password must match to the standard."
      ),
    repeatPassword: Yup.string()
      .required("Field is required.")
      .oneOf([Yup.ref("password"), null], "Your password doesn't match."),
  });
  const updatedInitialValue = initialValues;

  const userDetails = localStorage.getItem("userDetails");
  const UserRole = userDetails ? JSON.parse(userDetails).Role : null;

  function handleBack() {
    setOpen(false);
  }

  const handleSubmit = async (values, actions) => {
    const payload = {
      FirstName: values.firstName,
      LastName: values.lastName,
      EmailAddress: values.email,
      Password: values.password,
      RoleName: "Administrator", // "Administrator"
      IsActive: true,
    };
    submitForm(payload, actions);
  };

  const submitForm = async (values, actions) => {
    setIsSubmit(true);
    request("Users/PostExternalUser", "POST", values)
      .then(async (result) => {
        setIsSubmit(false);
        // setUpdate((prev) => !prev);
        submitHandlerEvent();
      })
      .catch((error) => {
        setIsSubmit(false);
        setVisible(true);
        setAlert(error);
      });
  };

  return (
    <>
      {visible ? (
        <AlertPop
          isDanger={true}
          toggle={setVisible}
          timer={7000}
          isOpen={visible}
          heading={"Error"}
        >
          {alert}
        </AlertPop>
      ) : null}
      <ReusableDialog
        maxWidth="sm"
        isOpen={open}
        title={
          <SoftBox display="flex">
            <SoftTypography textGradient={true} color="info">
              <span className="material-icons-outlined" style={{ fontSize: 25 }}>
                person_add_alt
              </span>
            </SoftTypography>
            <SoftTypography
              variant="h5"
              fontWeight="bold"
              ml={1}
              style={{ backgroundImage: "none" }}
            >
              Create New Admin
            </SoftTypography>
          </SoftBox>
        }
        handleClose={() => setOpen(false)}
      >
        <Formik
          initialValues={updatedInitialValue}
          validationSchema={validation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form id={formId} autoComplete="off">
              <UserForm
                formData={{ values, touched, formField, setFieldValue, errors, agree, setAgree }}
                isHide={true}
                role={role}
                userRole={UserRole}
              />

              <SoftBox mt={2} width="100%" display="flex" justifyContent="end">
                <SoftButton
                  variant="text"
                  circular={true}
                  color="text"
                  onClick={handleBack}
                  style={{
                    marginRight: 10,
                    padding: 13,
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                >
                  Back
                </SoftButton>
                <SoftButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  circular={true}
                  style={{ textTransform: "capitalize", fontWeight: 500, minWidth: 200 }}
                >
                  {isSubmit ? <CircularProgress size={18} color="white" /> : "Save & Complete"}
                </SoftButton>
              </SoftBox>
            </Form>
          )}
        </Formik>
      </ReusableDialog>
    </>
  );
};

export default CreateNewAdmin;
