import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Grid, Tooltip } from "@mui/material";

import SoftDropzone from "components/SoftDropzone";
import ReusableDialog from "examples/ReusableDialog";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import { bytesToMegaBytes } from "utils/constant";
import { fileNameWithExtension } from "utils/constant";
import { DesignatedContact } from "./DesignatedContact";
import initialValues from "../schemas/initialValues";
import validations from "../schemas/validations";
import form from "../schemas/form";
import { request } from "redux/actions/request";
import { fetchDocumentTypeList } from "redux/actions/wallet/documentTypeAction";
import { shareOptions } from "./options";
import { fetchUserInformation } from "redux/actions/users";
import { fetchDesignatedContact } from "redux/actions/profile/profileAction";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";
import SoftInput from "components/SoftInput";

import close from "./close.svg";
import laptop from "../../Icons/laptop.svg";
import mobile from "../../Icons/mobile.svg";
import "./style.css";

export const DragDrop = (props) => {
  const dispatch = useDispatch();
  const { documentTypeList, userInfo, designatedContact } = useSelector((state) => state);
  const { open, setOpen, setVisible, setAlert } = props;
  // useState
  const [documentOtherName, setDocumentOtherName] = useState(null);
  const [disabledDocumentType, setDisabledDocumentType] = useState(true);
  // const [disabledAdd, setDisabledAdd] = useState(true)
  const [fileBase64, setFileBase64] = useState(null);
  const [fileDetail, setFileDetail] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [disabledUpload, setDisabledUpload] = useState(true);
  const [fileSize, setFileSize] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [shareStatus, setShareStatus] = useState(null);
  const [designateContact, setDesignateContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [newContact, setNewContact] = useState(false);
  const [emergencyContact, setEmergencyContact] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isOtherTypeError, setIsOtherTypeError] = useState(false);
  const [isStatusError, setIsStatusError] = useState(false);

  // Document List
  useEffect(() => {
    if (!documentTypeList.data) fetchDocumentType();
    else {
      let sorted = documentTypeList.data.map((x) => ({
        label: x.DocumentType_Name,
        value: x.DocummentType_Id,
      }));
      setDocumentOptions(sorted);
    }
  }, [documentTypeList.data]);

  // UserInfo
  useEffect(() => {
    if (!userInfo.data || !designatedContact.data) fetchUserInfo();
    if (userInfo.data && userInfo.data.EmergencyContact && designatedContact.data) {
      // debugger
      let {
        data: {
          EmergencyContact: { EmergencyContact_FirstName, EmergencyContact_LastName },
        },
      } = userInfo;
      let { data } = designatedContact;
      let designate = [];
      let emergency = [
        { label: EmergencyContact_FirstName + " " + EmergencyContact_LastName, value: "emergency" },
      ];
      if (data.length) {
        designate = data.map((x) => ({
          label: x.Contact_FirstName + " " + x.Contact_LastName,
          value: x.ContactId,
        }));
      }
      setGroupOptions([
        { label: "New Contact", options: [{ label: "Add New", value: "new" }] },
        { label: "Previously Entered Designated Contacts", options: designate },
        { label: "Emergency", options: emergency },
      ]);
    }
  }, [userInfo.data, designatedContact.data]);

  // Fetch UserInfo
  async function fetchUserInfo() {
    if (userInfo.data === null) {
      let email = localStorage.getItem("Email");
      const result = await dispatch(fetchUserInformation(email));
      await fetchDesignateContact(result.Aspnet_UserId);
    } else await fetchDesignateContact(userInfo.data.Aspnet_UserId);
  }

  // fetch document list
  async function fetchDocumentType() {
    return await dispatch(fetchDocumentTypeList());
  }

  // Fetch Designated Contact
  async function fetchDesignateContact(userId) {
    return await dispatch(fetchDesignatedContact(userId, "DocumentContact"));
  }

  function onChangeDesignateContact(value) {
    setDesignateContact(value);
    if (documentType !== null) setDisabledUpload(false);
    // if(documentType && documentType.label.toLowerCase() === "other" && documentOtherName !== "") {
    //     setIsOtherTypeError(true);
    //     setDisabledUpload(true);
    // }
    switch (true) {
      case value.value === "emergency":
        setNewContact(false);
        setEmergencyContact(true);
        setIsOverflow(false);
        return;

      case value.value === "new":
        setEmergencyContact(false);
        setNewContact(true);
        setIsOverflow(true);
        return;

      default:
        setEmergencyContact(false);
        setIsOverflow(false);
        setNewContact(false);
        return;
    }
  }

  // function uploadDocument(file) {
  //     getBase64(file)
  //         .then((result) => console.log(result))
  //         .catch((err) => console.log(err))
  // }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const currentValidation = validations[0];
  const { formField } = form;

  const handleSubmit = async (values, actions) => {
    if (documentType && documentType.label.toLowerCase() === "other" && (documentOtherName === "" || documentOtherName === null)) {
      if (!shareStatus) setIsStatusError(true);
      else setIsStatusError(false);

      setIsOtherTypeError(true);
      return;
    }
    if (!shareStatus) {
      if (documentType && documentType.label.toLowerCase() === "other" && (documentOtherName === "" || documentOtherName === null))
        setIsOtherTypeError(true);
      else setIsOtherTypeError(false);

      setIsStatusError(true);
      return;
    }
    setLoading(true);
    let payload = {
      DocumentStorage: {
        DocumentFiles: [
          {
            // DocumentFileName: fileName.split('.').slice(0, -1).join('.'),
            DocumentFileName: fileName,
            DocumentFileContent: fileBase64.split("base64,").pop(), // FileBase64
            DocumentFileExtension: fileBase64.split("base64,")[0] + "base64",
            DocumentFileSize: fileDetail.size,
            DocumentFileMetaData: {
              Sharable: shareStatus ? shareStatus.value : "True",
              DocumentType: documentType.label,
            },
          },
        ],
      },
    };
    if (newContact) {
      payload.UserDocumentContact = {
        Contact_LastName: values.lastName,
        Contact_FirstName: values.firstName,
        Contact_Email: values.email,
        Contact_Phone: values.phone.toString(),
        Contact_Street1: values.address1,
        Contact_Street2: values.address2,
        Contact_City: values.city,
        Contact_State: values.state.value,
        Contact_PostalCode: values.zip.toString(),
        Contact_Type: "DocumentContact",
        DocumentTypeName: documentType.label,
      };
    } else if (emergencyContact) {
      let {
        data: { EmergencyContact },
      } = userInfo;
      payload.UserDocumentContact = {
        ContactId: EmergencyContact.EmergencyContact_ID,
        Contact_LastName: EmergencyContact.EmergencyContact_LastName,
        Contact_FirstName: EmergencyContact.EmergencyContact_FirstName,
        Contact_Email: EmergencyContact.EmergencyContact_Email,
        Contact_Phone: EmergencyContact.EmergencyContact_Phone,
        Contact_Street1: EmergencyContact.EmergencyContact_Street1,
        Contact_Street2: EmergencyContact.EmergencyContact_Street2,
        Contact_City: EmergencyContact.EmergencyContact_City,
        Contact_State: EmergencyContact.EmergencyContact_State,
        Contact_PostalCode: EmergencyContact.EmergencyContact_PostalCode,
        Contact_Type: "DocumentContact",
        DocumentTypeName: documentType.label,
      };
    } else {
      let filtered = designatedContact.data.filter((x) => x.ContactId === designateContact.value);
      if (filtered.length) {
        payload.UserDocumentContact = {
          ContactId: filtered[0].ContactId,
          Contact_FirstName: filtered[0].Contact_FirstName,
          Contact_LastName: filtered[0].Contact_LastName,
          Contact_Email: filtered[0].Contact_Email,
          Contact_Phone: filtered[0].Contact_Phone,
          Contact_Street1: filtered[0].Contact_Street1,
          Contact_Street2: filtered[0].Contact_Street2,
          Contact_City: filtered[0].Contact_City,
          Contact_State: filtered[0].Contact_State,
          Contact_PostalCode: filtered[0].Contact_PostalCode,
          Contact_Type: "DocumentContact",
          DocumentTypeName: documentType.label,
        };
      }
    }
    if (documentType.label === "Other")
      payload.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName =
        documentOtherName;
  console.log('payload', JSON.stringify(payload))
    request("Document/Documents", "POST", payload)
      .then(async () => {
        await fetchDocuments(100);
        // console.log(result)
        setVisible(true);
        setOpen(false);
        setAlert({
          isError: false,
          timer: 5000,
          heading: "Document Uploaded Successfully",
          text: "Your document has been uploaded successfully.",
        });
        setLoading(false);
        fetchDesignateContact(userInfo.data.Aspnet_UserId);
      })
      .catch((err) => {
        setVisible(true);
        setLoading(false);
        setOpen(false);
        setAlert({
          isError: true,
          timer: null,
          heading: "Error",
          text: err,
        });
      });
  };

  // fetch document list
  async function fetchDocuments(count) {
    return await dispatch(fetchDocumentList(count));
  }

  const options = {
    paramName: "file", // The name that will be used to transfer the file
    maxFilesize: 35, // MB
    uploadMultiple: false,
    acceptedFiles: "image/*,.pdf,.docx,.xlsx,.doc,application/vnd.ms-excel",
    accept: async function (file, done) {
      getBase64(file)
        .then((result) => setFileBase64(result))
        .catch((err) => alert("wrong file format", err));

      setFileDetail(file);
      let size = bytesToMegaBytes(file.size);
      let name = fileNameWithExtension(file.upload.filename);
      setFileName(name);
      setFileSize(size);
      // let fileBase64 = await toBase64(file)
      // setFileBase64(fileBase64)
      setDisabledDocumentType(false);
      return false;
    },
  };

  function onChangeDocumentType(e) {
    setDocumentOtherName(e.target.value);
    if (!e.target.value) {
      if (documentType && documentType.label.toLowerCase() === "other") setIsOtherTypeError(true);
      else setIsOtherTypeError(false);
    } else setIsOtherTypeError(false);
  }

  return (
    <>
      <ReusableDialog
        className={!isOverflow ? "is-overflow" : ""}
        isOpen={open}
        title="Upload Document"
        handleClose={() => setOpen(false)}
      >
        {/* {!isOptionSelected ? (
          <SoftBox>
            <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
              Select Upload Method
            </SoftTypography>
            <SoftBox width="100%" display="flex" justifyContent="space-between" mt={2}>
              <SoftBox display="flex" flex="auto">
                <SoftButton
                  type="button"
                  style={{
                    textTransform: "capitalize",
                    flex: 1,
                    marginRight: 10,
                    boxShadow: "none",
                    height: 150,
                    background: isSelect ? "#F2FBFD" : "#ffffff",
                    border: isSelect ? "2px solid #01a6e0" : "1px solid #AAAAAA",
                  }}
                  onClick={() => setIsSelect(true)}
                >
                  <SoftBox display="flex" flexDirection="column" alignItems="center">
                    <SoftBox px={1} component="img" src={laptop} style={{ width: 60 }} mb={2} />
                    <SoftTypography variant="h6" fontWeight="medium" color="dark" fontSize={14}>
                      Upload from Computer
                    </SoftTypography>
                  </SoftBox>
                </SoftButton>
              </SoftBox>
              {/* <Tooltip title="Evaluating for a Future Phase" placement="top-end">
                <SoftBox display="flex" flex="auto">
                  <SoftButton
                    type="button"
                    disabled={true}
                    style={{
                      textTransform: "capitalize",
                      flex: 1,
                      marginLeft: 10,
                      boxShadow: "none",
                      border: "1px solid #AAAAAA",
                      height: 150,
                    }}
                  >
                    <SoftBox display="flex" flexDirection="column" alignItems="center">
                      <SoftBox px={1} component="img" src={mobile} style={{ width: 45 }} mb={2} />
                      <SoftTypography variant="h6" fontWeight="medium" color="dark" fontSize={14}>
                        Scan from your mobile
                      </SoftTypography>
                    </SoftBox>
                  </SoftButton>
                </SoftBox>
              </Tooltip> */}
            {/* </SoftBox>
            <SoftBox width="100%" display="flex" justifyContent="end" mt={2.5}>
              <SoftButton
                type="button"
                variant="gradient"
                color="info"
                circular={true}
                size="medium"
                onClick={() => setIsOptionSelected(true)}
                style={{
                  textTransform: "capitalize",
                  fontWeight: 500,
                  background: !isSelect ? "#B1B1B1" : null,
                  pointerEvents: !isSelect ? "none" : "auto",
                }}
              >
                Continue
              </SoftButton>
            </SoftBox>
          </SoftBox>
        ) :   */}
        
          <SoftBox>
            <SoftBox>
              <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
                Attach Document:{" "}
                <small style={{ color: "#777", fontSize: 10, fontWeight: 400 }}>
                  Maximum file size 35 MB (Accepted file types: .pdf, .doc, .jpg, .png)
                </small>
              </SoftTypography>
              {!fileBase64 ? (
                <SoftBox>
                  <SoftDropzone options={options} />
                  <SoftTypography variant="h6" fontWeight="light" color="dark" fontSize={12} mt={1}>
                  For security reasons, we recommend PDF files. <br />
                                        Please note that If you upload Word or Excel <span style={{fontWeight: 700}}>(*.doc *.xls )</span> documents, they will not be available for preview. These specified format types will be available to download.
                  </SoftTypography>
                </SoftBox>
              ) : (
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    width: 360,
                    borderRadius: 100,
                    padding: "9px 16px",
                    background: "#F6F6F6",
                    border: "1px solid #D9D9D9",
                  }}
                >
                  <SoftBox display="flex">
                    <SoftTypography fontWeight="bold" fontSize={14}>
                      {fileName}
                    </SoftTypography>
                    <SoftTypography ml={1} fontWeight="medium" fontSize={14}>
                      {fileSize}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    style={{ cursor: "pointer" }}
                    px={1}
                    component="img"
                    src={close}
                    onClick={() => setFileBase64(null)}
                  />
                </SoftBox>
              )}
            </SoftBox>
            <SoftBox mt={3}>
              <Grid container justifyContent="center" spacing={1.5} sx={{ height: "100%" }}>
                <Grid item xs={6}>
                  <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
                    Document Type
                  </SoftTypography>
                  <SoftSelect
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={documentOptions}
                    value={documentType}
                    isDisabled={disabledDocumentType}
                    // isClearable
                    placeholder="Select Document Types"
                    onChange={(value) => {
                      setIsOtherTypeError(false);
                      setDocumentType(value);
                      setDocumentOtherName(null);
                      if (designateContact !== null) setDisabledUpload(false);
                    }}
                  />
                  {documentType && documentType.label === "Other" ? (
                    <SoftBox mt={1}>
                      <SoftInput
                        name="otherDocumentType"
                        placeholder="Enter Document Type"
                        onChange={onChangeDocumentType}
                        error={isOtherTypeError}
                      />
                      {isOtherTypeError ? (
                        <SoftTypography component="div" variant="caption" color="error" mt={1}>
                          Field is required.
                        </SoftTypography>
                      ) : null}
                    </SoftBox>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
                    Share Status
                  </SoftTypography>
                  <SoftSelect
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={shareOptions}
                    value={shareStatus}
                    isDisabled={disabledDocumentType}
                    error={isStatusError}
                    // isClearable
                    placeholder="Select Share Status"
                    onChange={(value) => {
                      if (value) setIsStatusError(false);
                      else setShareStatus(true);
                      setShareStatus(value);
                    }}
                  />
                  {isStatusError ? (
                    <SoftTypography component="div" variant="caption" color="error" mt={1}>
                      Field is required.
                    </SoftTypography>
                  ) : null}
                </Grid>
                <Grid item xs={12} mt={1}>
                  <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
                    Designated Contact
                  </SoftTypography>
                  <SoftSelect
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    options={groupOptions}
                    value={designateContact}
                    isDisabled={disabledDocumentType}
                    // isClearable
                    placeholder="Search or Select from previously entered contacts"
                    onChange={onChangeDesignateContact}
                  />
                </Grid>
              </Grid>
              {newContact ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentValidation}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, setFieldValue, setErrors }) => (
                    <Form id="emergencyInfo" autoComplete="off">
                      <DesignatedContact
                        formData={{ values, errors, touched, setFieldValue, setErrors, formField }}
                      />
                      <SoftButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        circular={true}
                        size="medium"
                        disabled={loading}
                        fullWidth
                        style={{
                          textTransform: "capitalize",
                          fontWeight: 500,
                          marginTop: 10,
                          fontSize: 14,
                        }}
                      >
                        {loading && <CircularProgress size={14} color="white" />}
                        {!loading && "Upload Document"}
                      </SoftButton>
                    </Form>
                  )}
                </Formik>
              ) : null}
              {!newContact ? (
                <SoftBox width="100%" display="flex" justifyContent="end" mt={2.5}>
                  <SoftButton
                    variant="text"
                    circular={true}
                    color="dark"
                    size="medium"
                    onClick={() =>setOpen(false)}
                    style={{
                      marginRight: 10,
                      textTransform: "capitalize",
                      fontWeight: 500,
                      marginTop: 5,
                    }}
                  >
                    back
                  </SoftButton>
                  <SoftButton
                    type="button"
                    variant="gradient"
                    color="info"
                    circular={true}
                    onClick={handleSubmit}
                    size="medium"
                    disabled={loading}
                    style={{
                      textTransform: "capitalize",
                      fontWeight: 500,
                      background: disabledUpload ? "#B1B1B1" : null,
                      pointerEvents: disabledUpload ? "none" : "auto",
                    }}
                  >
                    {loading && <CircularProgress size={14} color="white" />}
                    {!loading && "Upload Document"}
                  </SoftButton>
                </SoftBox>
              ) : null}
            </SoftBox>
          </SoftBox>
        
      </ReusableDialog>
    </>
  );
};
