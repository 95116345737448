/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form"
import { phoneRegExp } from "utils/constant";
const {
  formField: { firstName, lastName, email, phone, address1, city, state, zip, gender },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phone.name]: Yup.string().required(phone.errorMsg).matches(phoneRegExp, "Phone number is not valid."),
    [address1.name]: Yup.string().required(address1.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.object().required(state.errorMsg),
    [zip.name]: Yup.string().required(zip.errorMsg).min(5, zip.invalidMsg).matches(/^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,38})?)(?:e[+]?\d+)?$/, "Zip code is invalid."),
    // [gender.name]: Yup.object(),
  }),
];

export default validations;
