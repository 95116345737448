import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress, Grid } from "@mui/material";
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import FormField from "../FormField";
import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";

import { StateList } from "layouts/authentication/enrollment/components/PersonalInfo/StateList";

function LoginInfo({ formData }) {
  const [isTouched, setIsTouched] = useState(false)
  const [loading, setLoading] = useState(true)
  const [termsPolicy, setTermsPolicy] = useState({
    terms: false,
    policy: false
  })
  const [content, setContent] = useState(null);
  const { formField, values, errors, touched, setFieldValue, agree, setAgree } = formData;
  const { firstName, lastName, email, state } = formField;
  const { firstName: firstNameV, lastName: lastNameV, email: emailV, state: stateV } = values;
  const statusOptions = StateList;

  async function onChangeStatus(value) {
    setFieldValue("state", value);
  }

  function handleSetAgreement() {
    setIsTouched(!!agree)
    setAgree(!agree);
  }

  function handleAgreement(name) {
    setLoading(true);
    if (name === "terms") {
      setTermsPolicy({ terms: true, policy: false })
      fetch('/terms-of-use.html')
        .then(response => response.text())
        .then(data => {
          setContent(data);
          setLoading(false);
        });
    }
    else {
      setTermsPolicy({ terms: false, policy: true })
      fetch('/privacy-policy.html')
        .then(response => response.text())
        .then(data => {
          setContent(data);
          setLoading(false);
        });

    }

  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormField
            type={firstName.type}
            label={""}
            name={firstName.name}
            value={firstNameV}
            placeholder={firstName.placeholder}
            error={errors.firstName && touched.firstName}
            success={firstNameV.length > 0 && !errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={lastName.type}
            label={""}
            name={lastName.name}
            value={lastNameV}
            placeholder={lastName.placeholder}
            error={errors.lastName && touched.lastName}
            success={lastNameV.length > 0 && !errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormField
            type={email.type}
            label={""}
            name={email.name}
            value={emailV}
            placeholder={email.placeholder}
            error={errors.email && touched.email}
            success={emailV.length > 0 && !errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            fieldType="select"
            type={state.type}
            label={""}
            name={state.name}
            options={statusOptions}
            defaultValue={values.state}
            value={values.state}
            onChange={onChangeStatus}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            placeholder={state.placeholder}
            error={errors.state && touched.state}
            success={stateV.length > 0 && !errors.state}
          />
        </Grid>
      </Grid>
      <Grid container mb={4} mt={0.5}>
        <SoftBox display="flex" mt={0.5} alignItems="center">
          <Checkbox checked={agree} onChange={handleSetAgreement} />
          <SoftTypography
            fontSize={14}
            variant="caption"
            fontWeight="regular"
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;I agree the&nbsp;
            <SoftTypography
              fontSize={14}
              variant="caption"
              color="info"
              fontWeight="regular"
              onClick={() => handleAgreement("terms")}
              style={{ textDecoration: 'underline' }}>
              Terms and Conditions
            </SoftTypography>
            &nbsp;and&nbsp;
            <SoftTypography
              fontSize={14}
              variant="caption"
              color="info"
              fontWeight="regular"
              onClick={() => handleAgreement("policy")}
              style={{ textDecoration: 'underline' }}>
              Privacy Policy
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
        {isTouched ? <SoftBox mt={0.5} ml={3.7}>
          <SoftTypography component="div" variant="caption" color="error">
            Field is required.
          </SoftTypography>
        </SoftBox> : null}
        {termsPolicy.policy || termsPolicy.terms ? <ReusableDialog
          maxWidth="md"
          isOpen={termsPolicy.policy || termsPolicy.terms}
          handleClose={() => setTermsPolicy({ terms: false, policy: false })}
        >
          <SoftBox>
            {loading ? <SoftBox p={2} textAlign="center"><CircularProgress size={25} color="primary" thickness={5} /></SoftBox> : <div dangerouslySetInnerHTML={{ __html: content }} />}
          </SoftBox>
        </ReusableDialog> : null}
      </Grid>
    </>
  );
}

// typechecking props for UserInfo
LoginInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LoginInfo;
