import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import { Grid } from "@mui/material"
import SoftBox from "components/SoftBox"
import MyDocument from "./component/MyDocument";
import { fetchUserInformation } from "redux/actions/users";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";

const MyDocs = () => {
    const dispatch = useDispatch()
    const { documentList, userInfo } = useSelector(state => state)
    const [tableList, setTableList] = useState(null)

    useEffect(() => {
        if (!userInfo.data) fetchUserInfo()
    }, [userInfo.data])

    useEffect(() => {
        if (documentList.data) {
            // let list = documentList.data.map((x) => ({
            //     id: x.Document_Id,
            //     status: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.Sharable,
            //     type: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
            //     name: x.Document_Name,
            //     contact: x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName,
            //     updateDate: x.Created_On ? format(new Date(x.Created_On), "MMM d, y") : null,
            //     requestDate: x.Updated_On ? format(new Date(x.Updated_On), "MMM d, y") : null,
            // }))
            let list = documentList.data.map((x) => ({
                id: x.Document_Id,
                status: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.Sharable,
                type: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType.toLowerCase() === "other"
                    ? x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName
                    : x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
                fileName: x.Document_Name,
                contact: x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName,
                updateDate: x.Created_On ? format(new Date(x.Created_On), "MMM d, y") : null,
                requestDate: x.Updated_On ? format(new Date(x.Updated_On), "MMM d, y") : null,
                extension: x.DocumentStorage.DocumentFiles[0].DocumentFileExtension,
                name: x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName,
                phone: x.UserDocumentContact.Contact_Phone,
                email: x.UserDocumentContact.Contact_Email,
                address: x.UserDocumentContact.Contact_Email.Contact_Street1 + ", "
                    + x.UserDocumentContact.Contact_Email.Contact_Street2 + ", "
                    + x.UserDocumentContact.Contact_Email.Contact_State + " "
                    + x.UserDocumentContact.Contact_Email.Contact_PostalCode
            }))

            setTableList(list)

        } else fetchDocuments(100)
    }, [documentList.data])

    // fetch document list
    async function fetchDocuments(count) {
        return await dispatch(fetchDocumentList(count))
    }

    // Fetch UserInfo
    async function fetchUserInfo() {
        let email = localStorage.getItem("Email")
        return await dispatch(fetchUserInformation(email))
    }


    return (
        <SoftBox>
            <Grid container justifyContent="center" sx={{ height: "100%" }}>
                <Grid item xs={12}>
                    <MyDocument data={tableList} loading={tableList === null ? true : false} />
                </Grid>
            </Grid>
        </SoftBox >
    )
}

export default MyDocs