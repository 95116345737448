/* eslint-disable import/no-anonymous-default-export */
import * as Actions from "../../constants/constants";

const initialState = {
    stateInfo: null,
    productList: [],
    productValue: {},
    subscribeCancellationResponse: {},
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case Actions.STATE_INFO:
            return { ...state, stateInfo: payload };
        case Actions.PRODUCT_LIST_SUCCESS:
            return { ...state, productList: payload };
        case Actions.PRODUCT_LIST_FAILED:
            return { ...state, productList: [] };
        case Actions.PRODUCT_VALUE_SELECTED:
            return { ...state, productValue: payload };
        case Actions.PRODUCT_VALUE_REMOVED:
            return { ...state, productValue: [] };
            case Actions.SCUBSCRIBE_CANCEL_SUCCESS:
            return { ...state, subscribeCancellationResponse: payload };
        case Actions.SCUBSCRIBE_CANCEL_FAILED:
            return { ...state, subscribeCancellationResponse: [] };
        default:
            return state;
    }
};