const form = {
  formId: "login",
  formField: {
    firstName: {
      name: "firstName",
      label: "Name",
      type: "text",
      placeholder: "First Name",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "lastName",
      label: " ",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Last name is required.",
    },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "Email Address",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "State",
      errorMsg: "State is required.",
    }
  },
};

export default form;
