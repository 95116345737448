

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//  base styles
import colors from "assets/theme/base/colors";

function LockKey({ color, size }) {
    return (
        <svg
            width={size}
            height={size}
            fill={colors[color] ? colors[color].main : colors.dark.main}
            viewBox="0 0 20 12"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.80563 4.59194L9.8055 4.59158C9.21509 2.91314 7.61279 1.78317 5.83333 1.78317C3.50572 1.78317 1.61667 3.67222 1.61667 5.99984C1.61667 8.32745 3.50572 10.2165 5.83333 10.2165C7.61279 10.2165 9.21509 9.08654 9.8055 7.4081L9.80562 7.40774L9.82342 7.35589C9.9205 7.07309 10.1865 6.88317 10.4855 6.88317H14.25C14.6366 6.88317 14.95 7.19657 14.95 7.58317V9.4165C14.95 9.85833 15.3082 10.2165 15.75 10.2165H15.9167C16.3585 10.2165 16.7167 9.85833 16.7167 9.4165V7.58317C16.7167 7.19657 17.0301 6.88317 17.4167 6.88317H17.5833C18.0252 6.88317 18.3833 6.525 18.3833 6.08317V5.9165C18.3833 5.47468 18.0252 5.1165 17.5833 5.1165H10.4855C10.1865 5.1165 9.9205 4.92659 9.82342 4.64378L9.80563 4.59194ZM18.2833 11.0832C18.2833 11.4698 17.9699 11.7832 17.5833 11.7832H14.0833C13.6967 11.7832 13.3833 11.4698 13.3833 11.0832V9.24984C13.3833 8.80801 13.0252 8.44984 12.5833 8.44984H11.5757C11.2652 8.44984 10.9891 8.63142 10.8364 8.89648C9.82125 10.6578 7.91987 11.7832 5.83333 11.7832C2.64428 11.7832 0.05 9.18889 0.05 5.99984C0.05 2.81079 2.64428 0.216504 5.83333 0.216504C7.92062 0.216504 9.81561 1.34275 10.8361 3.10543C10.9889 3.3694 11.2644 3.54984 11.574 3.54984H19.25C19.6366 3.54984 19.95 3.86324 19.95 4.24984V7.74984C19.95 8.13644 19.6366 8.44984 19.25 8.44984H19.0833C18.6415 8.44984 18.2833 8.80801 18.2833 9.24984V11.0832ZM5.83333 8.44984C4.48595 8.44984 3.38333 7.34722 3.38333 5.99984C3.38333 4.65245 4.48595 3.54984 5.83333 3.54984C7.18072 3.54984 8.28333 4.65245 8.28333 5.99984C8.28333 7.34722 7.18072 8.44984 5.83333 8.44984ZM5.83333 5.1165C5.34739 5.1165 4.95 5.51389 4.95 5.99984C4.95 6.48578 5.34739 6.88317 5.83333 6.88317C6.31928 6.88317 6.71667 6.48578 6.71667 5.99984C6.71667 5.51389 6.31928 5.1165 5.83333 5.1165Z" fill="white" stroke="#0270D6" strokeWidth="0.1" />
        </svg>
    );
}

// Setting default values for the props of Basket
LockKey.defaultProps = {
    color: "dark",
    size: "16px",
};

// Typechecking props for the Basket
LockKey.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "white",
    ]),
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LockKey;
