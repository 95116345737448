import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import FormField from "../FormField";

function ForgotForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { email } = formField;
  const { email: emailV } = values;
  return (
    <>
      <SoftBox mb={2}>
        <FormField
          type={email.type}
          label={email.label}
          name={email.name}
          value={emailV}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={emailV.length > 0 && !errors.email}
        />
      </SoftBox>
    </>
  );
}

// typechecking props for UserInfo
ForgotForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ForgotForm;
