import { Card, IconButton, useMediaQuery } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import React from 'react';
import brand from "assets/images/brand-logo.svg";

const DocumentErrorResponse = () => {
  const data = {
    success: true
  }
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <SoftBox style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2)", padding: '30px', maxWidth: '900px' }}>
        <SoftBox textAlign="center" p={isMobile ? 0 : 3} mb={2}>
          <SoftBox
            px={1}
            component="img"
            src={brand}
            style={{ width: isMobile ? 150 : 190 }}
          />
        </SoftBox>
        <SoftTypography
          fontWeight="bold"
          fontSize={20}
        >
          Document Link got expired. Please resubmit the document.
        </SoftTypography>
      </Card>
    </SoftBox>
  );
};

export default DocumentErrorResponse;

