

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//  base styles
import colors from "assets/theme/base/colors";

function Download({ color, size }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 18 18"

            xmlns="http://www.w3.org/2000/svg">
            <path
                fill={colors[color] ? colors[color].main : colors.dark.main}
                d="M16 9V16H2V9H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9H16ZM10 9.67L12.59 7.09L14 8.5L9 13.5L4 8.5L5.41 7.09L8 9.67V0H10V9.67Z" />
        </svg>

    );
}

// Setting default values for the props of Download
Download.defaultProps = {
    color: "dark",
    size: "16px",
};

// Typechecking props for the Download
Download.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "white",
    ]),
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Download;
