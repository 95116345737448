import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";

// @mui material components
import { Card, CircularProgress } from "@mui/material";

//  components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
// import Icon from "@mui/material/Icon";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
// import Separator from "layouts/authentication/components/Separator";
// Images
// import loginBg from "assets/images/login-page/login-background.png";

import LoginInfo from "./components/LoginInfo";
import validations from "./schemas/validations";
import form from "./schemas/form";
import useAuth from "config/hooks/useAuth";
import { postLogin } from "redux/actions/loginAction";
import Footer from "layouts/authentication/components/Footer";
import LockKey from "examples/Icons/LockKey";
import AlertPop from "./components/LoginInfo/Alert";
import { request } from "redux/actions/request";
import { fetchUserInformation } from "redux/actions/users";
import { whitespace } from "stylis";

const Basic = () => {
  const [isActive, setIsActive] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isDanger, setIsDanger] = useState(false);
  const [timer, setTimer] = useState(7000);
  const [email, setEmail] = useState("");
  const [searchParams] = useSearchParams();
  const [successText, setSuccessText] = useState("");
  const [successHeading, setSuccessHeading] = useState("");
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    // debugger
    if (localStorage.getItem("authToken") !== null) {
      navigate("/wallet");
    }

    let activation = searchParams.get("ActivationId");
    let code = searchParams.get("code");
    let email = searchParams.get("emailId");

    if (code && email) {
      let payload = {
        EmailId: email,
        Code: code
      };
      request("Users/ActivateEmail", "POST", payload)
        .then((response) => {
          if (response.Data && response.Data !== "undefined") {
            localStorage.setItem('loginErrResponse', JSON.stringify(response.Data));
          }
          setSuccessText(`You may now sign into the application using ${email}`);
          setSuccessHeading(`Your Email Verification was Successful!`);
          setEmail(email);
          setIsActive(true);
          setTimer(7000);
          if (localStorage.getItem('loginErrResponse') !== null) {
            const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse').length > 0 ? localStorage.getItem('loginErrResponse') : '');
            if (loginErrResponse.EmailConfirmed) {
              navigate('/enrollment')
              return
            }
          }
        })
        .catch((err) => {
          setSuccessHeading(`Error`);
          if (localStorage.getItem('loginErrResponse') !== null) {
            const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse').length > 0 ? localStorage.getItem('loginErrResponse') : '')
            if (loginErrResponse.EmailConfirmed) {
              navigate('/enrollment')
              return
            }
          }
          ErrorHandler(err);
          setTimeout(() => {
            setTimer(7000);
          }, 0)
        });
    }
    if (activation && code) {
      let payload = {
        ActivationId: activation,
        Code: code,
      };
      request("SignUp/VerifyEmailAddress", "POST", payload)
        .then(() => {
          setSuccessText(`You're all set on your journey to learning more. Ready to begin?`);
          setSuccessHeading(`Your Email Verification was Successful!`);
          setEmail(email);
          setTimer(7000);
          setIsActive(true);
        })
        .catch((err) => {
          ErrorHandler(err);
        });
    }
  }, []);

  const { setAuth } = useAuth();
  const { formId, formField } = form;
  const dispatch = useDispatch();
  const currentValidation = validations[0];
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/wallet";
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");

  const submitForm = (values, actions) => {
    localStorage.removeItem('loginErrResponse');
    // setError("");
    let obj = {
      username: values.email,
      password: values.password,
      DeviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
    };
    actions.setTouched({});
    actions.setSubmitting(false);
    setLoading(true);
    dispatch(postLogin(obj))
      .then(async (res) => {
        if (rememberMe) {
          let encodedPassword = base64_encode(values.password);
          let credentials = { email: values.email, password: encodedPassword };
          localStorage.setItem("Credentails", JSON.stringify(credentials));
        } else localStorage.removeItem("Credentails");
        const result = await fetchUserInfo();
        if (result) {
          localStorage.setItem('userInfo', JSON.stringify(result));
        }
        const accessToken = localStorage.getItem("authToken");

        const roles = res.Role;
        setAuth({
          username: obj.username,
          password: obj.password,
          roles: roles,
          accessToken: accessToken,
        });
        setLoading(false);
        setTimer(7000);
        if (res.Role && (res.Role.toLowerCase() === "administrator" || res.Role.toLowerCase() === "superadmin")) {
          navigate("/dashboard/admin", { replace: true });
        } else {
          if (result.IsFirstTimeLogin === "False") {
            navigate("/dashboard/profile", { replace: true });
          } else {
            navigate(from, { replace: true });
          }
        }
      })
      .catch((err) => {
        if (localStorage.getItem('loginErrResponse') !== null) {
          const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse').length > 0 ? localStorage.getItem('loginErrResponse') : '')
          if (loginErrResponse.EmailConfirmed) {
            navigate('/enrollment')
            return
          }
        }
        setEmail(values.email);
        if (err === "NotVerified") {
          setIsActive(true);
          setIsWarning(true);
          setSuccessHeading(`Email Id has not yet been verified. `);
          setIsDanger(false);
          setError(err);
          setLoading(false);
          setTimer(null);
        } else {
          if (localStorage.getItem('loginErrResponse') !== null) {
            const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse').length > 0 ? localStorage.getItem('loginErrResponse') : '')
            if (!loginErrResponse.EmailConfirmed) {
              setIsActive(true);
              setIsWarning(true);
              setSuccessHeading(`Email Id has not yet been verified. `);
              setIsDanger(false);
              setError(err);
              setLoading(false);
              setTimer(null);
            }
          } else {
            ErrorHandler(err);
          }
          // setSuccessHeading("Error");
          setLoading(false);
        }
      });
  };

  // Fetch UserInfo
  async function fetchUserInfo() {
    let email = localStorage.getItem("Email");
    return await dispatch(fetchUserInformation(email));
  }

  function ErrorHandler(err) {
    setError(err);
    setIsActive(true);
    setIsDanger(true);
    setIsWarning(false);
    setTimer(null);
    // setTimeout(() => {
    //   setIsDanger(false);
    //   isWarning(false)
    //   setIsActive(false);
    //   setError("")
    //   setTimer(7000)
    // }, 10000)
  }

  function login(formData) {
    return <LoginInfo formData={formData} rememberMe={rememberMe} setRememberMe={setRememberMe} />;
  }

  function ResentEmail() {
    request("Users/ResendEmailVerificationLink", "POST", { EmailId: email })
      .then(() => {
        setSuccessText(`A new verification link was sent to ${email}`);
        setSuccessHeading(`Email Verification Successfully Resent`);
        setIsWarning(false);
        setIsDanger(false);
        setTimer(7000);
        setIsActive(true);
      })
      .catch((err) => {
        setSuccessHeading("Error");
        ErrorHandler(err);
      });
  }

  function handleNewEnrollment() {
    const loginErrResponse = localStorage.getItem('loginErrResponse');
    if (loginErrResponse !== null) {
      localStorage.removeItem('loginErrResponse');
    }
  }

  return (
    <SoftBox>
      <SoftBox style={{ position: "relative", minHeight: "calc(100vh - 50px)" }}>
        <BasicLayout>
          <SoftBox
            style={{
              width: "50%",
              margin: "0 auto",
              position: "absolute",
              top: 42,
              zIndex: 9,
              left: "25%",
            }}
          >
            <AlertPop
              isWarning={isWarning ?? false}
              isDanger={isDanger ?? false}
              toggle={setIsActive}
              isOpen={isActive}
              timer={timer}
              heading={successHeading}
            >
              {isDanger ? (
                <>{error}</>
              ) : isWarning ? (
                <SoftBox>
                  <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize={13}
                    display="block"
                  >
                    It appears we have not had verification of the {email} address. Please check
                    your Inbox (and/or Spam folder) and click on our link sent from
                    no-reply@medidocpro.com.
                  </SoftTypography>
                  <SoftTypography
                    mt={1}
                    variant="caption"
                    fontWeight="regular"
                    fontSize={11}
                    display="block"
                  >
                    Didnt’ receive the email?&nbsp;{" "}
                    <Link to="#" onClick={ResentEmail}>
                      <SoftTypography
                        fontSize={11}
                        style={{ textDecoration: "underline" }}
                        variant="caption"
                        fontWeight="medium"
                        color="info"
                      >
                        Click here to resend
                      </SoftTypography>
                    </Link>
                  </SoftTypography>
                </SoftBox>
              ) : (
                `${successText}`
              )}
            </AlertPop>
          </SoftBox>
          <SoftBox mt={7}>
            <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2)" }}>
              <SoftBox p={6}>
                <SoftBox mb={1}>
                  <Socials />
                </SoftBox>
                <SoftBox p={3} pb={2}>
                  {/* {error !== "" && (
                  <SoftAlert color="error" dismissible>
                    {alertContent(error)}
                  </SoftAlert>
                )} */}
                  <SoftBox mb={1.5} textAlign="center">
                    <SoftTypography
                      variant="caption"
                      fontWeight="light"
                      fontStyle="italic"
                      fontSize={11}
                    >
                      Please enter your <strong>Email</strong> and <strong>Password</strong> to sign
                      in (both Required):
                    </SoftTypography>
                  </SoftBox>
                  {initialValues ? (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={currentValidation}
                      onSubmit={submitForm}
                    >
                      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
                        <Form id={formId} autoComplete="off">
                          <SoftBox>
                            {login({
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                              submitForm,
                            })}
                            <SoftBox mt={4} mb={1}>
                              <SoftButton
                                variant="gradient"
                                color="info"
                                fullWidth
                                type="submit"
                                disabled={loading}
                                circular={true}
                                size="large"
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: 16,
                                  fontWeight: 500,
                                  whiteSpace: 'nowrap'
                                }}
                              // textTransform={"capitalize"}
                              >
                                {!loading && (
                                  <>
                                    {`sign in`}&nbsp;&nbsp; <LockKey size={24} />
                                  </>
                                )}
                                {loading && <CircularProgress size={18} color="white" />}
                              </SoftButton>
                            </SoftBox>
                          </SoftBox>
                        </Form>
                      )}
                    </Formik>
                  ) : null}
                </SoftBox>
                <SoftBox p={0} textAlign="center">
                  <SoftTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize={14}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;Problems signing in?&nbsp;
                  </SoftTypography>
                  <Link to="/forgot-password">
                    <SoftTypography
                      variant="caption"
                      color="info"
                      fontWeight="regular"
                      fontSize={14}
                      style={{ textDecoration: "underline" }}
                    >
                      Forgot Password
                    </SoftTypography>
                  </Link>
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
          <SoftBox pt={2} textAlign="center">
            <SoftTypography
              variant="caption"
              fontWeight="regular"
              fontSize={14}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;New to MediDocPro? &nbsp;
            </SoftTypography>
            <Link to="/enrollment">
              <SoftTypography
                variant="caption"
                color="info"
                fontWeight="bold"
                textGradient={true}
                fontSize={14}
                onClick={handleNewEnrollment}
              >
                Enroll Now
              </SoftTypography>
            </Link>
          </SoftBox>
          <SoftBox pt={1} mb={5} textAlign="center">
            <SoftTypography
              variant="caption"
              fontWeight="regular"
              fontSize={14}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;Just want to stay in the know? &nbsp;
            </SoftTypography>
            <Link to="/signup">
              <SoftTypography
                variant="caption"
                color="info"
                fontWeight="bold"
                fontSize={14}
                textGradient={true}
              >
                Get Our Newsletter
              </SoftTypography>
            </Link>
          </SoftBox>
        </BasicLayout>
      </SoftBox>
      <Footer />
    </SoftBox>
  );
};

export default Basic;
