import * as Actions from "../../constants/constants";
import { request } from "../request";

export const fetchUserManagementList = (payload) => (dispatch) => {
    return request(`AdminUserManagement`, "POST", payload)
        .then((response) => {
            dispatch({
                type: Actions.GET_USER_MANAGEMENT_SUCCESS,
                payload: response.Data,
            });

            return Promise.resolve(response.Data)
        })
        .catch((error) => {
            dispatch({
                type: Actions.GET_USER_MANAGEMENT_FAIL,
                payload: error
            })
        });
}