export const FETCH_SIMPLE_DETAILS = 'FETCH_SIMPLE_DETAILS';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const FETCH_ROLES = 'FETCH_ROLES'

// Patients
export const FETCH_PATIENTS = "FETCH_PATIENTS";
export const FETCH_PATIENT_BY_ID = "FETCH_PATIENT_BY_ID";
export const FETCH_PATIENT_BY_NAME = "FETCH_PATIENT_BY_NAME";
export const POST_PATIENT = "POST_PATIENT";
export const PUT_PATIENT = "PUT_PATIENT";
export const PUT_PATIENT_BY_STATUS = 'PUT_PATIENT_BY_STATUS'

// Clients
export const FETCH_CLIENTS = 'FETCH_CLIENTS'
export const EMPTY_CLIENTS = 'EMPTY_CLIENTS'

//Langugages
export const FETCH_LANGUAGES = "FETCH_LANGUAGES"

// AppointmentType
export const FETCH_APPOINTMENT_TYPE = 'FETCH_APPOINTMENT_TYPE'

//Facility
export const FETCH_FACILITY_BY_CLIENT = "FETCH_FACILITY_BY_CLIENT"
export const EMPTY_FACILITY = "EMPTY_FACILITY"

//Buildings
export const FETCH_BUILDINGS_BY_FACILITY = "FETCH_BUILDINGS_BY_FACILITY"
export const EMPTY_BUILDING = "EMPTY_BUILDING"

// Appointments

export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS"
export const POST_MULTIPLE_APPOINTMENTS = "POST_MULTIPLE_APPOINTMENTS"

// Interpreter
export const FETCH_INTERPRETER = "FETCH_INTERPRETER"

// DEPARTMENT

export const FETCH_DEPARTMENT_BY_BUILDING_ID = "FETCH_DEPARTMENT_BY_BUILDING_ID"
export const EMPTY_DEPARTMENT = "EMPTY_DEPARTMENT"

export const TEMP_APP_INFO = "TEMP_APP_INFO"
export const TEMP_APP_PATIENT_INFO = "TEMP_APP_PATIENT_INFO"

// Guest Enrollment
export const STATE_INFO = "STATE_INFO"

// User Personal Information
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS"
export const USER_INFO_FAIL = "USER_INFO_FAIL"

// Get List Of Document
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS"
export const GET_DOCUMENT_LIST_FAIL = "GET_DOCUMENT_LIST_FAIL"

// Get List Of Document
export const GET_DOCUMENT_TYPES_LIST_SUCCESS = "GET_DOCUMENT_TYPES_LIST_SUCCESS"
export const GET_DOCUMENT_TYEPS_LIST_FAIL = "GET_DOCUMENT_TYEPS_LIST_FAIL"

// Get List Of Requested Document
export const GET_REQUESTED_DOCUMENT_LIST_SUCCESS = "GET_REQUESTED_DOCUMENT_LIST_SUCCESS"
export const GET_REQUESTED_DOCUMENT_LIST_FAIL = "GET_REQUESTED_DOCUMENT_LIST_FAIL"

// Get List Of Document Types
export const GET_DOCUMENT_TYPE_LIST_SUCCESS = "GET_DOCUMENT_TYPE_LIST_SUCCESS"
export const GET_DOCUMENT_TYPE_LIST_FAIL = "GET_DOCUMENT_TYPE_LIST_FAIL"

// Get User Management Types
export const GET_USER_MANAGEMENT_SUCCESS = "GET_USER_MANAGEMENT_SUCCESS"
export const GET_USER_MANAGEMENT_FAIL = "GET_USER_MANAGEMENT_FAIL"

// Get Designated Contacts 
export const GET_DESIGNATED_CONTACT_SUCCESS = "GET_DESIGNATED_CONTACT_SUCCESS"
export const GET_DESIGNATED_CONTACT_FAIL = "GET_DESIGNATED_CONTACT_FAIL"

// Get QR Code
export const GET_QR_CODE_SUCCESS = "GET_QR_CODE_SUCCESS"
export const GET_QR_CODE_FAIL = "GET_QR_CODE_FAIL"

// Records Health Care Provider
export const GET_RECORD_APPROVED = "GET_RECORD_APPROVED"
export const GET_RECORD_FAIL = "GET_RECORD_FAIL"

// Records Request User Info
export const GET_RECORD_REQUEST = "GET_RECORD_REQUEST"
export const FAIL_RECORD_REQUEST = "FAIL_RECORD_REQUEST"

// Logout 
export const LOGOUT = "LOGOUT"

// Payment Info
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS"
export const PRODUCT_LIST_FAILED = "PRODUCT_LIST_FAILED"
export const PRODUCT_VALUE_SELECTED = "PRODUCT_VALUE_SELECTED"
export const PRODUCT_VALUE_REMOVED = "PRODUCT_VALUE_REMOVED"
export const SCUBSCRIBE_CANCEL_SUCCESS = "SCUBSCRIBE_CANCEL_SUCCESS"
export const SCUBSCRIBE_CANCEL_FAILED = "SCUBSCRIBE_CANCEL_FAILED"