
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DataTable from "examples/Tables/DataTable";
import { request, authRequest } from "redux/actions/request";
import SoftTypography from "components/SoftTypography";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";

function ShareDocuments({ formData }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { activeStep, setActiveStep } = formData
    const [tableList, setTableList] = useState([])
    const [isSetup, setIsSetup] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const { documentList: { data } } = useSelector(state => state)

    useEffect(() => {
        let list = data.map((x) => ({
            id: x.Document_Id,
            files: "Share on Request",
            // type: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
            type: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType.toLowerCase() === "other"
            ? x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName
            : x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
            name: x.Document_Name,
            contact: x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName
        }))
        setTableList(list)
    }, [data])

    function shareRequest() {
        setLoading(true)
        let payload = selectedRow.map(x => ({ Document_Id: x.id, Sharable: "True" }))
        let email = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")).Email : null;
        if (payload.length) {
            request("Document/Documents", "PUT", payload)
                .then(() => {
                    setActiveStep(activeStep + 1)
                    setLoading(false)
                    // console.log(result)
                    setIsSetup(true)
                    fetchDocuments(100)
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                })
        }
        if (email) {
            authRequest(`Users/UpdateFirstTimeUser/${email}`, "PUT", {})
                .then((result) => { setActiveStep(activeStep + 1); setLoading(false); console.log(result); setIsSetup(true) })
                .catch((err) => { console.log(err); setIsSetup(false); setLoading(false) })
        }
    }

    // fetch document list
    async function fetchDocuments(count) {
        return await dispatch(fetchDocumentList(count))
    }


    return (
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
            {!isSetup
                ? <Grid item xs={12} lg={7}>
                    <SoftBox mt={2}>
                        <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                            <SoftBox p={2.5}>
                                <SoftBox px={1} py={0.5}>
                                    <SoftTypography variant="h5" fontWeight="bold" color="info" textGradient={true} mb={1}>Share Documents</SoftTypography>
                                    <SoftTypography variant="caption" fontWeight="regular" display="block" fontSize={14} mb={4}>In order to share documents upon requests made to MediDocPro, you will need to designate them by checking their corresponding boxes below.</SoftTypography>
                                </SoftBox>
                                <DataTable
                                    showTotalEntries={false}
                                    entriesPerPage={false}
                                    isCheckbox={true}
                                    onRowSelectStateChange={setSelectedRow}
                                    selectAll={true}
                                    table={{
                                        columns: [
                                            { Header: "Select files below", accessor: "files", width: "28%" },
                                            { Header: "Document Type", accessor: "type", width: "25%" },
                                            { Header: "File Name", accessor: "name", width: "25%" },
                                            { Header: "Contact Info", accessor: "contact", width: "25%" },
                                        ],
                                        rows: tableList
                                    }}
                                />
                                <SoftBox width="100%" display="flex" justifyContent="end" mt={2}>
                                    <SoftButton
                                        variant="text"
                                        circular={true}
                                        color="dark"
                                        onClick={() => setActiveStep(activeStep - 1)}
                                        style={{ marginRight: 10, padding: 13, textTransform: 'capitalize', fontWeight: 500 }}>
                                        back
                                    </SoftButton>
                                    <SoftButton
                                        type="button"
                                        variant="gradient"
                                        color="info"
                                        circular={true}
                                        disabled={loading}
                                        style={{ textTransform: 'capitalize', fontWeight: 500 }}
                                        onClick={shareRequest}
                                    >
                                        {loading && <CircularProgress size={14} color="info" />}
                                        {!loading && "Continue"}
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    </SoftBox>
                </Grid>
                : null}
        </Grid>
    )
}
// typechecking props for ShareDocuments
ShareDocuments.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ShareDocuments;