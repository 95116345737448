// Password Validator
let lowerCaseLetters = /[a-z]/g;
let upperCaseLetters = /[A-Z]/g;
let numbers = /[0-9]/g;
export const PasswordValidator = (value) => {
    let count = 0;

    if (value.match(lowerCaseLetters)) count += 25;

    if (value.match(upperCaseLetters)) count += 25;

    if (value.match(numbers)) count += 25;

    if (value.length >= 8) count += 25;

    return count
}

export const formatPhoneNumber = (phoneNumberString) => {
    if (!phoneNumberString) return
    let sliced = phoneNumberString.length > 10 ? phoneNumberString.slice(1, 12) : phoneNumberString;
    let cleaned = ('' + sliced).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const fileNameWithExtension = (fileName) => {
    if (!fileName) return
    if (fileName.length > 25) {
        let getExtension = fileName.slice(fileName.lastIndexOf("."));
        let name = fileName.slice(0, 24);
        return name + "..." + getExtension;
    } else return fileName;
}

export const bytesToMegaBytes = (bytes) => {
    switch (true) {
        case (bytes / 1024).toFixed() > 1023:
            return (bytes / (1024 * 1024)).toFixed() + "MB";
        default:
            return (bytes / 1024).toFixed() + "KB";
    }
}

export const documentOptions = [
    { label: "Health Care Surrogate", value: "hcs" },
    { label: "Power of Attorney", value: "poa" },
    { label: "Do Not Resuscitate", value: "dnr" },
    { label: "Identification Documents", value: "id" },
    { label: "Vaccination Cards", value: "vc" },
    { label: "Other", value: "other" },
]

export const PasswordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/

export const phoneRegExp = /^(\+\d{1,2}\s?\(\d{3}\)\s?\d{3}-\d{4})|(\+\d{1,2}\s?\d{3}-\d{3}-\d{4})|(\(\d{3}\)\s?\d{3}-\d{4})|(\d{3}-\d{3}-\d{4})$/

