import { useEffect, useState, useMemo } from "react";
import { Card, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import Papa from "papaparse";

import DataTable from "examples/Tables/DataTable";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";

import DeleteUser from "./DeleteUser";

import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import { fetchUserManagementList } from "redux/actions/user-management";
import ShowQRCode from "./ShowQRCode";
import { QRCode } from "layouts/pages/wallet/components/GenerateCard/QRCode";
import UpdateAccount from "layouts/pages/profile/component/UpdateAccount";
import { fetchUserInformation } from "redux/actions/users";
import Member from "../CreateUser";
import CreateNewUser from "../CreateUser/CreateNewUser";
import Complete from "../CreateUser/Complete";
import { downloadJSONToCSVWithFileName } from "utils";
import { ViewAccount } from "./ViewAccount";
import CreateNewAdmin from "../CreateUser/CreateNewAdmin";

const filterList = [
  { value: null, label: "All Roles" },
  { value: "Member", label: "Members" },
  { value: "Administrator", label: "Admins" },
];

const UserManagement = (props) => {
  const dispatch = useDispatch();
  const [isQRCode, setIsQRCode] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [tableList, setTableList] = useState(null);
  const [data, setData] = useState(null);
  const [isOpening, setIsOpening] = useState(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [update, setUpdate] = useState(null);
  const [creatingRole, setCreatingRole] = useState(null);
  const [roleFilterList, setRoleFilterList] = useState(null);
  const [isView, setIsView] = useState(false);
  const [filterSelectValue, setFilterSelectValue] = useState(null);
  const [alert, setAlert] = useState({
    heading: "",
    text: "",
    isError: false,
  });

  const [openAccount, setOpenAccount] = useState(false);
  const [openNewAccount, setOpenNewAccount] = useState(false);
  const [openNewAdmin, setOpenNewAdmin] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]); // State to store selected rows
  const userDetails = localStorage.getItem("userDetails");
  const role = userDetails ? JSON.parse(userDetails).Role : null;

  // Pagination settings
  const pageSize = 100;
  const currentPage = 1;
  const [total, setTotal] = useState(0);
  // const entriesPerPageOptions = [5, 10, 15, 20, 25]; // Options for entries per page
  // const defaultEntriesPerPage = 10; // Default entries per page

  // Handle page change
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  useEffect(() => {
    setFilterSelectValue({ value: null, label: "All Roles" });
    fetchUsersList({
      PageNumber: currentPage,
      PageSize: pageSize,
      FilterBy: null,
    });
  }, [update]);

  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails");
    let role = userDetails ? JSON.parse(userDetails).Role : null;
    if (role && role.toLowerCase() === "superadmin") {
      setRoleFilterList([...filterList, { label: "Super Admin", value: "SuperAdmin" }]);
    } else {
      setRoleFilterList([...filterList]);
    }
  }, []);

  const tableDataWithColumn = useMemo(
    () => ({
      columns: [
        {
          Header: () => (
            <SoftTypography variant="contained">
              Name&nbsp;
              <SoftTypography variant="caption" fontSize={11}>
                (First / Last)
              </SoftTypography>
            </SoftTypography>
          ),
          accessor: "Name",
          width: "120px",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox>
                {original.FirstName && original.FirstName} {original.LastName && original.LastName}
              </SoftBox>
            );
          },
        },
        {
          Header: "Role",
          accessor: "RoleName",
          width: "70px",
          Cell: ({ row: { original } }) => {
            if (original.RoleName && original.RoleName.toLowerCase() === "paidmember")
              return "Member";
            if (original.RoleName && original.RoleName.toLowerCase() === "administrator")
              return "Admin";
            if (original.RoleName && original.RoleName.toLowerCase() === "superadmin")
              return "Super Admin";
          },
        },
        {
          Header: "Email Address",
          accessor: "EmailAddress",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox>
                {original.EmailAddress && (
                  <u>
                    <SoftTypography
                      //   noWrap
                      variant="contained"
                      component="a"
                      href={`mailto:${original.EmailAddress}`}
                      //   style={{ width: 220, display: "block" }}
                      title={original.EmailAddress}
                    >
                      {original.EmailAddress}
                    </SoftTypography>
                  </u>
                )}
              </SoftBox>
            );
          },
        },
        {
          Header: "Phone Number",
          accessor: "SMSCapableNumber",
        },
        {
          Header: "Address",
          accessor: "Address",
          width: "200px",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox>
                {original.Street1 && <span>{original.Street1} </span>}
                {original.Street2 && <span>{original.Street2} </span>}
                {original.State && <span>{original.State} </span>}
                {original.City && <span>{original.City} </span>}
                {original.PostalCode && <span>{original.PostalCode}</span>}
              </SoftBox>
            );
          },
        },
        {
          Header: "QR Code",
          accessor: "status",
          width: "75px",
          Cell: ({ row: { original } }) => {
            if (original && original?.RoleName?.toLowerCase() === "paidmember") {
              return (
                <SoftBox textAlign="left">
                  <IconButton color="default" sx={{ p: 0 }} onClick={() => showQRCode(original)}>
                    <span
                      className="material-icons-outlined"
                      style={{ fontSize: 25, fontWeight: 500 }}
                    >
                      zoom_in
                    </span>
                  </IconButton>
                </SoftBox>
              );
            }
          },
        },
        {
          Header: () => <span className="table-action">Action</span>,
          accessor: "Action",
          sortable: false,
          width: "60px",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox display="flex" alignItems="center" justifyContent="start">
                {role &&
                  role.toLowerCase() === "administrator" &&
                  ((original.RoleName && original.RoleName.toLowerCase() === "administrator") ||
                    (original.RoleName && original.RoleName.toLowerCase() === "superadmin")) ? (
                  <Tooltip title="View User" placement="top">
                    <SoftBox>
                      <IconButton
                        sx={{ p: 0, mr: 1 }}
                        onClick={() => fetchAndShowUserInfo(original.EmailAddress)}
                      >
                        <span
                          className="material-icons-outlined"
                          style={{ fontSize: 20, fontWeight: 700 }}
                        >
                          visibility
                        </span>
                      </IconButton>
                    </SoftBox>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit User" placement="top">
                    <SoftBox>
                      <IconButton
                        sx={{ p: 0, mr: 1 }}
                        onClick={() => fetchUserInfo(original.EmailAddress)}
                      >
                        <span
                          className="material-icons-outlined"
                          style={{ fontSize: 20, fontWeight: 700 }}
                        >
                          edit
                        </span>
                      </IconButton>
                    </SoftBox>
                  </Tooltip>
                )}
                {role && role.toLowerCase() === "superadmin" ? (
                  <Tooltip title="Delete User" placement="top">
                    <SoftBox>
                      <IconButton sx={{ p: 0 }}>
                        <span
                          className="material-icons-outlined"
                          style={{ fontSize: 20, fontWeight: 700 }}
                          onClick={() => onSingleDelete(original)}
                        >
                          delete
                        </span>
                      </IconButton>
                    </SoftBox>
                  </Tooltip>
                ) : null}
              </SoftBox>
            );
          },
        },
      ],
      rows: tableList || [],
    }),
    [tableList]
  );

  // Fetch UserInfo
  async function fetchUserInfo(email) {
    setIsOpening(true);
    const result = await dispatch(fetchUserInformation(email));
    setData(result);
    setIsEdit(true);
    setIsOpening(false);
  }

  // Fetch and show User Info
  async function fetchAndShowUserInfo(email) {
    setIsOpening(true);
    const result = await dispatch(fetchUserInformation(email));
    setData(result);
    setIsOpening(false);
    setIsView(true);
  }

  // fetch document list
  async function fetchUsersList(payload) {
    setLoading(true);
    setTotal(0);
    const result = await dispatch(fetchUserManagementList(payload));
    const mappedData = result.Data.map((x) => {
      let disabled = false;
      if (role) {
        const lowerCaseRole = role.toLowerCase();
        const lowerCaseRoleName = x.RoleName ? x.RoleName.toLowerCase() : "";
        if (
          lowerCaseRole === "administrator" &&
          (lowerCaseRoleName === "administrator" || lowerCaseRoleName === "superadmin")
        ) {
          disabled = true;
        } else if (lowerCaseRole === "superadmin" && lowerCaseRoleName === "superadmin") {
          disabled = true;
        }
      }
      return {
        ...x,
        disabled: disabled,
      };
    });
    setTotal(mappedData.length);
    setTableList(mappedData);
    setLoading(false);
  }

  function exportUserList() {
    const dstr = selectedRow.map((row) => ({
      Name: row.FirstName + " " + row.LastName,
      Role: row.RoleName,
      EmailAddress: row.EmailAddress,
      Phone: row.SMSCapableNumber,
      Address: `${row.Street1} ${row.Street2} ${row.State} ${row.City} ${row.PostalCode}`,
    }));
    const csvData = Papa.unparse(dstr);
    downloadJSONToCSVWithFileName(csvData, "MDPUserList.csv");
  }

  function onFilterChange(data) {
    setFilterSelectValue(data);
    fetchUsersList({
      PageNumber: currentPage,
      PageSize: pageSize,
      FilterBy: data.value,
    });
  }

  function onSelectEventHandler(params) {
    if (params && params.length) setIsSelect(true);
    else setIsSelect(false);
    setSelectedRow(params);
  }

  function showQRCode(userId) {
    setUserInfo(userId);
    setIsQRCode(true);
  }

  function onSingleDelete(data) {
    setSelectedRow(data);
    setDeleteOpen(true);
  }

  function newMemberEventHandler(role) {
    setCreatingRole(role);
    if (role && role === "Administrator") {
      setOpenNewAdmin(true);
      return;
    }
    setOpenNewAccount(true);
    // setOpenAccount(false)
  }

  return (
    <SoftBox mt={4}>
      {visible ? (
        <AlertPop
          isDanger={alert.isError}
          toggle={setVisible}
          timer={7000}
          isOpen={visible}
          heading={alert.heading}
        >
          {alert.text}
        </AlertPop>
      ) : null}
      <Card
        sx={{ height: "100%" }}
        style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)", overflow: "inherit" }}
      >
        <SoftBox p={3}>
          {!isSelect && (
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={5}>
              <Grid container>
                <Grid md={6} xs={12}>
                  <SoftTypography fontSize={20} fontWeight="bold" display="flex" alignItems="center">
                    User List &nbsp;<SoftTypography variant="caption">[ {total} TOTAL ]</SoftTypography>
                  </SoftTypography>
                </Grid>
                <Grid md={3} xs={12}>
                  <SoftBox display="flex" alignItems="center">
                    <SoftTypography variant="caption" color="dark" style={{ whiteSpace: "nowrap" }}>
                      Filter by:&nbsp;&nbsp;
                    </SoftTypography>
                    <SoftSelect
                      defaultValue={{ value: null, label: "All Roles" }}
                      marginBottom="5px"
                      options={roleFilterList}
                      onChange={onFilterChange}
                      value={filterSelectValue}
                    />
                    &nbsp;&nbsp;
                  </SoftBox>
                  {/* <SoftButton
                  variant="outlined"
                  color="info"
                  disabled
                  size="medium"
                  circular
                  sx={{ ml: 2, mr: 2 }}
                >
                  <span className="material-icons-outlined" style={{ fontSize: 20 }}>
                    search
                  </span>
                  &nbsp;&nbsp;Search
                </SoftButton> */}

                </Grid>
                <Grid item md={3} xs={12}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    size="medium"
                    circular
                    onClick={() => setOpenAccount(true)}
                    disabled={isOpening}
                  >
                    {isOpening ? (
                      <SoftBox display="flex" justifyContent="center" alignItems="center" width={150}>
                        <CircularProgress size={18} color="white" />
                      </SoftBox>
                    ) : (
                      <>
                        <span className="material-icons-outlined" style={{ fontSize: 20 }}>
                          person_add_alt
                        </span>
                        &nbsp;&nbsp;Create New User
                      </>
                    )}
                  </SoftButton></Grid>
              </Grid>
            </SoftBox>
          )}
          {isSelect && (
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={5}>
              <Grid container>
                <Grid md={8} xs={12}>
                  <SoftTypography fontSize={20} fontWeight="bold">
                    {selectedRow.length} row(s) selected
                  </SoftTypography>
                </Grid>
                <Grid md={4} xs={12}>
                  <SoftBox>
                    {role && role.toLowerCase() === "superadmin" ? (
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        size="medium"
                        
                        circular
                        style={{ marginRight: 10, marginBottom:"5px"}}
                        onClick={() => {
                          setData(selectedRow);
                          setDeleteOpen(true);
                        }}
                      >
                        <span className="material-icons-outlined" style={{ fontSize: 20 }}>
                          delete
                        </span>
                        &nbsp;&nbsp;Delete Selected
                      </SoftButton>
                    ) : null}
                    {role && role.toLowerCase() === "superadmin" ? (
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        size="medium"
                        circular
                        style={{ marginRight: 10 }}
                        onClick={exportUserList}
                      >
                        <span className="material-icons-outlined" style={{ fontSize: 20 }}>
                          file_upload
                        </span>
                        &nbsp;&nbsp;Export Selected
                      </SoftButton>
                    ) : null}
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          )}
          {loading ? (
            <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
              <CircularProgress size={25} color="info" />
            </SoftBox>
          ) : (
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              isCheckbox={true}
              onRowSelectStateChange={onSelectEventHandler}
              selectAll={true}
              table={tableDataWithColumn}
              pagination={{
                currentPage: currentPage,
                onPageChange: handlePageChange,
              }}
            />
          )}
        </SoftBox>
        {isQRCode ? (
          <ShowQRCode handleClose={() => setIsQRCode(false)} open={isQRCode} title="QR Code">
            <SoftBox sx={{ width: 200 }} p={2} pt={0}>
              <QRCode UserId={userInfo?.Aspnet_UserID} />
            </SoftBox>
          </ShowQRCode>
        ) : null}
        {isEdit ? (
          <UpdateAccount
            data={data}
            open={isEdit}
            setOpen={setIsEdit}
            setAlert={setAlert}
            setVisible={setVisible}
            setUpdate={setUpdate}
          />
        ) : null}
        {isView ? <ViewAccount data={data} open={isView} setOpen={setIsView} /> : null}
        {deleteOpen ? (
          <DeleteUser
            data={selectedRow}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            setAlert={setAlert}
            setVisible={setVisible}
            setUpdate={setUpdate}
          />
        ) : null}
        {openAccount ? (
          <Member
            open={openAccount}
            setOpen={setOpenAccount}
            openNewMemberHandler={newMemberEventHandler}
          />
        ) : null}
        {openNewAccount ? (
          <CreateNewUser
            role={creatingRole}
            open={openNewAccount}
            setOpen={setOpenNewAccount}
            submitHandlerEvent={() => {
              setOpenNewAccount(false);
              setOpenAccount(false);
              setIsCreateAccount(true);
            }}
            setUpdate={setUpdate}
          />
        ) : null}
        {openNewAdmin ? (
          <CreateNewAdmin
            role={creatingRole}
            open={openNewAdmin}
            setOpen={setOpenNewAdmin}
            submitHandlerEvent={() => {
              setOpenNewAdmin(false);
              setOpenAccount(false);
              setIsCreateAccount(true);
            }}
            setUpdate={setUpdate}
          />
        ) : null}
        {isCreateAccount ? (
          <Complete
            open={isCreateAccount}
            onBackList={() => {
              setOpenAccount(false);
              setOpenNewAccount(false);
              setIsCreateAccount(false);
            }}
            creatingRole={creatingRole}
          />
        ) : null}
      </Card>
    </SoftBox>
  );
};

export default UserManagement;
