import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import FormField from "../FormField";
import { useEffect, useState } from "react"
import SoftProgress from "components/SoftProgress";
import SoftTypography from "components/SoftTypography";
import { PasswordValidator } from "utils/constant";
import circle from "./icon.svg"
import { Grid } from "@mui/material";

function ForgotForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { password, repeatPassword } = formField;
  const { password: passwordV,
    repeatPassword: repeatPasswordV, } = values;
  const [isPass1, setIsPass1] = useState(false)
  const [isPass2, setIsPass2] = useState(false)
  const [progressValue, setProgressValue] = useState(0)

  useEffect(() => {
    let count = PasswordValidator(passwordV)
    setProgressValue(count)
  }, [passwordV])

  return (
    <>
      <Grid container>
        <Grid item xs={7} sm={7}>
          <SoftBox mb={2}>
            <FormField
              type={isPass1 ? "text" : "password"}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              inputProps={{ autoComplete: "" }}
              icon={{
                component: isPass1 ? "visibility_off" : "visibility",
                direction: "right"
              }}
              iconClickHandler={() => setIsPass1(!isPass1)}
            />
            {/* {progressValue > 0 ? <>
              <SoftProgress
                value={progressValue}
                variant="gradient"
                color={progressValue <= 30 ? "warning" : progressValue <= 80 ? "info" : progressValue <= 100 ? "success" : "primary"}
              />
              <SoftTypography
                variant="caption"
                fontWeight="light"
                color={progressValue <= 30 ? "warning" : progressValue <= 80 ? "info" : progressValue <= 100 ? "success" : "primary"}>
                {progressValue <= 30 ? "Week" : progressValue <= 80 ? "Good" : progressValue <= 100 ? "Strong" : ""}
              </SoftTypography>
            </> : null} */}
          </SoftBox>
          <SoftBox mb={2}>
            <FormField
              type={isPass2 ? "text" : "password"}
              label={repeatPassword.label}
              name={repeatPassword.name}
              value={repeatPasswordV}
              placeholder={repeatPassword.placeholder}
              error={errors.repeatPassword && touched.repeatPassword}
              success={repeatPasswordV.length > 0 && !errors.repeatPassword}
              inputProps={{ autoComplete: "" }}
              icon={{
                component: isPass2 ? "visibility_off" : "visibility",
                direction: "right"
              }}
              iconClickHandler={() => setIsPass2(!isPass2)}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={5} sm={5}>
          <SoftBox pl={2.5} py={3.5}>
            <SoftTypography variant="h6" mb={1} fontWeight="bold" color="dark" fontSize={15}>Password Requirements</SoftTypography>
            <SoftTypography variant="caption" display="block" fontWeight="regular" mb={2} style={{ color: '#67748e', listStyleType: 'disc' }}>Please follow this guide for a strong password.</SoftTypography>
            <SoftBox style={{ color: '#67748e' }} component="ul" fontSize={12} display="flex" flexDirection="column" p={0} m={0}>
              {/* <SoftBox component="li" display="flex" flexDirection="column">
                <SoftBox display="flex" alignItems="center">
                  <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={0} style={{ color: '#67748e' }}>
                    A Minimum of:
                  </SoftTypography>
                </SoftBox>
              </SoftBox> */}
              <SoftBox component="li" display="flex" flexDirection="column">
                <SoftBox display="flex" alignItems="center">
                  <SoftBox width={7} component="img" src={circle}></SoftBox>
                  <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                    One lowercase character
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox component="li" display="flex" flexDirection="column">
                <SoftBox display="flex" alignItems="center">
                  <SoftBox width={7} component="img" src={circle}></SoftBox>
                  <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                    One uppercase character
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox component="li" display="flex" flexDirection="column">
                <SoftBox display="flex" alignItems="center">
                  <SoftBox width={7} component="img" src={circle}></SoftBox>
                  <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                    One number
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox component="li" display="flex" flexDirection="column">
                <SoftBox display="flex" alignItems="center">
                  <SoftBox width={7} component="img" src={circle}></SoftBox>
                  <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                    1 special character (ex. !@#$%)
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox component="li" display="flex" flexDirection="column">
                <SoftBox display="flex" alignItems="center">
                  <SoftBox width={7} component="img" src={circle}></SoftBox>
                  <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                    8 characters minimum
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </>
  );
}

// typechecking props for UserInfo
ForgotForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ForgotForm;
