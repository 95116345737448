import SoftBox from 'components/SoftBox'
import React, { useState } from 'react'
import { CircularProgress, Grid } from "@mui/material"
import { Form, Formik } from "formik";
import * as Yup from "yup";

import circle from "../Icons/icon.svg"

import SoftTypography from "components/SoftTypography"
import FormField from 'layouts/authentication/reset-password/components/FormField';
import { PasswordValidationRegex } from 'utils/constant';
import SoftButton from 'components/SoftButton';
import { request } from 'redux/actions/request';
import SoftAlert from 'components/SoftAlert';

const validations = [
  Yup.object().shape({
    ['oldPassword']: Yup.string().required("Password is required.").min(8, "Your password should be more than 8 characters.").matches(PasswordValidationRegex, "Password must match to the standard."),
    ['newPassword']: Yup.string().required("Password in required.").min(8, "Your password should be more than 8 characters.").matches(PasswordValidationRegex, "Password must match to the standard."),
    ['confirmPassword']: Yup.string()
      .required("Password in required.")
      .oneOf([Yup.ref("newPassword"), null], "Your password doesn't match."),
  }),
];

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const Password = (props) => {
  const { data, setAlert, setOpen, setVisible } = props
  const [loading, setLoading] = useState(false)
  const [isPass1, setIsPass1] = useState(false)
  const [isPass2, setIsPass2] = useState(false)
  const [isPass3, setIsPass3] = useState(false)
  const [error, setError] = useState("")

  function handleSubmit(values) {
    setLoading(true)
    let payload = {
      EmailId: data.EmailAddress,
      OldPassword: values.oldPassword,
      NewPassword: values.newPassword
    }
    request("Users/ResetNewPassword", "POST", payload)
      .then(() => {
        setLoading(false)
        setOpen(false)
        setError("")
        setAlert({
          heading: "Password Successfully Changed!",
          text: "You may login with new password.",
          isError: false,
          timer: 7000
        })
        setVisible(true)
      })
      .catch(err => {
        setVisible(false)
        setError(err)
        setLoading(false)
      })
  }

  return (
    <SoftBox>
      {error !== "" ? <SoftAlert color="error" dismissible mb={2}>{error}</SoftAlert> : null}
      <Formik initialValues={initialValues} validationSchema={validations[0]} onSubmit={handleSubmit}>
        {({ values, errors, touched }) => (
          <Form id="changePassword" autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      type={isPass1 ? "text" : "password"}
                      label="Old Password"
                      name="oldPassword"
                      placeholder="Enter Old Password"
                      error={errors.oldPassword && touched.oldPassword}
                      success={values.oldPassword.length > 0 && !errors.oldPassword}
                      inputProps={{ autoComplete: "" }}
                      icon={{
                        component: isPass1 ? "visibility_off" : "visibility",
                        direction: "right"
                      }}
                      iconClickHandler={() => setIsPass1(!isPass1)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      type={isPass2 ? "text" : "password"}
                      label="New Password"
                      name="newPassword"
                      placeholder="Enter New Password"
                      error={errors.newPassword && touched.newPassword}
                      success={values.newPassword.length > 0 && !errors.newPassword}
                      inputProps={{ autoComplete: "" }}
                      icon={{
                        component: isPass2 ? "visibility_off" : "visibility",
                        direction: "right"
                      }}
                      iconClickHandler={() => setIsPass2(!isPass2)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      type={isPass3 ? "text" : "password"}
                      label="Confirm Password"
                      name="confirmPassword"
                      placeholder="Enter Confirm Password"
                      error={errors.confirmPassword && touched.confirmPassword}
                      success={values.confirmPassword.length > 0 && !errors.confirmPassword}
                      inputProps={{ autoComplete: "" }}
                      icon={{
                        component: isPass3 ? "visibility_off" : "visibility",
                        direction: "right"
                      }}
                      iconClickHandler={() => setIsPass3(!isPass3)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftTypography variant="h6" mb={1} fontWeight="bold" color="dark">Password Requirements</SoftTypography>
                <SoftTypography variant="caption" display="block" fontWeight="regular" mb={2} style={{ color: '#67748e', listStyleType: 'disc' }}>Please follow this guide for a strong password.</SoftTypography>
                <SoftBox style={{ color: '#67748e' }} component="ul" fontSize={12} display="flex" flexDirection="column" p={0} m={0}>
                  {/* <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={0} style={{ color: '#67748e' }}>
                        A Minimum of:
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox> */}
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                        One lowercase character
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                        One uppercase character
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                        One number
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                        1 special character (ex. !@#$%)
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={1} style={{ color: '#67748e' }}>
                        8 characters minimum
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox display="flex" justifyContent="end" mt={2}>
              <SoftButton
                variant="text"
                color="dark"
                type="button"
                onClick={() => setOpen(false)}
                size="medium"
                style={{ color: "#333333", textTransform: "capitalize", marginTop: 5, fontWeight: 500 }}
                circular={true}>Cancel</SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                size="medium"
                type="submit"
                disabled={loading}
                style={{ textTransform: "capitalize", fontWeight: 500, marginLeft: 10 }}
                circular={true}>
                {loading && <CircularProgress size={14} color="info" />}
                {!loading && "Save Changes"}
              </SoftButton>
            </SoftBox>
          </Form>
        )}
      </Formik>
    </SoftBox>
  )
}

export default Password
