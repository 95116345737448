import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import stateInfo from "./enrollment/enrollmentReducer";
import productList from "./enrollment/enrollmentReducer";
import productValue from "./enrollment/enrollmentReducer";
import subscribeCancellationResponse from "./enrollment/enrollmentReducer";
import userInfo from "./users";
import documentList from "./wallet/documentReducer";
import documentTypeList from "./wallet/documentTypeReducer";
import requestedDocument from "./documents/requestedDocumentReducer";
import designatedContact from "./profile/profileReducer";
import QRCode from "./qrcode/qrcodeReducer";
import recordApproved from "./records/approved";
import requestInfo from "./records/request";
import usermanagement from "./user-management";

const allReducers = combineReducers({
  requestedDocument,
  loginReducer,
  stateInfo,
  productList,
  productValue,
  subscribeCancellationResponse,
  userInfo,
  usermanagement,
  documentList,
  documentTypeList,
  designatedContact,
  QRCode,
  recordApproved,
  requestInfo,
});

export const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return allReducers(state, action);
};
