import * as Yup from "yup";
import checkout from "./form"
import { phoneRegExp } from "utils/constant";
const {
  formField: { firstName, lastName, email, phone, address1, city, state, zip, gender },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [email.name]: Yup.string().required(email.errorMsg).matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, email.invalidMsg),
    [phone.name]: Yup.string().required(phone.errorMsg).matches(phoneRegExp, "Phone number is not valid."),
    [address1.name]: Yup.string().required(address1.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.object().required(state.errorMsg),
    [zip.name]: Yup.string()
      .length(5, "Zip Code is invalid.")
      .matches(/^[0-9]{5}/, "Zip Code is invalid")
      .required(zip.errorMsg)
    // [zip.name]: Yup.string().required(zip.errorMsg).matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Zip code is invalid.")
    // [zip.name]: Yup.string().required(zip.errorMsg).min(5, zip.invalidMsg).matches(/^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,38})?)(?:e[+]?\d+)?$/, "Zip code is invalid."),
    // [gender.name]: Yup.object(),
  }),
];

export default validations;
