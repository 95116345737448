
const form = {
  formId: "enrollment-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "Name",
      type: "text",
      placeholder: "First Name",
      errorMsg: "Field is required."
    },
    lastName: {
      name: "lastName",
      label: " ",
      type: "text",
      placeholder: "Last Name",
      errorMsg: "Field is required."
    },
    // company: {
    //   name: "company",
    //   label: "company",
    //   type: "text",
    //   placeholder: "eg. Creative Tim",
    // },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "Email Address",
      errorMsg: "Field is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "password",
      type: "password",
      placeholder: "Password",
      errorMsg: "Field is required.",
      invalidMsg: "Your password should be more than 8 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "confirm password",
      type: "password",
      placeholder: "Confirm Password",
      errorMsg: "Field is required.",
      invalidMsg: "Your password doesn't match.",
    },
    agree: {
      name: "agree",
      label: "agree",
      type: "checkbox",
      placeholder: "",
      errorMsg: "Field is required."
    },
    phone: {
      name: "phone",
      label: "phone number",
      type: "number",
      placeholder: "Phone Number",
      errorMsg: "Field is required.",
      invalidMsg: "Your phone is invalid.",
    },
    address1: {
      name: "address1",
      label: "address",
      type: "text",
      placeholder: "Street Address 1",
      errorMsg: "Field is required."
    },
    address2: {
      name: "address2",
      label: "",
      type: "text",
      placeholder: "Apartment, Suite, etc (Optional)",
    },
    city: {
      name: "city",
      label: "city",
      type: "text",
      placeholder: "City",
      errorMsg: "Field is required."
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "State",
      errorMsg: "Field is required."
    },
    zip: {
      name: "zip",
      label: "zip",
      type: "text",
      placeholder: "Zip Code",
      errorMsg: "Field is required.",
      invalidMsg: "Zip code should be at least 5 digits.",
    },
    // twitter: {
    //   name: "twitter",
    //   label: "twitter handle",
    //   type: "text",
    //   placeholder: "@soft",
    //   errorMsg: "Field is required."
    // },
    // facebook: {
    //   name: "facebook",
    //   label: "facebook account",
    //   type: "text",
    //   placeholder: "https://...",
    // },
    // instagram: {
    //   name: "instagram",
    //   label: "instagram account",
    //   type: "text",
    //   placeholder: "https://...",
    // },
    // publicEmail: {
    //   name: "publicEmail",
    //   label: "public email",
    //   type: "email",
    //   placeholder: "Use an address you don't use frequently",
    // },
    // bio: {
    //   name: "bio",
    //   label: "bio",
    //   placeholder: "Say a few words about who you are or what you're working on.",
    // },
  },
};

export default form;
