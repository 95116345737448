import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import Card from "@mui/material/Card";

//  components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import validations from "../components/schemas/validations";

import initialValues from "../components/schemas/initialValues";
import form from "../components/schemas/form";
import ForgotForm from "../components/Forgot";
import SoftButton from "components/SoftButton";
import checkIcon from "assets/images/checkIcon.svg";
import axios from "axios";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";

//  page layout routes

function ResetPassword() {
  const [timer, setTimer] = useState(7000);
  const [loading, setLoading] = useState(false);
  const [isDanger, setIsDanger] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const currentValidation = validations[0];
  const { formId, formField } = form;

  useEffect(() => {
    if (localStorage.getItem("authToken") !== null) navigate("/home");
  }, []);

  const submitForm = async (values) => {
    setLoading(true);
    changePassword(null, values.email);
  };

  function changePassword(isReset, email) {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}Users/ForgotPassword?emailId=${email}`)
      .then(() => {
        setEmail(email);
        setLoading(false);
        setOpen(false);
        setTimer(7000);
        if (isReset) {
          setIsDanger(false);
          setOpen(true);
        }
      })
      .catch(
        ({
          response: {
            data: { Message },
          },
        }) => {
          setOpen(true);
          setIsDanger(true);
          setError(Message);
          setLoading(false);
          setTimer(null);
        }
      );
  }

  function forgot(formData) {
    return <ForgotForm formData={formData} />;
  }

  function resent() {
    changePassword(true, email);
  }

  return (
    <SoftBox>
      <SoftBox style={{ minHeight: "calc(100vh - 50px)" }}>
        <BasicLayout>
          <AlertPop
            isDanger={isDanger ?? false}
            toggle={setOpen}
            isOpen={open}
            timer={timer}
            heading={isDanger ? "Error" : "Email Link Successfully Resent!"}
          >
            {isDanger
              ? error
              : `A link was sent to ${email}. Follow the instruction in the email to reset your password. `}
          </AlertPop>
          <SoftBox mt={7}>
            <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2)" }}>
              <SoftBox p={6}>
                <SoftBox mb={1}>
                  <Socials />
                </SoftBox>
                <SoftBox p={3} pb={2}>
                  {email !== "" && (
                    <SoftBox style={{ textAlign: "center" }} mt={2.5} mb={3}>
                      <SoftTypography variant="h5" fontWeight="bold" mb={0.5}>
                        Email Sent
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        fontWeight="regular"
                        fontSize={14}
                        display="block"
                      >
                        We sent an email to <strong>{email}</strong> with a link to reset your
                        password. {"Didn't"} receive the email?&nbsp;
                        <SoftTypography
                          variant="caption"
                          color="info"
                          fontWeight="regular"
                          fontSize={14}
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                          onClick={resent}
                        >
                          Click here to resend
                        </SoftTypography>
                      </SoftTypography>
                      <SoftBox display="flex" justifyContent="center" mt={4}>
                        <SoftBox px={1} component="img" src={checkIcon} />
                      </SoftBox>
                    </SoftBox>
                  )}
                  {email === "" && (
                    <>
                      <SoftBox style={{ textAlign: "center" }} mt={2.5} mb={3}>
                        <SoftTypography variant="h5" fontWeight="bold" mb={0.5}>
                          Forgot Password?
                        </SoftTypography>
                        <SoftTypography
                          style={{ textAlign: "justify" }}
                          variant="caption"
                          fontWeight="regular"
                          fontSize={14}
                          display="block"
                        >
                          No worries, {"we'll"} email a link and instructions to reset the password.
                        </SoftTypography>
                      </SoftBox>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={submitForm}
                      >
                        {({ values, errors, touched }) => (
                          <Form id={formId} autoComplete="off">
                            <SoftBox>
                              {forgot({ values, touched, formField, errors, submitForm })}

                              <SoftBox mt={4} mb={1}>
                                <SoftButton
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  type="submit"
                                  disabled={loading}
                                  circular={true}
                                  size="large"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                >
                                  {!loading && "Request link to reset password"}
                                  {loading && <CircularProgress size={18} color="white" />}
                                </SoftButton>
                              </SoftBox>
                            </SoftBox>
                          </Form>
                        )}
                      </Formik>
                    </>
                  )}
                </SoftBox>
                <SoftBox p={0} textAlign="center">
                  <Link to="/login">
                    <SoftTypography
                      variant="caption"
                      color="info"
                      fontWeight="regular"
                      fontSize={14}
                      style={{ textDecoration: "underline" }}
                    >
                      Return to Sign In
                    </SoftTypography>
                  </Link>
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
        </BasicLayout>
      </SoftBox>
      <Footer />
    </SoftBox>
  );
}

export default ResetPassword;
