import React from "react";
import ReusableDialog from "examples/ReusableDialog";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

const Member = ({ open, setOpen, openNewMemberHandler }) => {
  // ** States
  //   const [open, setOpen] = useState(false);
  const userDetails = localStorage.getItem("userDetails");
  const role = userDetails ? JSON.parse(userDetails).Role : null;

  return (
    <ReusableDialog
      maxWidth="sm"
      isOpen={open}
      title={
        <SoftBox display="flex">
          <SoftTypography textGradient={true} color="info">
            <span className="material-icons-outlined" style={{ fontSize: 25 }}>
              account_circle
            </span>
          </SoftTypography>
          <SoftTypography variant="h5" fontWeight="bold" color="dark" ml={1}>
            Create New User
          </SoftTypography>
        </SoftBox>
      }
      handleClose={() => setOpen(false)}
    >
      <SoftTypography variant="contains" fontWeight="normal" color="dark" mb={0.5} fontSize={15}>
        {role && role.toLowerCase() === "superadmin"
          ? "Please select whether to create a new Member or Admin:"
          : `Click "New Member" to continue:`}
      </SoftTypography>

      <SoftBox display="flex" justifyContent="end" mt={5}>
        <SoftButton
          variant="text"
          color="dark"
          type="button"
          onClick={() => setOpen(false)}
          size="medium"
          style={{ color: "#333333", textTransform: "initial", marginTop: 5, fontWeight: 500 }}
          circular={true}
        >
          Cancel
        </SoftButton>
        <SoftButton
          variant="gradient"
          color="info"
          size="medium"
          type="submit"
          onClick={() => openNewMemberHandler("PaidMember")}
          style={{ textTransform: "initial", fontWeight: 500, marginLeft: 10 }}
          circular={true}
        >
          New Member
        </SoftButton>
        {role && role.toLowerCase() === "superadmin" ? (
          <SoftButton
            variant="gradient"
            color="info"
            size="medium"
            type="submit"
            onClick={() => openNewMemberHandler("Administrator")}
            style={{ textTransform: "initial", fontWeight: 500, marginLeft: 10 }}
            circular={true}
          >
            New Admin
          </SoftButton>
        ) : null}
      </SoftBox>
    </ReusableDialog>
  );
};

export default Member;
