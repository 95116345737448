import SoftBox from 'components/SoftBox'
import Accordion from './Accordion';

const Account = (props) => {
  return (

    <SoftBox>
      <Accordion {...props} />
    </SoftBox>
  )
}

export default Account
