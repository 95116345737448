import { useEffect, useState } from "react";
import { Card, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";

import DataTable from "examples/Tables/DataTable";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import Approveselected from "../../Icons/Approveselected.svg";
import DenySelected from "../../Icons/Denyselected.svg";

import { DragDrop } from "../Uploads";
import ApproveDocument from "./ApproveDocument";
import DeniedDocument from "./DeniedDocument";

import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import { useMemo } from "react";
import ColumnHeader from "./ColumnHeader";
import StatusColumn from "./StatusColumn";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchRequestedDocumentsList } from "redux/actions/documents/requestedDocumentsAction";
import { fetchUserInformation } from "redux/actions/users";
import SoftSelect from "components/SoftSelect";
import search from "../../Icons/search.svg";

const filterList = [
  { value: null, label: "All Requests" },
  { value: "Pending", label: "Pending Requests" },
  { value: "Approved", label: "Approved Requests" },
  { value: "Denied", label: "Denied Requests" },
];

export default function MyDocument() {
  const [uploadOpen, setUploadOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);

  const [approveOpen, setApproveOpen] = useState(false);
  const [deniedOpen, setDeniedOpen] = useState(false);
  const [alert, setAlert] = useState({
    heading: "",
    text: "",
    isError: false,
  });
  const [isSelect, setIsSelect] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]); // State to store selected rows
  const [update, setUpdate] = useState(null);

  // Pagination settings
  const pageSize = 100;
  const currentPage = 1;
  const [total, setTotal] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0); // State to manage current page
  // const entriesPerPageOptions = [5, 10, 15, 20, 25]; // Options for entries per page
  // const defaultEntriesPerPage = 10; // Default entries per page

  // Handle page change
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const dispatch = useDispatch();
  const [tableList, setTableList] = useState([]);

  // useEffect(() => {
  //   if (!userInfo.data) fetchUserInfo();
  // }, [userInfo.data]);

  useEffect(() => {
    fetchRequestedDocuments({
      PageNumber: currentPage,
      PageSize: pageSize,
      FilterBy: filter,
    });
  }, [update]);

  const tableDataWithColumn = useMemo(
    () => ({
      columns: [
        {
          Header: () => <ColumnHeader heading="Requester Info" caption="(Name / License #)" />,
          accessor: "requesterInfo",
          width: "200px",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox>
                {original.Requestor_FullName && (
                  <>
                    <span>{original.Requestor_FullName}</span> <br />
                  </>
                )}
                <span>{original.Requestor_LicenseNumber}</span>
              </SoftBox>
            );
          },
        },
        {
          Header: () => <ColumnHeader heading="Requester Contact" caption="(Email / Phone #)" />,
          accessor: "requesterContact",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox>
                {original.Requestor_EmailAddress && (
                  <u>
                    <SoftTypography
                      noWrap
                      variant="contained"
                      component="a"
                      href={`mailto:${original.Requestor_EmailAddress}`}
                      style={{ width: 150, display: "block" }}
                      title={original.Requestor_EmailAddress}
                    >
                      {original.Requestor_EmailAddress}
                    </SoftTypography>
                  </u>
                )}
                <span>{original.Requestor_PhoneNumber}</span>
              </SoftBox>
            );
          },
        },
        {
          Header: "Facility Name",
          accessor: "FacilityName",
        },
        {
          Header: () => <ColumnHeader heading="Facility Info" caption="(URL / Address)" />,
          accessor: "facilityInfo",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox>
                {original.Requestor_WebsiteUrl && (
                  <u>
                    <SoftTypography
                      noWrap
                      variant="contained"
                      component="a"
                      href={original.Requestor_WebsiteUrl}
                      target="_blank"
                      style={{ width: 200, display: "block" }}
                      title={original.Requestor_WebsiteUrl}
                    >
                      {original.Requestor_WebsiteUrl}
                    </SoftTypography>
                  </u>
                )}
                {original.Requestor_Street1 && <span>{original.Requestor_Street1} </span>}
                {original.Requestor_Street2 && <span>{original.Requestor_Street2} </span>}
                {original.Requestor_City && <span>{original.Requestor_City} </span>}
                {original.Requestor_State && <span>{original.Requestor_State} </span>}
                {original.Requestor_Zipcode && <span>{original.Requestor_Zipcode}</span>}
              </SoftBox>
            );
          },
        },
        {
          Header: "Status",
          accessor: "Status",
          Cell: ({ row: { original } }) => <StatusColumn status={original.Status} />,
        },
        {
          Header: "Member",
          accessor: "MemberName",
        },
        {
          Header: "Requested On",
          accessor: "RequestedOn",
          width: "175px",
          Cell: ({ row: { original } }) => {
            if (original.RequestedOn) {
              return format(new Date(original.RequestedOn), "MMM dd, yyyy");
            }
          },
        },
        {
          Header: () => <span className="table-action">Action</span>,
          accessor: "Action",
          sortable: false,
          width: "100px",
          Cell: ({ row: { original } }) => {
            return (
              <SoftBox display="flex" alignItems="center">
                <Tooltip title="Approve" placement="top">
                  <SoftBox>
                    <IconButton
                      color="success"
                      sx={{ p: 0, mr: 1 }}
                      onClick={() => onSingleApprove(original)}
                      disabled={
                        (original &&
                          original.Status &&
                          original.Status.toLowerCase() === "approved") ||
                        (original && original.Status && original.Status.toLowerCase() === "deny") ||
                        (original && original.Status && original.Status.toLowerCase() === "denied")
                      }
                    >
                      <span
                        className="material-icons-outlined"
                        style={{ fontSize: 20, fontWeight: 700 }}
                      >
                        check
                      </span>
                    </IconButton>
                  </SoftBox>
                </Tooltip>
                <Tooltip title="Deny" placement="top">
                  <SoftBox>
                    <IconButton
                      color="error"
                      sx={{ p: 0 }}
                      onClick={() => onSingleDeny(original)}
                      disabled={
                        (original && original.Status && original.Status.toLowerCase() === "deny") ||
                        (original &&
                          original.Status &&
                          original.Status.toLowerCase() === "denied") ||
                        (original &&
                          original.Status &&
                          original.Status.toLowerCase() === "approved")
                      }
                    >
                      <span
                        className="material-icons-outlined"
                        style={{ fontSize: 20, fontWeight: 700 }}
                      >
                        close
                      </span>
                    </IconButton>
                  </SoftBox>
                </Tooltip>
              </SoftBox>
            );
          },
        },
      ],
      rows: tableList || [],
    }),
    [tableList]
  );

  // fetch document list
  async function fetchRequestedDocuments(payload) {
    setLoading(true);
    setTotal(0);
    const result = await dispatch(fetchRequestedDocumentsList(payload));
    const mappedData = result.Data.map((x) => ({
      ...x,
      disabled: x.Status && x.Status.toLowerCase() !== "pending",
    }));
    setTotal(mappedData.length);
    setTableList(mappedData);
    setLoading(false);
  }

  // Fetch UserInfo
  async function fetchUserInfo() {
    let email = localStorage.getItem("Email");
    return await dispatch(fetchUserInformation(email));
  }

  function onSelectEventHandler(params) {
    if (params && params.length) setIsSelect(true);
    else setIsSelect(false);
    setSelectedRow(params);
  }

  function onSingleApprove(data) {
    setSelectedRow(data);
    setApproveOpen(true);
  }

  function onSingleDeny(data) {
    setSelectedRow(data);
    setDeniedOpen(true);
  }

  function onFilterChange(data) {
    setFilter(data.value);
    fetchRequestedDocuments({
      PageNumber: currentPage,
      PageSize: pageSize,
      FilterBy: data.value,
    });
  }

  function approveDocument(document) {
    if (Object.keys(document).length) {
      setApproveOpen(true);
    }
  }

  function deniedDocument(document) {
    if (Object.keys(document).length) {
      setDeniedOpen(true);
    }
  }

  return (
    <SoftBox mt={4} sx={{ mb: 10 }}>
      {visible ? (
        <AlertPop
          isDanger={alert.isError}
          toggle={setVisible}
          timer={7000}
          isOpen={visible}
          heading={alert.heading}
        >
          {alert.text}
        </AlertPop>
      ) : null}
      <Card
        sx={{ height: "100%" }}
        style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)", overflow: "inherit" }}
      >
        <SoftBox p={3}>
          {!isSelect && (
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={5}>
              <Grid container>
                <Grid md={9} xs={12}>
                  <SoftTypography fontSize={20} fontWeight="bold" display="flex" alignItems="center">
                    Requests History &nbsp;
                    <SoftTypography variant="caption">[ {total} TOTAL ]</SoftTypography>
                  </SoftTypography>
                </Grid>
                <Grid md={3} xs={12}>
                  <SoftBox display="flex">
                    <SoftBox display="flex" alignItems="center">
                      <SoftTypography variant="caption" color="dark" style={{ whiteSpace: "nowrap" }}>
                        Filter by:&nbsp;&nbsp;
                      </SoftTypography>
                      <SoftSelect
                        defaultValue={{ value: null, label: "All Requests" }}
                        options={filterList}
                        onChange={onFilterChange}
                      />
                    </SoftBox>
                    {/* <SoftButton
                  variant="outlined"
                  color="info"
                  disabled
                  size="medium"
                  circular
                  sx={{ ml: 2 }}
                >
                  <SoftBox px={1} component="img" src={search} />
                  Search
                </SoftButton> */}
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          )}
          {isSelect && (
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={5}>
              <Grid container>
                <Grid md={8} xs={12}>
                  <SoftTypography fontSize={20} fontWeight="bold">
                    {selectedRow.length} row(s) selected
                  </SoftTypography>
                </Grid>
                <Grid md={4} xs={12}>
                  <SoftBox>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="medium"
                      circular
                      style={{ marginRight: 10, marginBottom:'5px' }}
                      onClick={() => deniedDocument(selectedRow[0])}
                    >
                      <SoftBox px={1} component="img" src={DenySelected} />
                      Deny Selected
                    </SoftButton>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="medium"
                      circular
                      style={{ marginRight: 10 }}
                      onClick={() => approveDocument(selectedRow[0])}
                    >
                      <SoftBox px={1} component="img" src={Approveselected} />
                      Approve Selected
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          )}
          {loading ? (
            <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
              <CircularProgress size={25} color="info" />
            </SoftBox>
          ) : (
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              // entriesPerPage={{ defaultValue: 10 }}
              isCheckbox={true}
              onRowSelectStateChange={onSelectEventHandler}
              selectAll={true}
              table={tableDataWithColumn}
            />
          )}
        </SoftBox>
        {/* {deleteOpen ? <DeleteDocument data={selectedRow.length ? selectedRow : [documentId]} open={deleteOpen} setOpen={setDeleteOpen} setAlert={setAlert} setVisible={setVisible} /> : null} */}
        {/* {approveOpen ? <Approverequest data={selectedRow.length ? selectedRow : [documentId]} open={approveOpen} setOpen={setApproveOpen} setAlert={setAlert} setVisible={setVisible} /> : null} */}
        {approveOpen ? (
          <ApproveDocument
            data={selectedRow}
            open={approveOpen}
            setOpen={setApproveOpen}
            setAlert={setAlert}
            setVisible={setVisible}
            setUpdate={setUpdate}
          />
        ) : null}
        {deniedOpen ? (
          <DeniedDocument
            data={selectedRow}
            open={deniedOpen}
            setOpen={setDeniedOpen}
            setAlert={setAlert}
            setVisible={setVisible}
            setUpdate={setUpdate}
          />
        ) : null}
        {uploadOpen ? (
          <DragDrop
            open={uploadOpen}
            setOpen={setUploadOpen}
            setAlert={setAlert}
            setVisible={setVisible}
          />
        ) : null}
        {/* {viewDocs ? <ViewDocument open={viewDocs} setOpen={setViewDocs} data={document} /> : null} */}
      </Card>
    </SoftBox>
  );
}
