import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Card, CircularProgress } from "@mui/material"
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography";
import { ContactForm } from "../Forms";
import initialValues from "../../schemas/initialValues";
import validations from "../../schemas/validations";
import form from "../../schemas/form"
import SoftButton from "components/SoftButton";
import { request } from "redux/actions/request";
import { fetchUserInformation } from "redux/actions/users";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import { formatPhoneNumber } from "utils/constant";

export const EmergencyContact = ({ formData }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState({
        heading: "",
        text: "",
        isError: false
    })
    const { activeStep, setActiveStep } = formData;
    const { userInfo: { data } } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const [isEmergency, setIsEmergency] = useState(false)
    const [userEmergencyInfo, setUserEmergencyInfo] = useState(null)

    useEffect(() => {
        if (data && data.EmergencyContact) {
            setIsEmergency(true)
            setUserEmergencyInfo(data.EmergencyContact)
        }
    }, [data])


    const handleSubmit = async (values, actions) => {
        setLoading(true)
        let payload = {
            UserId: data.Aspnet_UserId,
            Contact_LastName: values.lastName,
            Contact_FirstName: values.firstName,
            Contact_Email: values.email,
            Contact_Phone: values.phone.toString(),
            Contact_Street1: values.address1,
            Contact_Street2: values.address2,
            Contact_City: values.city,
            Contact_State: values.state.value,
            Contact_PostalCode: values.zip.toString(),
            Contact_Type: "EmergencyContact"
        }
        if (data.EmergencyContact) payload.ContactId = data.EmergencyContact.EmergencyContact_ID;
        request(
            data.EmergencyContact
                ? "User/UpdateUserContact"
                : "User/AddUserContact",
            data.EmergencyContact
                ? "PUT"
                : "POST", payload)
            .then(async () => {
                await dispatch(fetchUserInformation(data.EmailAddress))
                setAlert({
                    heading: "Changes Successfully Saved",
                    text: "Your personal information has been saved.",
                    isError: false
                })
                setLoading(false)
                setActiveStep(activeStep + 1)
            })
            .catch((error) => {
                setLoading(false)
                setAlert({
                    heading: "Error",
                    text: error,
                    isError: true
                })
                setOpen(true)
            })
    }
    const currentValidation = validations[0];
    const { formField } = form;

    return (
        <SoftBox>
            <AlertPop
                isDanger={alert.isError}
                toggle={setOpen}
                timer={7000}
                isOpen={open}
                heading={alert.heading}>
                {alert.text}
            </AlertPop>
            <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, setFieldValue, setErrors }) => (
                    <Form id="emergencyInfo" autoComplete="off">
                        <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                            <SoftBox p={4}>
                                <SoftBox lineHeight={0.8}>
                                    <SoftTypography variant="h5" fontWeight="bold" color="info" textGradient={true}>
                                        Emergency Contact Info
                                    </SoftTypography>
                                    <SoftBox mt={0.5}>
                                        <SoftTypography variant="caption" fontWeight="light" fontSize={14}>
                                            Please provide accurate information as it will be printed on your card.
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                                {!isEmergency ? <SoftBox mt={4} mb={1} textAlign="left">
                                    <SoftTypography variant="caption" fontWeight="regular" fontStyle="italic" fontSize={11} display="block"
                                        style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                                        (All fields Required unless specified as Optional)
                                    </SoftTypography>
                                </SoftBox> : null}
                                {isEmergency ? <SoftBox>
                                    <SoftBox mt={2} display="flex" justifyContent="end">
                                        <SoftButton
                                            variant="outlined"
                                            color="dark"
                                            type="button"
                                            onClick={() => setIsEmergency(false)}
                                            size="small"
                                            style={{
                                                color: "#333333", textTransform: "capitalize",
                                                borderColor: "#AAAAAA", fontWeight: 500
                                            }}
                                            circular={true}>Edit Information</SoftButton>
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Name
                                        </SoftTypography>
                                        {userEmergencyInfo ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            <span style={{ textTransform: 'capitalize' }}>{userEmergencyInfo.EmergencyContact_FirstName}</span> <span style={{ textTransform: 'capitalize' }}>{userEmergencyInfo.EmergencyContact_LastName}</span>
                                        </SoftTypography> : null}
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Phone Number
                                        </SoftTypography>
                                        {userEmergencyInfo ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            {userEmergencyInfo.EmergencyContact_Phone}
                                        </SoftTypography> : null}
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Email Address
                                        </SoftTypography>
                                        {userEmergencyInfo ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            {userEmergencyInfo.EmergencyContact_Email}
                                        </SoftTypography> : null}
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Address
                                        </SoftTypography>
                                        {userEmergencyInfo ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            {userEmergencyInfo?.EmergencyContact_Street1} {" "}
                                            {userEmergencyInfo?.EmergencyContact_Street2} {" "}
                                            {userEmergencyInfo?.EmergencyContact_City}, {" "}
                                            {userEmergencyInfo?.EmergencyContact_State} {" "}
                                            {userEmergencyInfo?.EmergencyContact_PostalCode}
                                        </SoftTypography> : null}
                                    </SoftBox>
                                </SoftBox> :
                                    <ContactForm formData={{ formData, values, errors, touched, setFieldValue, setErrors, formField }} />
                                }
                                <SoftBox mt={2} width="100%" display="flex" justifyContent="end">
                                    {!isEmergency ? <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        circular={true}
                                        disabled={loading}
                                        style={{ textTransform: 'capitalize', fontWeight: 500 }}
                                    >
                                        {loading && <CircularProgress size={14} color="white" />}
                                        {!loading && "Continue to Generate ID"}
                                    </SoftButton> : <SoftButton
                                        type="button"
                                        variant="gradient"
                                        color="info"
                                        circular={true}
                                        style={{ textTransform: 'capitalize', fontWeight: 500 }}
                                        onClick={() => setActiveStep(activeStep + 1)}
                                    >
                                        Continue to Generate ID
                                    </SoftButton>}
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    </Form>
                )}
            </Formik>
        </SoftBox>
    )
}
