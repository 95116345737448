// prop-type is a library for typechecking of props
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card, Grid } from "@mui/material";
import { useSelector } from "react-redux";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { MediCard } from "./MediCard";
import SoftButton from "components/SoftButton";
import ReusableDialog from "examples/ReusableDialog";
import { CardPrint } from "./CardPrint";

function GenerateCard({ formData }) {
  const componentToPrint = useRef(null);
  const { activeStep, setActiveStep } = formData;
  const [open, setOpen] = useState(false);
  const {
    userInfo: { data },
  } = useSelector((state) => state);

  return (
    <Grid container justifyContent="center" sx={{ height: "100%" }}>
      <Grid item xs={12} lg={5}>
        <SoftBox mb={3}>
          <Card
            sx={{ height: "100%" }}
            style={{
              boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)",
              overflow: "inherit",
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            <SoftBox p={4}>
              <SoftBox lineHeight={0} mb={5}>
                <SoftTypography variant="h5" fontWeight="bold" color="info" textGradient={true}>
                  Generate ID Card
                </SoftTypography>
                <SoftBox mb={2.5} mt={0.5}>
                  <SoftTypography variant="caption" fontWeight="light" fontSize={14}>
                    Please print or save your ID card. Keep in your wallet in case of an emergency.
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <MediCard />
              <SoftBox width="100%" display="flex" justifyContent="end">
                <SoftButton
                  variant="text"
                  circular={true}
                  color="dark"
                  onClick={() => setActiveStep(activeStep - 1)}
                  style={{
                    marginRight: 10,
                    padding: 13,
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                >
                  back
                </SoftButton>
                <SoftButton
                  type="button"
                  variant="gradient"
                  color="info"
                  circular={true}
                  style={{ textTransform: "capitalize", fontWeight: 500 }}
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  Continue to Upload Documents
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
      </Grid>
      <ReusableDialog maxWidth="md" isOpen={open} handleClose={() => setOpen(false)}>
        <CardPrint componentToPrint={componentToPrint} data={data} isZoom={true} />
      </ReusableDialog>
    </Grid>
  );
}

// typechecking props for Address
GenerateCard.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default GenerateCard;
