import { useState } from "react";
import { Form, Formik } from "formik";
import { CircularProgress } from "@mui/material";
import { request } from "redux/actions/request";

import SoftButton from "components/SoftButton";
import ReusableDialog from "examples/ReusableDialog";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import CreateAccount from "layouts/authentication/enrollment/components/CreateAccount";
import PersonalInfo from "layouts/authentication/enrollment/components/PersonalInfo";
import PaymentInfo from "layouts/authentication/enrollment/components/PaymentInfo";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";

import initialValues from "layouts/authentication/enrollment/schemas/initialValues";
import validations from "layouts/authentication/enrollment/schemas/validations";
import form from "layouts/authentication/enrollment/schemas/form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CreateNewUser = ({ open, setOpen, submitHandlerEvent, setUpdate, role }) => {
  // ** States
  const [visible, setVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [alert, setAlert] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [agree, setAgree] = useState(false);
  const currentValidation = validations[activeStep];
  const { formId, formField } = form;
  const updatedInitialValue = { ...initialValues, agree: true };

  const userDetails = localStorage.getItem("userDetails");
  const UserRole = userDetails ? JSON.parse(userDetails).Role : null;
  const productSelectValue = useSelector(state => state.productValue.productValue);


  function handleBack() {
    if (activeStep === 0) setOpen(false);
    else setActiveStep((prev) => prev - 1);
  }

  const handleSubmit = async (values, actions) => {
    if (
      (UserRole &&
        UserRole.toLowerCase() === "superadmin" &&
        role &&
        role.toLowerCase() === "administrator" &&
        activeStep === 1) ||
      activeStep === 1 // activeStep change 2 to 1 because user only need to fill the payment details 
    ) {
      const payload = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Gender: null,
        SMSCapableNumber: values.phone.toString(),
        Street1: values.address1,
        Street2: values.address2,
        City: values.city,
        State: values.state.value,
        PostalCode: values.zip.toString(),
        EmailAddress: values.email,
        Password: values.password,
        RoleName: role ?? "PaidMember", // "Administrator"
        IsAdmin: true,
        IsActive:false,
      };
      submitForm(payload, actions);
    } else {
      setActiveStep((prev) => prev + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const submitForm = async (values, actions) => {
    setIsSubmit(true);
    request("Users/PostExternalUser", "POST", values)
      .then(async (result) => {
        setIsSubmit(false);
        // setUpdate((prev) => !prev);
        // const url = `${productSelectValue.PaymentLink}?client_reference_id=${result?.Data?.Aspnet_Id}`;
        // window.location.replace(url);
        submitHandlerEvent();
      })
      .catch((error) => {
        setIsSubmit(false);
        setVisible(true);
        setAlert(error);
      });
  };

  return (
    <>
      {visible ? (
        <AlertPop
          isDanger={true}
          toggle={setVisible}
          timer={7000}
          isOpen={visible}
          heading={"Error"}
        >
          {alert}
        </AlertPop>
      ) : null}
      <ReusableDialog
        maxWidth="sm"
        isOpen={open}
        title={
          <SoftBox display="flex">
            <SoftTypography textGradient={true} color="info">
              <span className="material-icons-outlined" style={{ fontSize: 25 }}>
                person_add_alt
              </span>
            </SoftTypography>
            <SoftTypography
              variant="h5"
              fontWeight="bold"
              ml={1}
              style={{ backgroundImage: "none" }}
            >
              Create New User&nbsp;&nbsp; | &nbsp;
            </SoftTypography>
            <SoftTypography variant="h5" fontWeight="normal">
              Step {activeStep + 1} of{" "}
              {UserRole.toLowerCase() === "superadmin" &&
              role &&
              role.toLowerCase() === "administrator"
                ? 2
                : 3}
            </SoftTypography>
          </SoftBox>
        }
        handleClose={() => setOpen(false)}
      >
        <Formik
          initialValues={updatedInitialValue}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form id={formId} autoComplete="off">
              {activeStep === 0 && (
                <CreateAccount
                  formData={{ values, touched, formField, setFieldValue, errors, agree, setAgree }}
                  isHide={true}
                  role={role}
                  userRole={UserRole}
                />
              )}
              {activeStep === 1 && (
                <PersonalInfo
                  formData={{ values, touched, formField, setFieldValue, errors, agree, setAgree }}
                  isHide={true}
                  role={role}
                  userRole={UserRole}
                />
              )}
              {/* {activeStep === 2 && (
                // <PaymentInfo
                //   formData={{ values, touched, formField, setFieldValue, errors, agree, setAgree }}
                //   isHide={true}
                // />
              )} */}
              <SoftBox mt={2} width="100%" display="flex" justifyContent="end">
                <SoftButton
                  variant="text"
                  circular={true}
                  color="text"
                  onClick={handleBack}
                  style={{
                    marginRight: 10,
                    padding: 13,
                    textTransform: "capitalize",
                    fontWeight: 500,
                  }}
                >
                  Back
                </SoftButton>
                <SoftButton
                  // disabled={Object.keys(errors).length}
                  type="submit"
                  variant="gradient"
                  color="info"
                  circular={true}
                  style={{ textTransform: "capitalize", fontWeight: 500, minWidth: 200 }}
                >
                  {isSubmit ? (
                    <CircularProgress size={18} color="white" />
                  ) : UserRole &&
                    UserRole.toLowerCase() === "superadmin" &&
                    role &&
                    role.toLowerCase() === "administrator" &&
                    activeStep === 1 ? (
                    "Save & Complete"
                  ) : activeStep === 1 ? (
                    "Complete Enrollment"
                  ) : ('Save & Complete')}
                </SoftButton>
              </SoftBox>
            </Form>
          )}
        </Formik>
      </ReusableDialog>
    </>
  );
};

export default CreateNewUser;
