import SoftTypography from 'components/SoftTypography'
import React from 'react'

export const Personal = ({ data }) => {
    // console.log(data)
    return (
        <SoftTypography display="block"
            variant="caption"
            fontWeight="light"
            fontSize={14}
            lineHeight={2.5}
        >
            <strong>Name:</strong>&nbsp;{data?.FirstName} {data?.LastName}<br />
            <strong>Phone Number:</strong>&nbsp;{data ? data?.SMSCapableNumber : null}<br />
            <strong>Email Address:</strong>&nbsp;{data?.EmailAddress}<br />
            <strong>Address:</strong>&nbsp;{data?.Street1} {data?.Street2} {data?.City}, {data?.State} {data?.PostalCode}
        </SoftTypography>
    )
}
