import { useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";
import { request } from "redux/actions/request";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";

export default function DeleteDocument(props) {
  const { data, open, setOpen, setAlert, setVisible, setUpdate } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const role = data?.RoleName;

  // share document
  function deleteUserHandler() {
    setLoading(true);

    let payload = {};
    if (Array.isArray(data)) payload = data.map((x) => ({ UserName: x.EmailAddress }));
    else payload = [{ UserName: data.EmailAddress }];

    request("AdminUserManagement", "Delete", payload)
      .then(async () => {
        setAlert({
          heading: "User Successfully Deleted.",
          text: "user has been successfully deleted.",
          isError: false,
          timer: 7000,
        });
        setVisible(true);
        setLoading(false);
        setOpen(false);
        setUpdate((prev) => !prev);
      })
      .catch((err) => {
        setAlert({
          heading: "Error",
          text: err,
          isError: true,
        });
        setVisible(true);
        setOpen(false);
      });
  }

  // fetch document list
  async function fetchDocuments(count) {
    return await dispatch(fetchDocumentList(count));
  }

  return (
    <ReusableDialog isOpen={open} title="Delete User(s)" handleClose={() => setOpen(false)}>
      <SoftBox>
        <SoftTypography color="dark" caption="text" fontSize={14} paragraph={true}>
          {data && data.length ? (
            <>Are you sure you want to delete member/admin? You can’t undo this action.</>
          ) : (
            <>
              Are you sure you want to delete{" "}
              {role && role.toLowerCase() === "superadmin"
                ? "superadmin"
                : role && role.toLowerCase() === "administrator"
                ? "admin"
                : "member"}{" "}
              {`"${data?.FirstName} ${data?.LastName}"`}? You can’t undo this action.
            </>
          )}
        </SoftTypography>
        <SoftBox display="flex" justifyContent="end" mt={5}>
          <SoftButton
            variant="text"
            color="dark"
            type="button"
            onClick={() => setOpen(false)}
            size="medium"
            style={{ color: "#333333", textTransform: "initial", marginTop: 5, fontWeight: 500 }}
            circular={true}
          >
            Cancel
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            size="medium"
            type="submit"
            onClick={deleteUserHandler}
            disabled={loading}
            style={{ textTransform: "initial", fontWeight: 500, marginLeft: 10 }}
            circular={true}
          >
            {loading && <CircularProgress size={14} color="info" />}
            {!loading && Array.isArray(data) ? "Delete Member/Admin" : "Delete Member(s)"}
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </ReusableDialog>
  );
}
