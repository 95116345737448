

// @mui material components
// import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
// import typography from "assets/theme/base/typography";
// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import PinterestIcon from "@mui/icons-material/Pinterest";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

//  components
import { useState } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import ReusableDialog from "examples/ReusableDialog";
import { CircularProgress } from "@mui/material";
// style={{ backgroundColor: '#344767' }}
function Footer() {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true)
  const [termsPolicy, setTermsPolicy] = useState({
    terms: false,
    policy: false
  })

  function handleAgreement(e, name) {
    e.preventDefault()
    setLoading(true);
    if (name === "terms") {
      setTermsPolicy({ terms: true, policy: false })
      fetch('/terms-of-use.html')
        .then(response => response.text())
        .then(data => {
          setContent(data);
          setLoading(false);
        });
    }
    else {
      setTermsPolicy({ terms: false, policy: true })
      fetch('/privacy-policy.html')
        .then(response => response.text())
        .then(data => {
          setContent(data);
          setLoading(false);
        });
    }
  }

  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      style={{ backgroundColor: '#0C2340', position: 'static', bottom: 0, zIndex: 99 }}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        fontSize={16}
        fontWeight="light"
        px={1.5}
        py={2}
        style={{ color: '#3F91C3' }}
      >
        &copy; MediDocPro. All rights reserved.
      </SoftBox>
      <SoftBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 1.5,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <SoftBox component="li" px={2} lineHeight={1}>
          <Link to="/" onClick={(e) => handleAgreement(e, "policy")}>
            <SoftTypography style={{ textDecoration: 'underline', color: '#3F91C3' }} fontSize={16} variant="caption" fontWeight="light" color="info">
              Privacy Policy
            </SoftTypography>
          </Link>
        </SoftBox>
        <SoftBox component="li" px={2} lineHeight={1}>
          <Link to="/" onClick={(e) => handleAgreement(e, "terms")}>
            <SoftTypography style={{ textDecoration: 'underline', color: '#3F91C3' }} fontSize={16} variant="caption" fontWeight="light" color="info">
              Terms of Use
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>
      {termsPolicy.policy || termsPolicy.terms ? <ReusableDialog
        maxWidth="md"
        isOpen={termsPolicy.policy || termsPolicy.terms}
        handleClose={() => setTermsPolicy({ terms: false, policy: false })}
      >
        <SoftBox>
          {loading ? <SoftBox p={2} textAlign="center"><CircularProgress size={25} color="primary" thickness={5} /></SoftBox> : <div dangerouslySetInnerHTML={{ __html: content }} />}
        </SoftBox>
      </ReusableDialog> : null}
    </SoftBox>
  );
}

// Setting default values for the props of Footer
// Footer.defaultProps = {
//   links: [
//     { href: "/login", name: "Terms of Use" },
//     { href: "/login", name: "Privacy Policy" }
//   ],
// };

// Typechecking props for the Footer
Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
