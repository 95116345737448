
import checkout from "./form";

const {
  formField: {
    firstName,
    lastName,
    email,
    state
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [state.name]: ""
};

export default initialValues;
