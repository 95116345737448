import { StateList } from 'layouts/authentication/enrollment/components/PersonalInfo/StateList'
import React, { useEffect } from 'react'
import { DesignatedContact } from '../Uploads/DesignatedContact'

export default function DesignatedContactForm({ formData }) {
    const { setFieldValue, data, setErrors } = formData

    useEffect(() => {
        let state = StateList.filter(x => x.value === data.Contact_State)
        setFieldValue("firstName", data.Contact_FirstName)
        setFieldValue("lastName", data.Contact_LastName)
        setFieldValue("email", data.Contact_Email)
        setFieldValue("phone", data.Contact_Phone)
        setFieldValue("address1", data.Contact_Street1)
        setFieldValue("address2", data.Contact_Street2)
        setFieldValue("city", data.Contact_City)
        setFieldValue("state", state.length ? { label: state[0].label, value: state[0].value } : null)
        setFieldValue("zip", data.Contact_PostalCode)
        setTimeout(() => setErrors({}), 0)
    }, [])

    return (
        <div>
            <DesignatedContact formData={formData} isEdit={true} isStates={true} />
        </div>
    )
}
