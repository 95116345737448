import React from 'react'
import { Card, Grid } from "@mui/material"
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import walletlogo from '../wallet/Icons/walletlogo.svg'

export const Welcome = ({ data }) => {
    return (
        <SoftBox>
            <Grid container item>
                <Grid item xs={12}>
                    <Card style={{ background: '#f4fcff', boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                        <SoftBox px={4} py={3}>
                            <Grid container>
                                <Grid item lg={9} xs={12}>
                                    <SoftTypography mt={1.5} variant="h4" fontWeight="bold" fontSize={22}>Welcome to your MediDoc wallet, {data ? <span style={{ textTransform: 'capitalize' }}>{data?.FirstName}!</span> : null}</SoftTypography>
                                    <SoftTypography
                                        mt={1}
                                        mb={1.5}
                                        fontWeight="regular"
                                        fontSize={15}>
                                        It’s your one-stop-shop for managing healthcare docs with ease. You’ll want to ensure that it’s all up-to-date.
                                    </SoftTypography>
                                </Grid>
                                <Grid item lg={3} xs={12} style={{  bottom: 0, right: 30 }}>
                                    <SoftBox px={1} component="img" src={walletlogo} />
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}
