import { useState } from "react";
import { Grid } from "@mui/material"
// import { useSelector } from "react-redux";
import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography";
import { StateList } from "../CreateInfo/StateList";
import FormField from "../FormField"

// import preFill from "./Icons/pre-filled.svg"

export const DesignatedContact = ({ formData }) => {
    const { formField, values, errors, touched, setFieldValue } = formData
    const { firstName, lastName, email, phone, address1, address2, city, state, zip } = formField
    const { firstName: firstNameV, lastName: lastNameV, email: emailV, address1: address1V, address2: address2V, city: cityV, state: stateV, zip: zipV, phone: phoneV } = values
    const [selectedStatus, setSelectedStatus] = useState(null)
    // const { userInfo: { data } } = useSelector(state => state)

    const statusOptions = StateList

    async function onChangeStatus(value) {
        setSelectedStatus(value)
        setFieldValue("state", value)
    }

    // function preFilledEmergencyContact() {
    //     if (data && data.EmergencyContact) {
    //         let state = StateList.filter(x => x.value === data.EmergencyContact.EmergencyContact_State)
    //         setFieldValue("firstName", data.EmergencyContact.EmergencyContact_FirstName)
    //         setFieldValue("lastName", data.EmergencyContact.EmergencyContact_LastName)
    //         setFieldValue("email", data.EmergencyContact.EmergencyContact_Email)
    //         setFieldValue("phone", data.EmergencyContact.EmergencyContact_Phone)
    //         setFieldValue("address1", data.EmergencyContact.EmergencyContact_Street1)
    //         setFieldValue("address2", data.EmergencyContact.EmergencyContact_Street2)
    //         setFieldValue("city", data.EmergencyContact.EmergencyContact_City)
    //         setFieldValue("state", state.length ? { label: state[0].label, value: state[0].value } : null)
    //         setFieldValue("zip", data.EmergencyContact.EmergencyContact_PostalCode)
    //         setSelectedStatus(state.length ? { label: state[0].label, value: state[0].value } : null)
    //         setTimeout(() => setErrors({}), 0)
    //         setIsPrefilled(true)
    //     }
    // }

    return (
        <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mt={3} mb={3}>
                <SoftTypography variant="h6" fontWeight="bold">Designated Contact</SoftTypography>
                {/* <SoftBox display="flex" alignItems="center">
                    <SoftBox component="img" src={preFill}></SoftBox>
                    <SoftTypography
                        variant="caption"
                        fontWeight="medium"
                        textGradient={true}
                        color="info"
                        fontSize={12}
                        ml={0.5}
                        onClick={preFilledEmergencyContact}
                        style={{ cursor: 'pointer' }}>
                        Prefill from Emergency Contact
                    </SoftTypography>
                </SoftBox> */}
            </SoftBox>
            <SoftTypography variant="caption" fontWeight="light" fontStyle="italic" display="block" fontSize={10.5} mb={2}>
                (All fields Required unless specified as Optional)
            </SoftTypography>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={firstName.type}
                        label={firstName.label}
                        name={firstName.name}
                        value={firstNameV}
                        placeholder="Select or Enter First Name"
                        error={errors.firstName && touched.firstName}
                        success={firstNameV.length > 0 && !errors.firstName}
                    />
                    {/* <FormField
                        fieldType="select"
                        type={state.type}
                        label={""}
                        name={state.name}
                        options={statusOptions}
                        defaultValue={selectedStatus}
                        value={selectedStatus}
                        onChange={onChangeStatus}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder={state.placeholder}
                        error={errors.state && touched.state}
                        success={stateV.length > 0 && !errors.state}
                    /> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={lastName.type}
                        label={lastName.label}
                        name={lastName.name}
                        value={lastNameV}
                        placeholder={lastName.placeholder}
                        error={errors.lastName && touched.lastName}
                        success={lastNameV.length > 0 && !errors.lastName}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FormField
                        fieldType="phone"
                        helper="US Number Only"
                        id={phone.name}
                        type={phone.type}
                        name={phone.name}
                        setFieldValue={setFieldValue}
                        label={phone.label}
                        placeholder={phone.placeholder}
                        error={errors.phone && touched.phone}
                        success={values.phone.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        placeholder={email.placeholder}
                        error={errors.email && touched.email}
                        success={emailV.length > 0 && !errors.email}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormField
                        type={address1.type}
                        label={address1.label}
                        name={address1.name}
                        value={address1V}
                        placeholder={address1.placeholder}
                        error={errors.address1 && touched.address1}
                        success={address1V.length > 0 && !errors.address1}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SoftBox>
                        <FormField
                            type={address2.type}
                            name={address2.name}
                            value={address2V}
                            label=""
                            placeholder={address2.placeholder}
                        />
                    </SoftBox>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={city.type}
                        name={city.name}
                        value={cityV}
                        label=""
                        placeholder={city.placeholder}
                        error={errors.city && touched.city}
                        success={cityV.length > 0 && !errors.city}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormField
                        fieldType="select"
                        type={state.type}
                        label={""}
                        name={state.name}
                        options={statusOptions}
                        defaultValue={selectedStatus}
                        value={selectedStatus}
                        onChange={onChangeStatus}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder={state.placeholder}
                        error={errors.state && touched.state}
                        success={stateV.length > 0 && !errors.state}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormField
                        type={zip.type}
                        name={zip.name}
                        label=""
                        value={zipV}
                        placeholder={zip.placeholder}
                        error={errors.zip && touched.zip}
                        success={zipV.length > 0 && !errors.zip}
                    />
                </Grid>
            </Grid>
        </SoftBox>
    )
}
