

//  components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import brand from "assets/images/brand-logo.svg"
function Socials() {
  return (
    <SoftBox display="flex" justifyContent="center">
       <SoftBox px={1} component="img" src={brand}/>
    </SoftBox>
  );
}

export default Socials;
