
const form = {
  formId: "enrollment-form",
  formField: {
    fullName: {
      name: "fullName",
      label: "Requestor's First Name",
      type: "text",
      placeholder: "Enter First Name",
      errorMsg: "Firstname is required.",
    },
    lastName: {
      name: "lastName",
      label: "Requestor's Last Name",
      type: "text",
      placeholder: "Enter Last Name",
      errorMsg: "Lastname is required.",
    },
    licenseNumber: {
      name: "licenseNumber",
      label: "NPI Number",
      type: "text",
      placeholder: "Enter Medical NPI Number",
      errorMsg: "NPI number is required.",
    },
    email: {
      name: "email",
      label: "email address",
      type: "email",
      placeholder: "Email Address",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    facilityName: {
      name: "facilityName",
      label: "Facility Name",
      type: "text",
      placeholder: "Facility Name",
      errorMsg: "Facility Name is required.",
      invalidMsg: "Your Facility Name is invalid",
    },
    phone: {
      name: "phone",
      label: "phone number",
      type: "number",
      placeholder: "(###) ###-####",
      errorMsg: "Phone number is required.",
      invalidMsg: "Your phone number is invalid.",
    },
    address1: {
      name: "address1",
      label: "Facility Address",
      type: "text",
      placeholder: "Street Address",
      errorMsg: "Address is required.",
    },
    company: {
      name: "company",
      label: "Company's Website",
      type: "text",
      placeholder: "Enter Url",
      errorMsg: "Company's link is required.",
    },
    address2: {
      name: "address2",
      label: "",
      type: "text",
      placeholder: "Apartment, Suite, etc (Optional)",
    },
    city: {
      name: "city",
      label: "city",
      type: "text",
      placeholder: "City",
      errorMsg: "City is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      placeholder: "State",
      errorMsg: "State is required.",
    },
    zip: {
      name: "zip",
      label: "zip",
      type: "text",
      placeholder: "Zip Code",
      errorMsg: "Zip code is required.",
      invalidMsg: "Zip code should be at least 5 digits.",
    },
  },
};

export default form;
