import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid, Tooltip } from "@mui/material"
import { Form, Formik } from 'formik';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SoftBox from "components/SoftBox"
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ReusableDialog from "examples/ReusableDialog";
import { request } from "redux/actions/request";
import deleteIcon from "../../Icons/delete.svg";
import warningIcon from "../../Icons/warning.svg";
import downloadIcon from "../../Icons/download.svg";
import leftArrow from "../../Icons/left-arrow.svg";
import rightArrow from "../../Icons/right-arrow.svg";
import { formatPhoneNumber, bytesToMegaBytes, fileNameWithExtension } from "utils/constant";
import SoftSelect from "components/SoftSelect";
import DesignatedContactForm from "./DesignatedContactForm";
import initialValues from '../schemas/initialValues';
import validations from '../schemas/validations';
import form from '../schemas/form';
import DeleteDocument from "./DeleteDocument";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";
import PDFViewer from "./PDFViewer";

export const ViewDocument = ({ open, setOpen, data }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector(state => state)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [isStatusEdit, setIsStatusEdit] = useState(false)
    const [isInfoEdit, setIsInfoEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isShareLoading, setIsShareLoading] = useState(false)
    const [shareStatus, setShareStatus] = useState(null)
    const [file, setFile] = useState(null)
    const [fileBase64, setFileBase64] = useState(null)
    const [fileDocument, setFileDocument] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isDifferentFormate, setIsDifferentFormate] = useState(false)
    const [isPDF, setIsPDF] = useState(false);
    const [alert, setAlert] = useState({
        heading: "",
        text: "",
        isError: false
    })
    const [docTitle, setDocTitle] = useState(null)
    
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const currentValidation = validations[0];
    const { formField } = form;

    useEffect(() => {
        viewDocumentHandler(data)
    }, [data])

    useEffect(() => {
        if (fileDocument) {
            fetchContentPreview()
        }
    }, [fileDocument])

    // fetch documents for view
    async function viewDocumentHandler(data) {
        let payload = [data.id]
        request("Document/ViewDocuments", "POST", payload)
            .then((result) => {
                fetchDocuments(100)
                setFileDocument(result.Data)
                setIsStatusEdit(false)
                setLoading(false)
                setIsShareLoading(false)
                setIsInfoEdit(false)
                if(result.Data.length && result.Data[0]?.Document_Type?.toLowerCase() === 'other') {
                    setDocTitle("Other - "+result.Data[0].DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName)
                } else {
                    setDocTitle(result.Data[0]?.Document_Type)
                }
            })
            .catch((error) => console.log(error))
    }

    // Designated Submit Form Handler
    const handleSubmit = async (values, actions) => {
        setLoading(true)
        let payload = {
            UserId: userInfo.data.Aspnet_UserId,
            ContactId: fileDocument[0]?.UserDocumentContact.ContactId,
            Contact_LastName: values.lastName,
            Contact_FirstName: values.firstName,
            Contact_Email: values.email,
            Contact_Phone: values.phone.toString(),
            Contact_Street1: values.address1,
            Contact_Street2: values.address2,
            Contact_City: values.city,
            Contact_State: values.state.value,
            Contact_PostalCode: values.zip.toString(),
            Contact_Type: "DocumentContact"
        }
        request("User/UpdateUserContact", "PUT", payload)
            .then(async () => {
                viewDocumentHandler(data)
                setAlert({
                    heading: "Changes Successfully Saved!",
                    text: "Your designated contact information has been saved.",
                    isError: false,
                    timer: 7000
                })
                setVisible(true)
            })
            .catch((err) => {
                setAlert({
                    heading: "Error!",
                    text: err,
                    isError: true
                })
                setVisible(true)
            })
    }

    // Update Share Status
    function updateShareStatus() {
        let payload;
        setIsShareLoading(true)
        if (shareStatus.value === "True") {
            payload = [{
                Sharable: "True",
                Document_Id: data.id
            }]
        } else {
            payload = [{
                Sharable: "False",
                Document_Id: data.id
            }]
        }
        request("Document/Documents", "PUT", payload)
            .then(() => {
                viewDocumentHandler(data)
                setAlert({
                    heading: "Changes Successfully Saved!",
                    text: "Share status has been saved.",
                    isError: false,
                    timer: 7000
                })
                setVisible(true)
            })
            .catch((err) => {
                setAlert({
                    heading: "Error!",
                    text: err,
                    isError: true
                })
                setVisible(true)
            })
    }

    // Change Share Status
    function changeShareStatus(status) {
        setShareStatus({
            label: status === "True" && status !== "" ? "Shared" : "Unshared",
            value: status === "True" && status !== "" ? "True" : "False"
        })
        setIsStatusEdit(true)
    }

    // Fetch The Document List
    async function fetchDocuments(count) {
        return await dispatch(fetchDocumentList(count))
    }

    // Fetch The Content to preview
    async function fetchContentPreview() {
        let payload = {
            DocumentFolder: userInfo.data.UserId,
            DocumentFiles: [{ DocumentFilePath: userInfo.data.UserId + "/" + data.fileName }]
        }
        request("AzureBlob/ViewFileContent", "POST", payload)
            .then(({ Data }) => {
                let fileName = fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileName;
                if (fileName.includes("docx") || fileName.includes("xlsx") || fileName.includes("xls") || fileName.includes("doc")) setIsDifferentFormate(true)
                else {
                    setIsDifferentFormate(false)
                    if(fileName.includes(".pdf")) setIsPDF(true)
                }
                setFileBase64(Data?.Data)

                let objbuilder = "";
                // objbuilder += (`<h2>${fileName}</h2>`);
                objbuilder += (`<object width="100%" height="100%" data="${fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileExtension},`);
                objbuilder += (Data?.Data);
                objbuilder += (`" type="${fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileExtension.split(":").pop().split(";")[0]}" class="internal"> title="${fileName}">`);
                objbuilder += (`<embed src="${fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileExtension},`);
                objbuilder += (Data?.Data);
                objbuilder += (`" type="${fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileExtension.split(":").pop().split(";")[0]}" />`);
                objbuilder += ('</object>');
                setFile(objbuilder)

            })
            .catch(err => console.log(err))
    }

    // Download File Content
    async function downloadFileContent() {
        let linkSource = `${fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileExtension},${fileBase64}`;
        let downloadLink = document.createElement("a");
        let fileName = data.fileName;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <ReusableDialog maxWidth="xl" isOpen={open} title={docTitle} handleClose={() => setOpen(false)}>
            {visible ? <AlertPop
                isDanger={alert.isError}
                toggle={setVisible}
                timer={7000}
                isOpen={visible}
                heading={alert.heading}>
                {alert.text}
            </AlertPop> : null}
            <SoftBox>
                <SoftBox>
                    <SoftTypography variant="h6" fontWeight="medium" fontSize={13} display="block">&nbsp;&nbsp;PREVIEW</SoftTypography>
                </SoftBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={9}>
                        <SoftBox style={{ background: '#F6F6F6', borderRadius: 8, padding: 10, border: '2px solid #D9D9D9' }}>
                            {!file
                                ? <div style={{ height: 600, borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress size={30} color="info" />
                                </div>
                                : isDifferentFormate
                                    ? <div style={{
                                        height: 600,
                                        borderRadius: 8,
                                        background: '#4A4D50',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <SoftBox style={{ opacity: .6 }} px={1} component="img" src={warningIcon} />
                                            <div style={{ color: '#ffffff', marginTop: 15, fontSize: 14, fontWeight: 300 }}>
                                                Document Preview <br />
                                                Unavailable for this <br />
                                                File Format
                                            </div>
                                        </div>
                                    </div>
                                    : <PDFViewer isPDF={isPDF} file={file} base64Data={fileBase64} numPages={numPages} onDocumentLoadSuccess={onDocumentLoadSuccess} /> }
                                    {/* : <div style={{ height: 600, borderRadius: 8 }} dangerouslySetInnerHTML={{ __html: file }} />} */}
                        </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        {fileDocument
                            ? <SoftBox mb={3}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6" fontWeight="bold" fontSize={13}>File Name</SoftTypography>
                                    <SoftTypography component="span" fontWeight="medium" fontSize={12} pl={3} style={{ color: '#293E8F' }}>
                                        {fileNameWithExtension(fileDocument[0]?.Document_Name)} &nbsp;&nbsp;|&nbsp;&nbsp; <span style={{ fontWeight: 500 }}>{bytesToMegaBytes(fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileSize)}</span>
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6" fontWeight="bold" fontSize={13}>Document Type &nbsp; </SoftTypography>
                                    <SoftTypography component="span" fontSize={12} pl={3} fontWeight="medium" style={{ color: '#293E8F', textAlign: 'right' }}>{fileDocument[0]?.Document_Type}</SoftTypography>
                                </SoftBox>
                                <SoftBox mb={2}>
                                    {/* <pre style={{ fontSize: 10 }}>{JSON.stringify(fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileMetaData?.Sharable)}</pre> */}
                                    {isStatusEdit
                                        ? <SoftBox>
                                            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                                                <SoftTypography variant="h6" fontWeight="bold" fontSize={13}>Share Status</SoftTypography>
                                                <SoftBox>
                                                    <SoftButton onClick={() => setIsStatusEdit(false)} variant="text" color="dark" size="small" circular style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: 12, padding: '3px 12px' }}>
                                                        Cancel
                                                    </SoftButton>
                                                    <SoftButton onClick={updateShareStatus} disabled={isShareLoading} variant="outlined" color="info" size="small" circular style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: 12, padding: '3px 12px' }}>
                                                        {isShareLoading && <CircularProgress size={14} color="info" />}
                                                        {!isShareLoading && "Save"}
                                                    </SoftButton>
                                                </SoftBox>
                                            </SoftBox>
                                            <SoftBox mt={1} display="flex" flexDirection="column" justifyContent="space-between">
                                                <SoftSelect
                                                    name="shareStatus"
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                    options={[{ value: 'True', label: 'Shared' }, { value: 'False', label: 'Unshared' }]}
                                                    value={shareStatus}
                                                    onChange={(value) => setShareStatus(value)} />
                                            </SoftBox>
                                        </SoftBox>
                                        : <SoftBox>
                                            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                                                <SoftTypography variant="h6" fontWeight="bold" fontSize={13}>Share Status</SoftTypography>
                                                <SoftButton onClick={() => changeShareStatus(fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileMetaData?.Sharable)} variant="outlined" color="dark" size="small" circular style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: 12, padding: '3px 12px' }}>
                                                    Edit Share Status
                                                </SoftButton>
                                            </SoftBox>
                                            {fileDocument[0]?.DocumentStorage?.DocumentFiles[0]?.DocumentFileMetaData?.Sharable === "True"
                                                ? <SoftBox display="flex" style={{
                                                    background: '#E5F7FC',
                                                    padding: '3px 6px 0',
                                                    width: 100,
                                                    justifyContent: 'center',
                                                    borderRadius: 50,
                                                    alignItems: 'center'
                                                }}>
                                                    <SoftBox fontSize={15} display="block" style={{ position: 'relative', top: 3 }}><PeopleAltIcon style={{ marginTop: 1 }} /></SoftBox>
                                                    <SoftTypography ml={0.5} fontSize={11} fontWeight="medium" style={{ color: '#293E8F' }}>Shared</SoftTypography>
                                                </SoftBox>
                                                : <SoftBox display="flex" style={{
                                                    background: '#DCE3E8',
                                                    padding: '3px 6px 0',
                                                    width: 100,
                                                    justifyContent: 'center',
                                                    borderRadius: 50,
                                                    alignItems: 'center'
                                                }}>
                                                    <SoftBox fontSize={15} display="block" style={{ position: 'relative', top: 3 }}><DoNotDisturbAltIcon /></SoftBox>
                                                    <SoftTypography ml={0.5} fontSize={11} fontWeight="medium" style={{ color: '#3E5463' }}>Unshared</SoftTypography>
                                                </SoftBox>}
                                        </SoftBox>}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                                        <SoftTypography variant="h6" fontWeight="bold" fontSize={13}>Designated Contact</SoftTypography>
                                        <SoftButton onClick={() => setIsInfoEdit(true)} variant="outlined" color="dark" size="small" circular style={{ textTransform: 'capitalize', fontWeight: 500, fontSize: 12, padding: '3px 12px' }}>
                                            Edit Info
                                        </SoftButton>
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox p={2.5} style={{ background: '#F9F9F9', borderRadius: 10 }}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" fontWeight="bold" fontSize={12} display="block" mb={0.5}>Name</SoftTypography>
                                        <SoftTypography variant="caption" fontSize={12} display="block">
                                            <span style={{ textTransform: 'capitalize' }}>{fileDocument[0]?.UserDocumentContact?.Contact_FirstName}&nbsp;</span>
                                            <span style={{ textTransform: 'capitalize' }}>{fileDocument[0]?.UserDocumentContact?.Contact_LastName}</span>
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" fontWeight="bold" fontSize={12} display="block" mb={0.5}>Phone Number</SoftTypography>
                                        <SoftTypography variant="caption" fontSize={12} display="block">{fileDocument[0]?.UserDocumentContact?.Contact_Phone}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" fontWeight="bold" fontSize={12} display="block" mb={0.5}>Email Address</SoftTypography>
                                        <SoftTypography variant="caption" fontSize={12} display="block">{fileDocument[0]?.UserDocumentContact?.Contact_Email}</SoftTypography>
                                    </SoftBox>
                                    <SoftBox>
                                        <SoftTypography variant="h6" fontWeight="bold" fontSize={12} display="block" mb={0.5}>Address</SoftTypography>
                                        <SoftTypography variant="caption" fontSize={12} display="block">
                                            {fileDocument[0]?.UserDocumentContact?.Contact_Street1}{", "}
                                            {fileDocument[0]?.UserDocumentContact?.Contact_Street2}{" "}
                                            {fileDocument[0]?.UserDocumentContact?.Contact_City}{", "}
                                            {fileDocument[0]?.UserDocumentContact?.Contact_State}{" "}
                                            {fileDocument[0]?.UserDocumentContact?.Contact_PostalCode}
                                        </SoftTypography>
                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                            : <SoftBox display="flex" justifyContent="center" alignItems="center" mb={2} style={{ height: 300, background: '#F9F9F9', borderRadius: 8 }}>
                                <CircularProgress size={24} color="info" />
                            </SoftBox>}
                        <SoftBox mb={3}>
                            <SoftButton disabled={!fileDocument} onClick={() => setDeleteOpen(true)} variant="outlined" fullWidth color="dark" size="medium" circular
                                style={{ textTransform: 'capitalize', fontWeight: 600 }}>
                                <SoftBox px={1} component="img" src={deleteIcon} />
                                &nbsp;Delete Document
                            </SoftButton>
                            <SoftButton disabled={!fileBase64} onClick={downloadFileContent} variant="gradient" fullWidth color="info" size="medium" circular
                                style={{ textTransform: 'capitalize', fontWeight: 600, marginTop: 15 }}>
                                <SoftBox px={1} component="img" src={downloadIcon} />
                                &nbsp;Download Document
                            </SoftButton>
                        </SoftBox>
                        {/* <Tooltip title="Evaluating for a Future Phase" placement="top-end">
                            <SoftBox display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                                <SoftBox style={{ width: '50%' }} px={1} component="img" src={leftArrow} />
                                <SoftBox style={{ width: '50%' }} px={1} component="img" src={rightArrow} />
                            </SoftBox>
                        </Tooltip> */}
                    </Grid>
                </Grid>
            </SoftBox>

            {/* Reusable Dialog For Delete Document */}
            {deleteOpen ? <DeleteDocument
                data={data}
                open={deleteOpen}
                setOpen={setDeleteOpen}
                setAlert={setAlert}
                setVisible={setVisible}
                setClose={setOpen} /> : null}

            {/* Reusable Dialog For Edit User Info */}
            {isInfoEdit
                ? <SoftBox>
                    <ReusableDialog isOpen={isInfoEdit} title={
                        <SoftBox>
                            <SoftTypography variant="h5" fontWeight="bold" color="dark" mb={0.5}>Edit Designated Contact</SoftTypography>
                            <SoftTypography variant="caption" fontWeight="regular" fontSize={14} display="block">
                                Please provide accurate information as this designated contact will be contacted via the provided email address.
                            </SoftTypography>
                        </SoftBox>
                    } handleClose={() => setIsInfoEdit(false)}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, setFieldValue, setErrors }) => (
                                <Form id="designatedContact" autoComplete="off">
                                    <DesignatedContactForm formData={{ values, errors, touched, setFieldValue, setErrors, formField, data: fileDocument[0]?.UserDocumentContact }} />
                                    <SoftBox display="flex" justifyContent="end" alignItems="center" mt={2.5}>
                                        <SoftButton
                                            variant="text"
                                            circular={true}
                                            color="dark"
                                            size="medium"
                                            onClick={() => setIsInfoEdit(false)}
                                            style={{ marginRight: 10, textTransform: 'capitalize', fontWeight: 500, marginTop: 5 }}>
                                            Discard Changes
                                        </SoftButton>
                                        <SoftButton
                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            circular={true}
                                            size='medium'
                                            disabled={loading}
                                            style={{ textTransform: 'capitalize', fontWeight: 500, marginTop: 10, fontSize: 14 }}
                                        >
                                            {loading && <CircularProgress size={14} color="white" />}
                                            {!loading && "Save Changes"}
                                        </SoftButton>
                                    </SoftBox>
                                </Form>
                            )}
                        </Formik>
                    </ReusableDialog>
                </SoftBox>
                : null}
        </ReusableDialog>
    )
}