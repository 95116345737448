import * as Yup from "yup";
import checkout from "./form";
import { phoneRegExp } from "utils/constant";
const {
  formField: { firstName, lastName, email, phone, address1, city, state, zip, gender },
} = checkout;

const getValidations = (disabledFields = []) => {
  let validationShape = {
    [firstName.name]: Yup.string()
      .required(firstName.errorMsg)
      .matches(
        /^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/,
        "Alphabetical characters only."
      ),
    [lastName.name]: Yup.string()
      .required(lastName.errorMsg)
      .matches(
        /^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/,
        "Alphabetical characters only."
      ),
    [email.name]: Yup.string()
      .required(email.errorMsg)
      .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, email.invalidMsg),
  };
  if (!disabledFields.includes(phone.name)) {
    validationShape[phone.name] = Yup.string().required(phone.errorMsg);
  }
  if (!disabledFields.includes(address1.name)) {
    validationShape[address1.name] = Yup.string().required(address1.errorMsg);
  }
  if (!disabledFields.includes(city.name)) {
    validationShape[city.name] = Yup.string().required(city.errorMsg);
  }
  if (!disabledFields.includes(state.name)) {
    validationShape[state.name] = Yup.object().required(state.errorMsg);
  }
  if (!disabledFields.includes(zip.name)) {
    validationShape[zip.name] = Yup.string()
      .length(5, "Zip Code is invalid.")
      .matches(/^[0-9]{5}/, "Zip Code is invalid")
      .required(zip.errorMsg);
  }

  return [Yup.object().shape(validationShape)];
};

export default getValidations;
