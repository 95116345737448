import * as Actions from "../../constants/constants";

const initialState = {
    data: null,
};

export default (state = initialState, action) => {
    // debugger
    switch (action.type) {
        case Actions.GET_RECORD_APPROVED:
            return { ...state, data: action.payload };
        case Actions.GET_RECORD_FAIL:
            return { ...state, data: null };
        default:
            return state;
    }
};