import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import { UploadInfo } from "./UploadInfo";
import { UploadTable } from "./UploadTable";
import { Grid } from "@mui/material";

// NewUser page components
function UploadDocuments({ formData }) {
  // console.log(formData)
  return (
    <Grid container justifyContent="center" sx={{ height: "100%" }}>
      <Grid item xs={12} lg={5}>
        <SoftBox>
          <UploadInfo />
          <UploadTable {...formData} />
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// typechecking props for Profile
UploadDocuments.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UploadDocuments;
