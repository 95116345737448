import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import Footer from "layouts/authentication/components/Footer";

// NewUser page components
import Socials from "layouts/authentication/components/Socials";

// NewUser layout schemas for form and form feilds
import { Link, useNavigate } from "react-router-dom";
import { request } from "redux/actions/request";
import { CircularProgress } from "@mui/material";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import CreateAccount from "./components/CreateAccount";
import PersonalInfo from "./components/PersonalInfo";
import PaymentInfo from "./components/PaymentInfo/index";
import form from "./schemas/form";
import validations from "./schemas/validations";
import initialValues from "./schemas/initialValues";
import "./stepper.css";
import { useSelector } from "react-redux";

function getSteps() {
  return ["Create Account", "Personal Info", "Payment Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <CreateAccount formData={formData} />;
    case 1:
      return <PersonalInfo formData={formData} />;
    case 2:
      return <PaymentInfo formData={formData}/>;
    default:
      return null;
  }
}

function Enrollment() {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(7000);
  const [resendLoader, setResendLoader] = useState(false);
  const [isDanger, setIsDanger] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabledCheckout, setIsDisabledCheckout] = useState(false);
  const [customerRefId, setCustomerRefId] = useState('');
  const [userEmail, setUserEmail] = useState("");
  const [isCompleteEnrollment, setIsCompleteEnrollment] = useState(false);
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const isPersonalInfoStep = activeStep === steps.length - 2;

  const handleBack = () => setActiveStep(activeStep - 1);
  const productSelectValue = useSelector(state => state.productValue.productValue);


  useEffect(() => {
    if (localStorage.getItem("authToken") !== null) navigate("/dashboard");
  }, []);

  const submitForm = async (values, actions) => {
    setIsSubmit(true);
    request("Users/PostExternalUser", "POST", values)
      .then(async (result) => {
        // const loginErrResponse = JSON.parse(localStorage.getItem('loginErrResponse'));
        // if(loginErrResponse !== 'undefined') {
        //   localStorage.removeItem('loginErrResponse');
        // }
        setActiveStep(activeStep + 1);
        setError(null);
        setIsCompleteEnrollment(true);
        setUserEmail(values.EmailAddress);
        setCustomerRefId(result?.Data?.Aspnet_Id)
        localStorage.setItem('client_reference_id', result?.Data?.Aspnet_Id);
        actions.setSubmitting(false);
        setOpen(false);
        setIsSubmit(false);
        setIsDanger(false);
        actions.resetForm();
        setTimer(7000);
        if (result.Data.IsEmailVerified) setIsAlreadyVerified(true);
        if (!result.Data.IsEmailVerified) setIsAlreadyVerified(false);
      })
      .catch((error) => {
        setOpen(true);
        setIsDanger(true);
        setError(error);
        setIsSubmit(false);
        setTimer(null);
      });
  };

  const handleSubmit = async (values, actions) => {
    if (isPersonalInfoStep) {
      const payload = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Gender: null,
        SMSCapableNumber: values.phone.toString(),
        Street1: values.address1,
        Street2: values.address2,
        City: values.city,
        State: values.state.value,
        PostalCode: values.zip.toString(),
        EmailAddress: values.email,
        Password: values.password,
        IsActive:false
      };
      submitForm(payload, actions);
      localStorage.setItem('EmailAddress', payload?.EmailAddress);
    } else if (isLastStep) {
      const url = `${productSelectValue.PaymentLink}?client_reference_id=${customerRefId}`;
      window.location.replace(url);
     } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    const loginErrResponse = localStorage.getItem('loginErrResponse')
       if (loginErrResponse?.length > 0 && loginErrResponse !== 'undefined') {
          const errResponse = JSON.parse(loginErrResponse)
          if (errResponse.EmailConfirmed) {
            setActiveStep(2)
            setCustomerRefId(errResponse?.UserId || errResponse?.Aspnet_Id);
          }
       }
  }, [])

  async function ResentEmail() {
    setResendLoader(true);
    request("Users/ResendEmailVerificationLink", "POST", { EmailId: userEmail })
      .then(() => {
        setOpen(true);
        setError(null);
        setIsDanger(false);
        setResendLoader(false);
      })
      .catch((error) => {
        setOpen(true);
        setIsDanger(true);
        setError(error);
        setResendLoader(false);
        setTimer(null);
      });
  }

  return (
    // <DashboardLayout>
    // <DashboardNavbar />
    <>
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={5}>
            <AlertPop
              isDanger={isDanger ?? false}
              toggle={setOpen}
              isOpen={open}
              timer={timer}
              heading={isDanger ? "Error" : "Email Verification Successfully Resent"}
            >
              {isDanger ? error : `A new verification link was sent to ${userEmail}`}
            </AlertPop>
            <SoftBox mb={1}>
              <Socials />
            </SoftBox>
            <Stepper activeStep={activeStep} alternativeLabel className="cs-stepper">
              {steps.map((label, index) => (
                <Step key={label} className={activeStep === index ? "active-wizard" : ""}>
                  <StepLabel>
                    <SoftBox fontWeight={600} fontSize={15}>
                      {label}
                    </SoftBox>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {!isCompleteEnrollment ? (
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Form id={formId} autoComplete="off">
                    {/* <p>{JSON.stringify(isSubmitting)}</p> */}
                    <Card
                      sx={{ height: "100%" }}
                      style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)", overflow: "inherit" }}
                    >
                      <SoftBox p={4}>
                        <SoftBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            setFieldValue,
                            errors,
                            // agree,
                            // setAgree
                          })}
                          <SoftBox mt={2} width="100%" display="flex" justifyContent="end">
                            {(activeStep === 0 || activeStep === 2) ? (
                              <SoftBox />
                            ) : (
                              <SoftButton
                                variant="text"
                                circular={true}
                                color="text"
                                onClick={handleBack}
                                style={{
                                  marginRight: 10,
                                  padding: 13,
                                  textTransform: "capitalize",
                                  fontWeight: 500,
                                }}
                              >
                                back
                              </SoftButton>
                            )}
                            <SoftButton
                              // disabled={Object.keys(errors).length || !agree ? true : false}
                              disabled={isLastStep && isDisabledCheckout}
                              type="submit"
                              variant="gradient"
                              color="info"
                              circular={true}
                              style={{ textTransform: "capitalize", fontWeight: 500 }}

                            >
                              {isSubmit ? (
                                <CircularProgress size={18} color="white" />
                              ) : isLastStep ? (
                                "Checkout"
                              ) : (
                                "continue"
                              )}
                            </SoftButton>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            ) : (
              <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)", overflow: "inherit" }}>
                <SoftBox p={4}>
                  {!isAlreadyVerified && (
                    <>
                      <SoftTypography variant="h5" fontWeight="bold" mb={2}>
                        Thanks for enrolling! Read below for one last step:
                      </SoftTypography>
                      <SoftBox mb={4}>
                        <SoftTypography mb={2.5} variant="caption" fontSize={14} display="block">
                          An email was sent to{" "}
                          <SoftTypography
                            fontSize={14}
                            variant="caption"
                            color="info"
                            fontWeight="bold"
                          >
                            {userEmail}
                          </SoftTypography>{" "}
                          with a link to verify your email address. Please{" "}
                          <strong>click the link</strong> within to complete your enrollment.
                        </SoftTypography>
                        <SoftTypography variant="caption" fontSize={14} display="block">
                          If you don’t see the email within a couple of minutes, make sure to check
                          your promotions/spam folder.
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox mb={5}>
                        <SoftTypography variant="caption" fontSize={14} display="block">
                          Did not receive the email? &nbsp;
                          {resendLoader ? (
                            <SoftBox mx={4} display="inline">
                              <CircularProgress size={12} color="info" />
                            </SoftBox>
                          ) : (
                            <Link to="#" onClick={ResentEmail}>
                              <SoftTypography
                                style={{ textDecoration: "underline" }}
                                fontSize={14}
                                variant="caption"
                                fontWeight="medium"
                                color="info"
                              >
                                Click here to resend
                              </SoftTypography>
                            </Link>
                          )}
                        </SoftTypography>
                      </SoftBox>
                    </>
                  )}
                  {isAlreadyVerified && (
                    <>
                      <SoftTypography variant="h5" fontWeight="bold" mb={2}>
                        This email has already been verified.
                      </SoftTypography>
                      <SoftBox mb={4}>
                        <SoftTypography mb={2.5} variant="caption" fontSize={14} display="block">
                          You may now sign into the application using{" "}
                          <SoftTypography
                            fontSize={14}
                            variant="caption"
                            color="info"
                            fontWeight="bold"
                          >
                            {userEmail}
                          </SoftTypography>
                          .
                        </SoftTypography>
                      </SoftBox>
                    </>
                  )}
                  <SoftBox px={6}>
                    <Link to="/login">
                      <SoftButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        size="large"
                        circular={true}
                        style={{ textTransform: "capitalize", fontWeight: 500 }}
                      >
                        Continue to Sign In
                      </SoftButton>
                    </Link>
                  </SoftBox>
                </SoftBox>
              </Card>
            )}
          </Grid>
        </Grid>
        {!isCompleteEnrollment ? (
          <SoftBox pt={2} textAlign="center">
            <SoftTypography
              variant="caption"
              fontWeight="regular"
              fontSize={14}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;Already have an account?&nbsp;
            </SoftTypography>
            <Link to="/login">
              <SoftTypography
                variant="caption"
                color="info"
                fontWeight="bold"
                fontSize={14}
                textGradient={true}
                style={{ zIndex: 0 }}
              >
                &nbsp;Sign in
              </SoftTypography>
            </Link>
          </SoftBox>
        ) : null}
      </SoftBox>
      <Footer />
    </>
    // </DashboardLayout>
  );
}

export default Enrollment;
