import SoftTypography from 'components/SoftTypography'

export const Password = () => {
    return (
        <SoftTypography display="block"
            variant="caption"
            fontWeight="light"
            fontSize={14}
            lineHeight={2.5}
        >
            {/* <strong>Current Password:</strong>&nbsp;••••••••••••<br /> */}
            <strong>Last Set On:</strong>&nbsp;12/01/2021<br />
        </SoftTypography>
    )
}
