import { useState } from "react"
import { Card, CircularProgress } from "@mui/material"
import { Form, Formik } from "formik";
import SoftBox from "components/SoftBox"
import { useSelector, useDispatch } from "react-redux";
import SoftButton from "components/SoftButton"
import SoftTypography from "components/SoftTypography"
import { PersonalForm } from "./PersonalForm"
import validations from "../../schemas/validations";
import form from "../../schemas/form"
import initialValues from "../../schemas/initialValues";
import { request } from "redux/actions/request";
import { fetchUserInformation } from "redux/actions/users";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
import { formatPhoneNumber } from "utils/constant";

export const PersonalInfo = ({ formData }) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState({
        heading: "",
        text: "",
        isError: false
    })
    const { userInfo: { data } } = useSelector(state => state)
    // console.log(data)
    const [isEdit, setIsEdit] = useState(false)
    const currentValidation = validations[0]
    const { formField } = form;
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {
       // debugger
        setLoading(true)
        let payload = {
            Aspnet_UserId: data?.Aspnet_UserId,
            FirstName: values.firstName,
            LastName: values.lastName,
            Gender: values.gender ? values.gender.value : null,
            SMSCapableNumber: values.phone.toString(),
            Street1: values.address1,
            Street2: values.address2,
            City: values.city,
            State: values.state.value,
            PostalCode: values.zip,
            EmailAddress: data?.EmailAddress
        }

        request("User", "PUT", payload)
            .then(async (result) => {
                await dispatch(fetchUserInformation(data?.EmailAddress))
                setOpen(true)
                setAlert({
                    heading: "Changes Successfully Saved",
                    text: "Your personal information has been saved.",
                    isError: false
                })
                setIsEdit(false)
                setLoading(false)
            })
            .catch((error) => {
                setOpen(true)
                setAlert({
                    heading: "Error",
                    text: error,
                    isError: true
                })
                setIsEdit(false)
                setLoading(false)
            })
    }

    return (
        <SoftBox mb={3}>
            <AlertPop
                isDanger={alert.isError}
                toggle={setOpen}
                timer={7000}
                isOpen={open}
                heading={alert.heading}>
                {alert.text}
            </AlertPop>
            <Formik initialValues={initialValues} validationSchema={currentValidation}>
                {({ values, errors, touched, setFieldValue, setErrors }) => (
                    <Form id="userUpdate" autoComplete="off">
                        {/* <p>{Object.keys(errors).length}</p> */}
                        <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                            <SoftBox p={4}>
                                <SoftBox lineHeight={0.8}>
                                    <SoftTypography variant="h5" fontWeight="bold" color="info" textGradient={true}>
                                        Personal Information
                                    </SoftTypography>
                                    <SoftBox mb={2.5} mt={0.5}>
                                        <SoftTypography variant="caption" fontWeight="light" fontSize={14}>
                                            Please review your information below and proceed to the next step. Provide accurate information as it will be printed on your card.
                                        </SoftTypography>
                                        <SoftBox display="flex" justifyContent="end">
                                            {!isEdit && <SoftButton
                                                variant="outlined"
                                                color="dark"
                                                type="button"
                                                onClick={() => setIsEdit(true)}
                                                size="small"
                                                style={{ color: "#333333", textTransform: "capitalize", borderColor: "#AAAAAA", fontWeight: 500 }}
                                                circular={true}>Edit Information</SoftButton>}
                                            {isEdit && <SoftButton
                                                variant="outlined"
                                                color="info"
                                                size="small"
                                                type="submit"
                                                disabled={Object.keys(errors).length > 0 || loading ? true : false}
                                                onClick={() => handleSubmit(values)}
                                                style={{ textTransform: "capitalize", fontWeight: 500 }}
                                                circular={true}>
                                                {loading && <CircularProgress size={14} color="info" />}
                                                {!loading && "Save Changes"}
                                            </SoftButton>}
                                        </SoftBox>
                                    </SoftBox>
                                </SoftBox>
                                {!isEdit ? <SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Name
                                        </SoftTypography>
                                        {data ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            <span style={{ textTransform: 'capitalize' }}>{data?.FirstName}</span> <span style={{ textTransform: 'capitalize' }}>{data?.LastName}</span>
                                        </SoftTypography> : null}
                                    </SoftBox>
                                    <SoftBox mb={2}>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Phone Number
                                        </SoftTypography>
                                        {data ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            {data?.SMSCapableNumber}
                                        </SoftTypography> : null}
                                    </SoftBox>
                                    {data?.Gender ? <SoftBox mb={2}>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Gender
                                        </SoftTypography>
                                        {data ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            {data?.Gender}
                                        </SoftTypography> : null}
                                    </SoftBox> : null}
                                    <SoftBox>
                                        <SoftTypography fontSize={14.5} variant="caption" fontWeight="bold" color="dark" display="block">
                                            Address
                                        </SoftTypography>
                                        {data ? <SoftTypography variant="caption" fontWeight="regular" color="dark">
                                            {data?.Street1} {data?.Street2} {data?.City}, {data?.State} {data?.PostalCode}
                                        </SoftTypography> : null}
                                    </SoftBox>
                                </SoftBox> :
                                    <PersonalForm formData={{ formData, values, errors, touched, setErrors, setFieldValue, formField }} />
                                }
                            </SoftBox>
                        </Card>
                    </Form>
                )}
            </Formik>
        </SoftBox>
    )
}
