import { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import FormField from "../../pages/usermanagement/component/FormField";
import form from "../../pages/usermanagement/component/schemas/form";
import getValidations from "layouts/pages/profile/schemas/validations";
import initialValues from "../../pages/usermanagement/component/schemas/initialValues";
import { StateList } from "layouts/authentication/enrollment/components/PersonalInfo/StateList";
import { request } from "redux/actions/request";
import { fetchUserInformation } from "redux/actions/users";

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

function PersonalFormField(props) {
  const { values, errors, touched, setFieldValue, formField, setErrors, data } = props;
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const statusOptions = StateList;
  const { firstName, lastName, phone, address1, address2, city, state, gender, zip } = formField;
  const role = data?.RoleName;

  useEffect(() => {
    if (data) {
      if (
        (role && role.toLowerCase() === "administrator") ||
        (role && role.toLowerCase() === "superadmin")
      ) {
        setFieldValue("firstName", data.FirstName);
        setFieldValue("lastName", data.LastName);
        setFieldValue("email", data.EmailAddress);
        setTimeout(() => setErrors({}), 0);
        return;
      }
      let state = StateList.filter((x) => x.value === data.State);
      setFieldValue("firstName", data.FirstName);
      setFieldValue("lastName", data.LastName);
      setFieldValue("email", data.EmailAddress);
      setFieldValue("gender", data.Gender ? { label: data.Gender, value: data.Gender } : null);
      setFieldValue("phone", data.SMSCapableNumber);
      setFieldValue("address1", data.Street1);
      setFieldValue("address2", data.Street2);
      setFieldValue("city", data.City);
      setFieldValue(
        "state",
        state.length ? { label: state[0].label, value: state[0].value } : null
      );
      setFieldValue("zip", data.PostalCode);
      setSelectedStatus(state.length ? { label: state[0].label, value: state[0].value } : null);
      setSelectedGender(data.Gender ? { label: data.Gender, value: data.Gender } : null);

      setTimeout(() => setErrors({}), 0);
    }
  }, [data]);

  return (
    <>
      <SoftBox>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid
            item
            xs={12}
            lg={
              (role && role.toLowerCase() === "superadmin") ||
              (role && role.toLowerCase() === "administrator")
                ? 12
                : 7
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={firstName.type}
                  label={firstName.label}
                  name={firstName.name}
                  value={values.firstName}
                  placeholder={firstName.placeholder}
                  error={errors.firstName && touched.firstName}
                  success={values.firstName.length > 0 && !errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={lastName.type}
                  label={lastName.label}
                  name={lastName.name}
                  value={values.lastName}
                  placeholder={lastName.placeholder}
                  error={errors.lastName && touched.lastName}
                  success={values.lastName.length > 0 && !errors.lastName}
                />
              </Grid>
            </Grid>
          </Grid>
          {(role && role.toLowerCase() === "superadmin") ||
          (role && role.toLowerCase() === "administrator") ? null : (
            <>
              <Grid item xs={12} lg={5}>
                <FormField
                  fieldType="phone"
                  id={phone.name}
                  helper="US Number Only"
                  type={phone.type}
                  name={phone.name}
                  setFieldValue={setFieldValue}
                  label={phone.label}
                  value={values.phone}
                  placeholder={phone.placeholder}
                  error={errors.phone && touched.phone}
                  success={values.phone.length > 0 && !errors.phone}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  type={address1.type}
                  label={address1.label}
                  name={address1.name}
                  value={values.address1}
                  placeholder={address1.placeholder}
                  error={errors.address1 && touched.address1}
                  success={values.address1.length > 0 && !errors.address1}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <SoftBox mt={4}>
                  <FormField
                    type={address2.type}
                    name={address2.name}
                    value={values.address2}
                    label=""
                    placeholder={address2.placeholder}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  fieldType="select"
                  type={gender.type}
                  // label={gender.label}
                  name={gender.name}
                  options={genderOptions}
                  defaultValue={selectedGender}
                  value={selectedGender}
                  onChange={(value) => {
                    setSelectedGender(value);
                    setFieldValue("gender", value);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={gender.placeholder}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  type={city.type}
                  name={city.name}
                  value={values.city}
                  label=""
                  placeholder={city.placeholder}
                  error={errors.city && touched.city}
                  success={values.city.length > 0 && !errors.city}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  fieldType="select"
                  type={state.type}
                  label={""}
                  name={state.name}
                  options={statusOptions}
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onChange={(value) => {
                    setSelectedStatus(value);
                    setFieldValue("state", value);
                  }}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder={state.placeholder}
                  error={errors.state && touched.state}
                  success={values.state.length > 0 && !errors.state}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormField
                  type={zip.type}
                  name={zip.name}
                  label=""
                  value={values.zip}
                  placeholder={zip.placeholder}
                  error={errors.zip && touched.zip}
                  success={values.zip.length > 0 && !errors.zip}
                />
              </Grid>
            </>
          )}
        </Grid>
      </SoftBox>
    </>
  );
}

function PersonalForm(props) {
  const { setOpen, data, setVisible, setAlert } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { formField } = form;
  const disabledFields = ["phone", "address1", "city", "state", "zip"];
  const currentValidation = getValidations(
    data?.RoleName?.toLowerCase() === "superadmin" ||
      data?.RoleName?.toLowerCase() === "administrator"
      ? disabledFields
      : null
  )[0];
  const userData = JSON.parse(localStorage.getItem("userDetails"));

  const handleSubmit = async (values) => {
    let payload = {};
    setLoading(true);
    if (
      data?.RoleName?.toLowerCase() === "superadmin" ||
      data?.RoleName?.toLowerCase() === "administrator"
    ) {
      payload = {
        Aspnet_UserId: data?.Aspnet_UserId,
        FirstName: values.firstName,
        LastName: values.lastName,
        EmailAddress: data?.EmailAddress,
      };
    } else {
      payload = {
        Aspnet_UserId: data?.Aspnet_UserId,
        FirstName: values.firstName,
        LastName: values.lastName,
        Gender: values.gender ? values.gender.value : null,
        SMSCapableNumber: values.phone.toString(),
        Street1: values.address1,
        Street2: values.address2,
        City: values.city,
        State: values.state.value,
        PostalCode: values.zip,
        EmailAddress: data?.EmailAddress,
      };
    }

    request("User", "PUT", payload)
      .then(async () => {
        await dispatch(fetchUserInformation(data?.EmailAddress));
        setAlert({
          heading: "Changes Successfully Saved",
          text: "Your personal information has been saved.",
          isError: false,
        });
        setLoading(false);
        setOpen(false);
        setVisible(true);
      })
      .catch((error) => {
        setAlert({
          heading: "Error",
          text: error,
          isError: true,
        });
        setVisible(false);
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, setErrors }) => (
        <Form id="PersonalContact" autoComplete="off">
          <PersonalFormField
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            formField={formField}
            data={data}
            setErrors={setErrors}
          />
          <SoftBox display="flex" justifyContent="end" mt={2}>
            <SoftButton
              variant="text"
              color="dark"
              type="button"
              onClick={() => setOpen(false)}
              size="medium"
              style={{
                color: "#333333",
                textTransform: "capitalize",
                marginTop: 5,
                fontWeight: 500,
              }}
              circular={true}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              size="medium"
              type="submit"
              disabled={loading}
              style={{ textTransform: "capitalize", fontWeight: 500, marginLeft: 10 }}
              circular={true}
            >
              {loading && <CircularProgress size={14} color="info" />}
              {!loading && "Save Changes"}
            </SoftButton>
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default PersonalForm;
