import * as Actions from "../../constants/constants";

const initialState = {
    data: null,
};

export default (state = initialState, action) => {
    // debugger
    switch (action.type) {
        case Actions.GET_DESIGNATED_CONTACT_SUCCESS:
            return { ...state, data: action.payload };
        case Actions.GET_DESIGNATED_CONTACT_FAIL:
            return { ...state, data: null };
        default:
            return state;
    }
};