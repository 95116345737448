import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { email, password },
} = checkout;

const validations = [
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
  }),
];

export default validations;
