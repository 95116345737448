import PropTypes from "prop-types";
import { PersonalInfo } from "./PersonalInfo";
import { EmergencyContact } from "./EmergencyContact";
import { Grid } from "@mui/material";

function CreateInfo({ formData }) {
  return (
    <Grid container justifyContent="center" sx={{ height: "100%" }}>
      <Grid item xs={12} lg={5}>
        <PersonalInfo formData={formData} />
        <EmergencyContact formData={formData} />
      </Grid>
    </Grid>
  );
}

// typechecking props for CreateInfo
CreateInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CreateInfo;
