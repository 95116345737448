import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Card, useMediaQuery } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import RequestForm from "./Requestform";
import { CardFront } from "./CardFront";
import RequestSent from "./Requestsent";

import Footer from "layouts/authentication/components/Footer";
import brand from "assets/images/brand-logo.svg";
import lock from "../Recordrequest/icons/lock.svg";
import { fetchUserInfoRecords } from "redux/actions/records/approved";

const style = {
  borderWidth: 0.3,
  borderLeftStyle: "dashed",
  borderRadius: 1,
  borderColor: "#CCCCCC",
};

const RecordRequest = () => {
  const dispatch = useDispatch();
  const { requestInfo } = useSelector((state) => state);
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const isMobile = useMediaQuery("(max-width:767px)");

  useEffect(() => {
    if (!requestInfo.data) fetchInfo();
  }, [requestInfo.data]);

  async function fetchInfo() {
    let userId = searchParams.get("id");
    if (userId) {
      return await dispatch(fetchUserInfoRecords(userId));
    }
  }

  return (
    <SoftBox>
      <SoftBox mt={isMobile ? 2 : 6} mb={12} style={{ minHeight: "calc(100vh - 50px)" }}>
        <Grid container justifyContent="center" p={isMobile ? 2 : 0}>
          <Grid item xs={12} sm={12} md={8}>
            {isSuccess ? (
              <RequestSent email={email} />
            ) : (
              <Card
                style={{
                  background: "#FFF",
                  boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)",
                  overflow: "inherit",
                }}
              >
                <SoftBox px={isMobile ? 3 : 6} py={isMobile ? 2.5 : 5}>
                  <SoftBox textAlign="center" p={isMobile ? 0 : 3} mb={2}>
                    <SoftBox
                      px={1}
                      component="img"
                      src={brand}
                      style={{ width: isMobile ? 150 : 190 }}
                    />
                    <SoftBox mt={isMobile ? 2 : 4}>
                      <SoftBox textAlign="center" display="flex" justifyContent="center">
                        <SoftBox px={1} component="img" src={lock} />
                        <SoftTypography variant="h5" fontWeight="medium">
                          Send a Records Request
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10} sm={8} md={4}>
                      <SoftBox>
                        {(requestInfo?.data && requestInfo?.data?.PaymentStatus !== 'PLAN CANCELLED') ? (
                          <CardFront data={requestInfo.data} isMobile={isMobile} />
                        ) : null}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <SoftBox pl={isMobile ? 0 : 5} style={isMobile ? {} : style}>
                        {(requestInfo && requestInfo?.data && requestInfo?.data?.PaymentStatus !== 'PLAN CANCELLED') ? <RequestForm
                          setSuccess={setIsSuccess}
                          setEmail={setEmail}
                          data={requestInfo.data}
                          isMobile={isMobile}
                        /> : (requestInfo?.data?.PaymentStatus === 'PLAN CANCELLED') ? <SoftTypography variant="h5" fontWeight="medium">
                          Access Denied: This feature is available to subscribers only
                        </SoftTypography> : null }
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            )}
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </SoftBox>
  );
};

export default RecordRequest;
