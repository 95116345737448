import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { Card, Grid } from "@mui/material"

import SoftBox from "components/SoftBox"
import SoftTypography from "components/SoftTypography"
import { LostYourCard } from './Lostyourcard'
import { CardFront } from './CardFront'
import { CardPrint } from 'layouts/pages/wallet/components/GenerateCard/CardPrint'
import SoftButton from 'components/SoftButton'
import Download from 'examples/Icons/Download'
import Print from 'examples/Icons/Print'

import "./style.css"
import { whitespace } from 'stylis'

const MyIdCard = () => {
  const [QROpen, setQROpen] = useState(false)
  const componentToPrint = useRef(null)
  const { userInfo: { data }, QRCode } = useSelector(state => state)

  return (
    <SoftBox mt={4}>
      <Card sx={{ borderRadius: "16px" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
        <SoftBox py={3} px={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
              <SoftTypography mb={3} style={{ color: '#0C2340', fontWeight: "700" }}>My Medi ID Card</SoftTypography>
              <SoftBox>
                <ReactToPrint
                  trigger={() => <SoftButton
                    variant="outlined"
                    color="dark"
                    type="button"
                    size="small"
                    circular={true}
                    onClick={() => setQROpen(true)}
                    fullWidth
                    style={{
                      textTransform: 'initial',
                      fontSize: 16, fontWeight: 500,
                      height: '37px', radius: '50px', padding: '0px, 16px, 0px, 16px',
                      marginBottom: 8,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Download size={16} />
                    <SoftBox ml={1}>Save as PDF</SoftBox>
                  </SoftButton>}
                  content={() => componentToPrint.current}
                />
                <ReactToPrint
                  trigger={() => <SoftButton
                    variant="outlined"
                    color="dark"
                    type="button"
                    onClick={() => setQROpen(true)}
                    size="small"
                    circular={true}
                    fullWidth
                    style={{
                      textTransform: 'capitalize',
                      fontSize: 16, fontWeight: 500,
                      height: '37px',
                      radius: '50px', padding: '0px, 16px, 0px, 16px',
                      marginBottom: 8
                    }}
                  >
                    <Print size={16} />
                    <SoftBox ml={1}>Print</SoftBox>
                  </SoftButton>}
                  content={() => componentToPrint.current}
                />
                {/* <LostYourCard /> */}
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={7}>
              <CardFront data={data} />
              <div className='card-print'>
                {QRCode.data ? <CardPrint componentToPrint={componentToPrint} data={data} /> : null}
              </div>
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </SoftBox>
  )
}

export default MyIdCard