import { useState, useEffect } from "react";
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// NewUser page components
import Checkbox from "@mui/material/Checkbox";
import FormField from "../FormField";
import circle from "../../Icons/icon.svg";
import { FormControlLabel } from "@mui/material";
import { Field } from "formik";

function UserForm({ formData, isHide, role, userRole }) {
  const [isPass1, setIsPass1] = useState(false);
  const [isPass2, setIsPass2] = useState(false);

  const { formField, values, errors, touched, setFieldValue } = formData;
  const { firstName, lastName, email, password, repeatPassword, phone } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    password: passwordV,
    phone: phoneV,
    repeatPassword: repeatPasswordV,
    agree: agreeV,
  } = values;

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          Create Account
        </SoftTypography>
        <SoftBox mb={1.5}>
          <SoftTypography
            variant="caption"
            fontWeight="light"
            fontSize={14}
            style={{ color: "#94A3B8" }}
          >
            Enter {"Admin's"} contact information below.
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftTypography
            variant="caption"
            fontWeight="regular"
            fontStyle="italic"
            fontSize={10.5}
            display="block"
          >
            (All fields are required unless specified optional)
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={2.8}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormField
              fieldType="phone"
              id={phone.name}
              helper="US Number Only"
              type={phone.type}
              name={phone.name}
              setFieldValue={setFieldValue}
              label={phone.label}
              placeholder={phone.placeholder}
              error={errors.phone && touched.phone}
              success={values.phone.length > 0 && !errors.phone}
            />
          </Grid> */}
        </Grid>
        <Grid container>
            <Grid item xs={isHide ? 6 : 7} sm={isHide ? 6 : 7}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={isPass1 ? "text" : "password"}
                    label={password.label}
                    name={password.name}
                    value={passwordV}
                    placeholder={password.placeholder}
                    error={errors.password && touched.password}
                    success={passwordV.length > 0 && !errors.password}
                    inputProps={{ autoComplete: "" }}
                    icon={{
                      component: isPass1 ? "visibility_off" : "visibility",
                      direction: "right",
                    }}
                    iconClickHandler={() => setIsPass1(!isPass1)}
                  />
                  {/* {progressValue > 0 ? <>
                    <SoftProgress
                      value={progressValue}
                      variant="gradient"
                      color={progressValue <= 30 ? "warning" : progressValue <= 80 ? "info" : progressValue <= 100 ? "success" : "primary"}
                    />
                    <SoftTypography
                      variant="caption"
                      fontWeight="light"
                      color={progressValue <= 30 ? "warning" : progressValue <= 80 ? "info" : progressValue <= 100 ? "success" : "primary"}>
                      {progressValue <= 30 ? "Week" : progressValue <= 80 ? "Good" : progressValue <= 100 ? "Strong" : ""}
                    </SoftTypography>
                  </> : null} */}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    type={isPass2 ? "text" : "password"}
                    label={repeatPassword.label}
                    name={repeatPassword.name}
                    value={repeatPasswordV}
                    placeholder={repeatPassword.placeholder}
                    error={errors.repeatPassword && touched.repeatPassword}
                    success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                    inputProps={{ autoComplete: "" }}
                    icon={{
                      component: isPass2 ? "visibility_off" : "visibility",
                      direction: "right",
                    }}
                    iconClickHandler={() => setIsPass2(!isPass2)}
                  />
                </Grid>
                {!isHide ? (
                  <Grid>
                    <Field
                      type="checkbox"
                      name="agree"
                      value={agreeV}
                      style={{ display: "flex", marginLeft: 0, marginRight: 0 }}
                      as={FormControlLabel}
                      control={<Checkbox />}
                      checked={values.agree}
                      label={
                        <SoftTypography
                          fontSize={12}
                          variant="caption"
                          fontWeight="regular"
                          // onClick={handleSetAgreement}
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                            marginLeft: 0,
                            marginRight: 0,
                          }}
                        >
                          I agree the&nbsp;
                          <SoftTypography
                            fontSize={12}
                            variant="caption"
                            color="info"
                            fontWeight="regular"
                            style={{ textDecoration: "underline" }}
                          >
                            Terms and Conditions
                          </SoftTypography>
                          &nbsp;and&nbsp;
                          <SoftTypography
                            fontSize={12}
                            variant="caption"
                            color="info"
                            fontWeight="regular"
                            style={{ textDecoration: "underline" }}
                          >
                            Privacy Policy
                          </SoftTypography>
                        </SoftTypography>
                      }
                    />
                    {errors.agree ? (
                      <SoftBox mt={0.5} ml={3.7}>
                        <SoftTypography component="div" variant="caption" color="error">
                          Field is required.
                        </SoftTypography>
                      </SoftBox>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={isHide ? 6 : 5} sm={isHide ? 6 : 5}>
              <SoftBox pl={2} py={2.5}>
                <SoftTypography variant="h6" mb={1} fontWeight="bold" color="dark">
                  Password Requirements
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  display="block"
                  fontWeight="regular"
                  mb={2}
                  style={{ color: "#67748e", listStyleType: "disc" }}
                >
                  Please follow this guide for a strong password.
                </SoftTypography>
                <SoftBox
                  style={{ color: "#67748e" }}
                  component="ul"
                  fontSize={12}
                  display="flex"
                  flexDirection="column"
                  p={0}
                  m={0}
                >
                  {/* <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftTypography variant="caption" fontSize={12} mt={0.5} ml={0} style={{ color: '#67748e' }}>
                        A Minimum of:
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox> */}
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography
                        variant="caption"
                        fontSize={12}
                        mt={0.5}
                        ml={1}
                        style={{ color: "#67748e" }}
                      >
                        One lowercase character
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography
                        variant="caption"
                        fontSize={12}
                        mt={0.5}
                        ml={1}
                        style={{ color: "#67748e" }}
                      >
                        One uppercase character
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography
                        variant="caption"
                        fontSize={12}
                        mt={0.5}
                        ml={1}
                        style={{ color: "#67748e" }}
                      >
                        One number
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography
                        variant="caption"
                        fontSize={12}
                        mt={0.5}
                        ml={1}
                        style={{ color: "#67748e" }}
                      >
                        1 special character (ex. !@#$%)
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox component="li" display="flex" flexDirection="column">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox width={7} component="img" src={circle}></SoftBox>
                      <SoftTypography
                        variant="caption"
                        fontSize={12}
                        mt={0.5}
                        ml={1}
                        style={{ color: "#67748e" }}
                      >
                        8 characters minimum
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserForm
UserForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserForm;
