import React from 'react'
import { Link } from "react-router-dom"
import { Grid, Card } from '@mui/material'

import SoftBox from 'components/SoftBox'
import SoftTypography from "components/SoftTypography"
import SoftButton from "components/SoftButton";

import brand from "assets/images/brand-logo.svg"
import sent from "../Recordrequest/icons/sent.svg"
import colorlock from "../Recordrequest/icons/color-lock.svg"

const RequestSent = (props) => {
    const { email } = props
    return (
        <SoftBox>
            <Grid container item display="flex" justifyContent="center">
                <Grid item xs={6}>
                    <Card style={{ background: '#FFF', boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                        <SoftBox p={6}>
                            <Grid mb={6}>
                                <SoftBox display="flex" justifyContent="center">
                                    <SoftBox style={{ width: 190 }} px={1} component="img" src={brand} />
                                </SoftBox>
                            </Grid>
                            <Grid mb={2}>
                                <SoftBox display="flex" justifyContent="center" alignItems="center">
                                    <SoftBox px={1} mb={0.5} component="img" src={colorlock} />
                                    <SoftTypography style={{ color: "#05CD99" }} textAlign="center" variant="h5" fontWeight="bold">
                                        Records Request Sent
                                    </SoftTypography>
                                </SoftBox>
                            </Grid>
                            <SoftTypography textAlign="center" fontWeight="regular" fontSize={14} lineHeight={1.5}>
                                Verification Successful! Please check your email for the details.
                            </SoftTypography>
                            <Grid xs={12} mt={4} style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "grid"}}>
                            <SoftBox px={1} component="img" src={sent} />
                        </Grid>
                        <Grid mt={4} textAlign="center">
                            <Link to={'/login'}>
                                <SoftButton fullWidth variant="outlined" color="info" size="large" circular
                                    style={{ padding: '0px 30px 0px 30px', textTransform: 'capitalize' }}>
                                    <SoftTypography textGradient={true} color="info" fontSize={16} fontWeight="500">Done</SoftTypography>
                                </SoftButton>
                            </Link>
                        </Grid>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
        </SoftBox >
    )
}

export default RequestSent
