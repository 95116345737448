import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import Management from "./component/Manage";

const Usermanagement = () => {
  return (
    <SoftBox>
      <Grid container justifyContent="center" sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <Management />
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default Usermanagement;
