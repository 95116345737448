import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import Card from "@mui/material/Card";

//  components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import SoftAlert from "components/SoftAlert";
import Socials from "layouts/authentication/components/Socials";
import validations from "../components/schemas/validations";

import initialValues from "../components/schemas/initialValues";
import form from "../components/schemas/form";
import ForgotForm from "../components/Reset";
import SoftButton from "components/SoftButton";
import checkIcon from "assets/images/checkIcon.svg";
// import SoftSnackbar from "components/SoftSnackbar";
import { request } from "redux/actions/request";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";
// import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";

//  page layout routes

function ResetPassword() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentValidation = validations[0];
  const { formId, formField } = form;

  useEffect(() => {
    if (localStorage.getItem("authToken") !== null) {
      navigate("/wallet");
    }
  }, []);

  const submitForm = (values) => {
    setLoading(true);
    let code = searchParams.get("code");
    let email = searchParams.get("EmailId");
    if (code && email) {
      const payload = {
        Email: email,
        Code: code,
        Password: values.password,
        Username: email,
      };
      request("Users/ChangePassword", "POST", payload)
        .then(() => {
          setLoading(false);
          setEmail(email);
          setOpen(false);
        })
        .catch((err) => {
          setOpen(true);
          setLoading(false);
          setError(err);
        });
    }
  };

  function forgot(formData) {
    return <ForgotForm formData={formData} />;
  }

  return (
    <SoftBox>
      <SoftBox style={{ minHeight: "calc(100vh - 50px)" }}>
        <BasicLayout>
          <AlertPop isDanger={true} toggle={setOpen} isOpen={open} heading="Expired Link">
            <>
              You can reset your password through the{" "}
              <Link to="/forgot-password" style={{ textDecoration: "underline" }}>
                Forgot Password
              </Link>{" "}
              link on the Sign In Page
            </>
          </AlertPop>
          <SoftBox mt={7} mb={10}>
            <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2)" }}>
              <SoftBox p={2.5}>
                <SoftBox mb={1}>
                  <Socials />
                </SoftBox>
                <SoftBox p={3} pb={2}>
                  {email !== "" && (
                    <SoftBox style={{ textAlign: "center" }} mt={2.5}>
                      <SoftTypography variant="h5" fontWeight="bold" mb={1}>
                        Password Changed
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        fontWeight="regular"
                        fontSize={14}
                        display="block"
                      >
                        Your password has successfully been changed.
                      </SoftTypography>
                      <SoftBox p={0} textAlign="center" mt={9}>
                        <Link to="/login">
                          <SoftButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            type="submit"
                            disabled={loading}
                            circular={true}
                            size="large"
                            style={{ textTransform: "capitalize", fontSize: 16, fontWeight: 500 }}
                          >
                            Return to Sign In
                          </SoftButton>
                        </Link>
                      </SoftBox>
                    </SoftBox>
                  )}
                  {email === "" && (
                    <>
                      <SoftBox style={{ textAlign: "center" }} mt={2.5} mb={3}>
                        <SoftTypography variant="h5" fontWeight="bold" mb={0.5}>
                          Reset Password
                        </SoftTypography>
                        <SoftTypography
                          style={{ textAlign: "center" }}
                          variant="caption"
                          fontWeight="regular"
                          fontSize={14}
                          display="block"
                        >
                          Please enter and confirm your new password (both Required).
                        </SoftTypography>
                      </SoftBox>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={currentValidation}
                        onSubmit={submitForm}
                      >
                        {({ values, errors, touched }) => (
                          <Form id={formId} autoComplete="off">
                            <SoftBox>
                              {forgot({ values, touched, formField, errors, submitForm })}

                              <SoftBox mt={4} mb={1}>
                                <SoftButton
                                  variant="gradient"
                                  color="info"
                                  fullWidth
                                  type="submit"
                                  disabled={loading}
                                  circular={true}
                                  size="large"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                >
                                  {!loading && "Submit"}
                                  {loading && <CircularProgress size={18} color="white" />}
                                </SoftButton>
                              </SoftBox>
                            </SoftBox>
                          </Form>
                        )}
                      </Formik>
                    </>
                  )}
                </SoftBox>
              </SoftBox>
            </Card>
          </SoftBox>
        </BasicLayout>
      </SoftBox>
      <Footer />
    </SoftBox>
  );
}

export default ResetPassword;
