import SoftBox from 'components/SoftBox'
import Accordion from './Accordion'

const UpdateAccount = (props) => {
  return (

    <SoftBox>
      <Accordion {...props} />
    </SoftBox>
  )
}

export default UpdateAccount
