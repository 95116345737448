import React, { useRef } from 'react'
import ReactDOMServer from "react-dom/server";

import { Grid } from "@mui/material";
import ReactToPrint from "react-to-print"
import jsPDF from "jspdf";

// ** Components
import SoftBox from 'components/SoftBox'
import SoftButton from "components/SoftButton";
import Download from "examples/Icons/Download";
import Print from "examples/Icons/Print";
import SoftTypography from 'components/SoftTypography';
import { CardPrint } from './CardPrint';
import { useSelector } from 'react-redux';

export const MediCard = () => {
    const componentToPrint = useRef(null);
    const { userInfo: { data } } = useSelector(state => state)

    const exportPDF = () => {
        let element = (
            <CardPrint componentToPrint={componentToPrint} data={data} />
        );
        const doc = new jsPDF("p", "pt", "letter");
        doc.html(ReactDOMServer.renderToString(element), {
            callback: function (doc) {
                doc.save('sample.pdf');
            }
        });
    };

    return (
        <SoftBox>
            <Grid container spacing={2} justifyContent="center">
                <Grid item sm={6} xs={12}>
                    <SoftBox mb={1}>
                        <ReactToPrint
                            trigger={() => (
                                <SoftButton
                                    variant="outlined"
                                    color="dark"
                                    type="button"
                                    size="small"
                                    circular={true}
                                    fullWidth
                                    style={{ textTransform: 'initial', fontSize: 16, fontWeight: 500 }}
                                >
                                    <Download size={16} />
                                    <SoftBox ml={1}>Save as PDF</SoftBox>
                                </SoftButton>
                            )}
                            content={() => componentToPrint.current}
                        />
                    </SoftBox>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <SoftBox mb={1}>
                        <ReactToPrint
                            trigger={() => (
                                <SoftButton
                                    variant="outlined"
                                    color="dark"
                                    type="button"
                                    size="small"
                                    circular={true}
                                    fullWidth
                                    style={{ textTransform: 'capitalize', fontSize: 16, fontWeight: 500 }}
                                >
                                    <Print size={16} />
                                    <SoftBox ml={1}>Print</SoftBox>
                                </SoftButton>
                            )}
                            content={() => componentToPrint.current}
                        />
                    </SoftBox>
                </Grid>
            </Grid>
            <SoftBox p={5}>
                <SoftTypography variant="h6" fontWeight="bold" color="dark" mb={2.5}>
                    Preview of your card
                </SoftTypography>
                <CardPrint componentToPrint={componentToPrint} data={data} />
            </SoftBox>
        </SoftBox >
    )
}
