import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import SoftDropzone from "components/SoftDropzone";
import ReusableDialog from "examples/ReusableDialog";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import close from "./Icons/close.svg";
import { bytesToMegaBytes } from "utils/constant";
import { fileNameWithExtension } from "utils/constant";
import { DesignatedContact } from "./DesignatedContact";
import initialValues from "../../schemas/initialValues";
import validations from "../../schemas/validations";
import { CircularProgress, Grid } from "@mui/material";
import form from "../../schemas/form";
import { request } from "redux/actions/request";
import { fetchDocumentTypeList } from "redux/actions/wallet/documentTypeAction";
import { fetchDocumentList } from "redux/actions/wallet/documentAction";
import { fetchUserInformation } from "redux/actions/users";
import { fetchDesignatedContact } from "redux/actions/profile/profileAction";

export const DragDrop = ({ open, setOpen, setAlert, setVisible }) => {
  // useState
  const [documentOtherName, setDocumentOtherName] = useState(null);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const dispatch = useDispatch();
  const [groupOptions, setGroupOptions] = useState([]);
  const [disabledDocumentType, setDisabledDocumentType] = useState(true);
  // const [disabledAdd, setDisabledAdd] = useState(true)
  const [fileBase64, setFileBase64] = useState(null);
  const [fileDetail, setFileDetail] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [disabledUpload, setDisabledUpload] = useState(true);
  const [fileSize, setFileSize] = useState(null);
  const [designateContact, setDesignateContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [emergencyContact, setEmergencyContact] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isError, setIsError] = useState(false);

  const { documentTypeList, userInfo, designatedContact } = useSelector((state) => state);

  // Document List
  useEffect(() => {
    if (!documentTypeList.data) fetchDocumentType();
    else {
      let sorted = documentTypeList.data.map((x) => ({
        label: x.DocumentType_Name,
        value: x.DocummentType_Id,
      }));
      setDocumentOptions(sorted);
    }
  }, [documentTypeList.data]);

  // UserInfo
  useEffect(() => {
    if (!userInfo.data || !designatedContact.data) fetchUserInfo();
    if (userInfo.data && userInfo.data.EmergencyContact && designatedContact.data) {
      // debugger
      let {
        data: {
          EmergencyContact: { EmergencyContact_FirstName, EmergencyContact_LastName },
        },
      } = userInfo;
      let { data } = designatedContact;
      let designate = [];
      let emergency = [
        { label: EmergencyContact_FirstName + " " + EmergencyContact_LastName, value: "emergency" },
      ];
      if (data.length) {
        designate = data.map((x) => ({
          label: x.Contact_FirstName + " " + x.Contact_LastName,
          value: x.ContactId,
        }));
      }
      setGroupOptions([
        { label: "New Contact", options: [{ label: "Add New", value: "new" }] },
        { label: "Previously Entered Designated Contacts", options: designate },
        { label: "Emergency", options: emergency },
      ]);
    }
  }, [userInfo.data, designatedContact.data]);

  // Fetch UserInfo
  async function fetchUserInfo() {
    if (userInfo.data === null) {
      let email = localStorage.getItem("Email");
      const result = await dispatch(fetchUserInformation(email));
      await fetchDesignateContact(result.Aspnet_UserId);
    } else await fetchDesignateContact(userInfo.data.Aspnet_UserId);
  }

  // Fetch Designated Contact
  async function fetchDesignateContact(userId) {
    return await dispatch(fetchDesignatedContact(userId, "DocumentContact"));
  }

  // fetch document list
  async function fetchDocumentType() {
    return await dispatch(fetchDocumentTypeList());
  }

  // fetch document list
  async function fetchDocuments(count) {
    return await dispatch(fetchDocumentList(count));
  }

  function onChangeDocumentType(e) {
    if (!e.target.value) {
        setIsTouched(true);
        setIsError(true);
    }
    else {
        setIsTouched(false);
        setIsError(false);
    }
    setDocumentOtherName(e.target.value);
  }

  function uploadDocument(e) {
    console.log("uploading... ", e);
  }

  const currentValidation = validations[0];
  const { formField } = form;

  const handleSubmit = async (values, actions) => {
    if (documentType && documentType.label === "Other") {
      if (documentOtherName === "" || documentOtherName === null) {
        setIsError(true);
        return;
      }
    }
    setLoading(true);
    let payload = {
      DocumentStorage: {
        DocumentFiles: [
          {
            // DocumentFileName: fileName.split('.').slice(0, -1).join('.'),
            DocumentFileName: fileName,
            DocumentFileContent: fileBase64.split("base64,").pop(), // FileBase64
            DocumentFileExtension: fileBase64.split("base64,")[0] + "base64",
            DocumentFileSize: fileDetail.size,
            DocumentFileMetaData: {
              Sharable: "False",
              DocumentType: documentType.label,
            },
          },
        ],
      },
    };
    if (newContact) {
      payload.UserDocumentContact = {
        Contact_LastName: values.lastName,
        Contact_FirstName: values.firstName,
        Contact_Email: values.email,
        Contact_Phone: values.phone.toString(),
        Contact_Street1: values.address1,
        Contact_Street2: values.address2,
        Contact_City: values.city,
        Contact_State: values.state.value,
        Contact_PostalCode: values.zip.toString(),
        Contact_Type: "DocumentContact",
        DocumentTypeName: documentType.label,
      };
    } else if (emergencyContact) {
      let {
        data: { EmergencyContact },
      } = userInfo;
      payload.UserDocumentContact = {
        ContactId: EmergencyContact.EmergencyContact_ID,
        Contact_LastName: EmergencyContact.EmergencyContact_LastName,
        Contact_FirstName: EmergencyContact.EmergencyContact_FirstName,
        Contact_Email: EmergencyContact.EmergencyContact_Email,
        Contact_Phone: EmergencyContact.EmergencyContact_Phone,
        Contact_Street1: EmergencyContact.EmergencyContact_Street1,
        Contact_Street2: EmergencyContact.EmergencyContact_Street2,
        Contact_City: EmergencyContact.EmergencyContact_City,
        Contact_State: EmergencyContact.EmergencyContact_State,
        Contact_PostalCode: EmergencyContact.EmergencyContact_PostalCode,
        Contact_Type: "DocumentContact",
        DocumentTypeName: documentType.label,
      };
    } else {
      let filtered = designatedContact.data.filter((x) => x.ContactId === designateContact.value);
      if (filtered.length) {
        payload.UserDocumentContact = {
          ContactId: filtered[0].ContactId,
          Contact_LastName: filtered[0].Contact_LastName,
          Contact_FirstName: filtered[0].Contact_FirstName,
          Contact_Email: filtered[0].Contact_Email,
          Contact_Phone: filtered[0].Contact_Phone,
          Contact_Street1: filtered[0].Contact_Street1,
          Contact_Street2: filtered[0].Contact_Street2,
          Contact_City: filtered[0].Contact_City,
          Contact_State: filtered[0].Contact_State,
          Contact_PostalCode: filtered[0].Contact_PostalCode,
          Contact_Type: "DocumentContact",
          DocumentTypeName: documentType.label,
        };
      }
    }
    if (documentType.label === "Other")
      payload.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName =
        documentOtherName;

    request("Document/Documents", "POST", payload)
      .then(async () => {
        await fetchDocuments(100);
        // console.log(result)
        setLoading(false);
        setVisible(true);
        setOpen(false);
        setAlert({
          isDanger: false,
          timer: 5000,
          heading: "Document Uploaded Successfully",
          text: "Your document has been uploaded successfully.",
        });
        fetchDesignateContact(userInfo.data.Aspnet_UserId);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err)
        setVisible(true);
        setAlert({
          isDanger: true,
          timer: null,
          heading: "Error",
          text: err,
        });
      });
  };

  function onChangeDesignateContact(value) {
    setDesignateContact(value);
    if (documentType !== null) setDisabledUpload(false);
    switch (true) {
      case value.value === "emergency":
        setNewContact(false);
        setEmergencyContact(true);
        setIsOverflow(false);
        return;

      case value.value === "new":
        setEmergencyContact(false);
        setNewContact(true);
        setIsOverflow(true);
        return;

      default:
        setEmergencyContact(false);
        setIsOverflow(false);
        setNewContact(false);
        return;
    }
  }

  const options = {
    paramName: "file", // The name that will be used to transfer the file
    maxFilesize: 35, // MB
    uploadMultiple: false,
    acceptedFiles: "image/*,.pdf,.docx,.xlsx,.doc,application/vnd.ms-excel",
    accept: async function (file, done) {
      getBase64(file)
        .then((result) => setFileBase64(result))
        .catch((err) => alert("wrong file format", err));
      // console.log(file)
      setFileDetail(file);
      let size = bytesToMegaBytes(file.size);
      let name = fileNameWithExtension(file.upload.filename);
      setFileName(name);
      setFileSize(size);
      // let fileBase64 = await toBase64(file)
      // setFileBase64(fileBase64)
      setDisabledDocumentType(false);
      return false;
    },
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <>
      {/* <AlertPop
                isDanger={alert.isDanger}
                toggle={setVisible}
                isOpen={visible}
                timer={alert.timer}
                heading={alert.heading}>
                {alert.text}
            </AlertPop> */}
      <ReusableDialog
        className={!isOverflow ? "is-overflow" : ""}
        isOpen={open}
        title="Upload Document"
        handleClose={() => setOpen(false)}
      >
        <SoftBox>
          <SoftBox>
            <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
              Attach Document:{" "}
              <small style={{ color: "#777", fontSize: 10, fontWeight: 400 }}>
                Maximum file size 35 MB (Accepted file types: .pdf, .doc, .jpg, .png)
              </small>
            </SoftTypography>
            {!fileBase64 ? (
              <SoftBox>
              <SoftDropzone onChange={uploadDocument} options={options} />
              <SoftTypography variant="h6" fontWeight="light" color="dark" fontSize={12} mt={1}>
              For security reasons, we recommend PDF files. <br />
                                        Please note that If you upload Word or Excel <span style={{fontWeight: 700}}>(*.doc *.xls )</span> documents, they will not be available for preview. These specified format types will be available to download.
              </SoftTypography>
            </SoftBox>
            ) : (
              <SoftBox
                display="flex"
                justifyContent="space-between"
                style={{
                  width: 360,
                  borderRadius: 100,
                  padding: "9px 16px",
                  background: "#F6F6F6",
                  border: "1px solid #D9D9D9",
                }}
              >
                <SoftBox display="flex">
                  <SoftTypography fontWeight="bold" fontSize={14}>
                    {fileName}
                  </SoftTypography>
                  <SoftTypography ml={1} fontWeight="medium" fontSize={14}>
                    {fileSize}
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  style={{ cursor: "pointer" }}
                  px={1}
                  component="img"
                  src={close}
                  onClick={() => setFileBase64(null)}
                />
              </SoftBox>
            )}
          </SoftBox>
          <SoftBox mt={3}>
            <Grid container justifyContent="center" spacing={1.5} sx={{ height: "100%" }}>
              <Grid item xs={12}>
                <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
                  Document Type
                </SoftTypography>
                <SoftSelect
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  options={documentOptions}
                  value={documentType}
                  isDisabled={disabledDocumentType}
                  // isClearable
                  placeholder="Select Document Types"
                  onChange={(value) => {
                    setDocumentType(value);
                    if (designateContact !== null) setDisabledUpload(false);
                  }}
                />
                {documentType && documentType.label === "Other" ? (
                  <SoftBox mt={1}>
                    <SoftInput
                      name="otherDocumentType"
                      placeholder="Enter Document Type"
                      onChange={onChangeDocumentType}
                      error={isError}
                    />
                  </SoftBox>
                ) : null}
                {isTouched || isError ? (
                  <SoftBox mt={0.5}>
                    <SoftTypography component="div" variant="caption" color="error">
                      Field is required.
                    </SoftTypography>
                  </SoftBox>
                ) : null}
              </Grid>
              <Grid item xs={12} mt={1}>
                <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>
                  Designated Contact
                </SoftTypography>
                <SoftSelect
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  options={groupOptions}
                  value={designateContact}
                  isDisabled={disabledDocumentType}
                  // isClearable
                  placeholder="Search or Select from previously entered contacts"
                  onChange={onChangeDesignateContact}
                />
              </Grid>
            </Grid>
            {newContact ? (
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue, setErrors }) => (
                  <Form id="emergencyInfo" autoComplete="off">
                    <DesignatedContact
                      formData={{ values, errors, touched, setFieldValue, setErrors, formField }}
                    />
                    <SoftButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      circular={true}
                      size="medium"
                      disabled={loading}
                      fullWidth
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 500,
                        marginTop: 10,
                        fontSize: 14,
                      }}
                    >
                      {loading && <CircularProgress size={14} color="white" />}
                      {!loading && "Upload Document"}
                    </SoftButton>
                  </Form>
                )}
              </Formik>
            ) : null}
            {!newContact ? (
              <SoftBox width="100%" display="flex" justifyContent="end" mt={2.5}>
                <SoftButton
                  variant="text"
                  circular={true}
                  color="dark"
                  size="medium"
                  onClick={() => setOpen(false)}
                  style={{
                    marginRight: 10,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    marginTop: 5,
                  }}
                >
                  back
                </SoftButton>
                <SoftButton
                  type="button"
                  variant="gradient"
                  color="info"
                  circular={true}
                  onClick={handleSubmit}
                  size="medium"
                  disabled={loading}
                  style={{
                    textTransform: "capitalize",
                    fontWeight: 500,
                    background: disabledUpload ? "#B1B1B1" : null,
                    pointerEvents: disabledUpload ? "none" : "auto",
                  }}
                >
                  {loading && <CircularProgress size={14} color="white" />}
                  {!loading && "Upload Document"}
                </SoftButton>
              </SoftBox>
            ) : null}
          </SoftBox>
          {/* <SoftBox mt={2}>
                        <SoftTypography variant="h6" fontWeight="bold" color="dark" fontSize={14}>Document Type</SoftTypography>
                        <SoftSelect
                            name="documentType"
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            options={documentOptions}
                            value={document}
                            isDisabled={disabledDocumentType}
                            // isClearable
                            placeholder="Select Document Types"
                            onChange={onChangeDocument} />
                        {document && document.label === 'Other' ? <SoftBox mt={1}>
                            <SoftInput
                                name="otherDocumentType"
                                placeholder="Enter Document Type"
                                onChange={onChangeDocumentType} />
                        </SoftBox> : null}
                        {!disabledUpload ? <Formik
                            initialValues={initialValues}
                            validationSchema={currentValidation}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, setFieldValue, setErrors }) => (
                                <Form id="emergencyInfo" autoComplete="off">
                                    <DesignatedContact formData={{ values, errors, touched, setFieldValue, setErrors, formField, setIsPrefilled }} />
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        circular={true}
                                        disabled={loading}
                                        size='medium'
                                        fullWidth
                                        style={{ textTransform: 'capitalize', fontWeight: 500, marginTop: 10, fontSize: 14 }}
                                    >
                                        {loading && <CircularProgress size={14} color="white" />}
                                        {!loading && "Upload Document"}
                                    </SoftButton>
                                </Form>
                            )}
                        </Formik> : null}
                        {disabledUpload ? <>
                            <SoftBox mt={2}>
                                <SoftButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    circular={true}
                                    onClick={() => {
                                        setIsOverflow(true)
                                        setDisabledUpload(false)
                                    }}
                                    size='medium'
                                    style={{
                                        background: disabledAdd ? '#B1B1B1' : null,
                                        textTransform: 'capitalize',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        pointerEvents: disabledAdd ? 'none' : 'auto',
                                    }}
                                >
                                    <SoftBox mr={1.5}><PlusIcon size={14} /></SoftBox> {"Add Contact"}
                                </SoftButton>
                            </SoftBox>
                            <SoftBox mt={2}>
                                <SoftButton
                                    variant="gradient"
                                    color="info"
                                    fullWidth
                                    type="submit"
                                    circular={true}
                                    size='medium'
                                    style={{
                                        background: disabledUpload ? '#B1B1B1' : null,
                                        textTransform: 'capitalize',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        pointerEvents: disabledUpload ? 'none' : 'auto',
                                    }}
                                >Upload Document</SoftButton>
                            </SoftBox>
                        </> : null}
                    </SoftBox> */}
        </SoftBox>
      </ReusableDialog>
    </>
  );
};
