import React from "react";
import { Card } from "@mui/material";

import MediIDCardBG from "assets/images/medi-id-card-bg.svg";
import brandLandScape from "assets/images/brand-logo-landscape.svg";
import { formatPhoneNumber } from "utils/constant";
import { QRCode } from "./QRCode";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

export const CardFront = (props) => {
  const { data, isMobile } = props;

  return (
    <SoftBox mt={isMobile ? 2 : 10}>
      <Card
        p={0}
        style={{
          border: "6px solid #4DC7E5",
          boxShadow: "none",
          backgroundImage: `url(${MediIDCardBG})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left bottom",
          backgroundSize: '82%'
          // background: `linear-gradient(90deg, #4DC7E6 0%, #293E8F 100%)`
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            padding: "15px 20px",
          }}
        >
          <div>
            <div style={{ marginBottom: 5 }}>
              <img src={brandLandScape} alt="MediDocProBrand" style={{ width: 100 }} />
            </div>
            <div style={{ color: "#344767", fontSize: 8.5, fontWeight: 300 }}>Member Name</div>
            <div
              style={{
                display: "flex",
                height: 95,
                flexDirection: "column",
                alignItems: "self-start",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: 15,
                    color: "#344767",
                    textTransform: "capitalize",
                  }}
                >
                  {data?.FirstName} {data?.LastName}
                </h4>
                <h6 style={{ fontWeight: 700, color: "#344767", fontSize: 9 }}>
                  {data ? data?.Phonenumber : null}
                </h6>
              </div>
              <div>
                <div style={{ color: "#344767", fontSize: 8.5, fontWeight: 300, marginTop: 15 }}>
                  Emergency Contact
                </div>
                <div>
                  <h6
                    style={{
                      color: "#344767",
                      fontWeight: 700,
                      textTransform: "capitalize",
                      marginRight: 10,
                      fontSize: 9,
                    }}
                  >
                    {data?.EmergencyContactFullName}
                  </h6>
                  <h6 style={{ color: "#344767", fontWeight: 700, fontSize: 9, marginTop: 2 }}>
                    {data
                      ? data?.EmergencyContactPhoneNumber
                      : null}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: 90 }}>{data ? <QRCode text="Scan for Profile" data={data} /> : null}</div>
        </div>
      </Card>
      <SoftTypography
        mt={2}
        style={{ color: "#05CD99", fontSize: 15 }}
        textAlign="center"
        fontStyle="italic"
        variant="h6"
        fontWeight="bold"
      >
        Thanks for scanning!
      </SoftTypography>
      <SoftTypography textAlign="center" fontStyle="italic" fontWeight="light" fontSize={12} lineHeight={1.5}>
        We’ve found your customer.
      </SoftTypography>
    </SoftBox>
  );
};
