import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React from "react";

const ColumnHeader = (props) => {
  const { heading, caption } = props;
  return (
    <SoftBox>
      <SoftTypography variant="contained">
        {heading} <br />
      </SoftTypography>
      <SoftTypography variant="caption" fontSize={11}>{caption}</SoftTypography>
    </SoftBox>
  );
};

export default ColumnHeader;
