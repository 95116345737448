import { useState } from "react";
import SoftBox from "components/SoftBox";
import { Card } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { DragDrop } from "./DragDrop";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert"

export const UploadInfo = () => {
    const [open, setOpen] = useState(false)
    const [visible, setVisible] = useState(false)
    const [alert, setAlert] = useState({
        heading: "",
        text: "",
        isError: false
    })
    return (
        <SoftBox>
            {visible ? <AlertPop
                isDanger={alert.isError}
                toggle={setVisible}
                timer={7000}
                isOpen={visible}
                heading={alert.heading}>
                {alert.text}
            </AlertPop> : null}
            <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                <SoftBox p={4}>
                    <SoftBox lineHeight={1}>
                        <SoftTypography variant="h5" fontWeight="bold" color="info" textGradient={true}>
                            Upload Documents
                        </SoftTypography>
                        <SoftBox mb={3.5} mt={0.5}>
                            <SoftTypography variant="caption" fontWeight="regular" fontSize={14}>
                                Get your wallet ready to share docs right away by uploading any of the types listed below, and naming the designated contact person for each.
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                            <SoftTypography variant="h6" fontWeight="bold">
                                Types of Document:
                            </SoftTypography>
                        </SoftBox>
                        <SoftTypography
                            mt={0.5}
                            mb={5}
                            display="block"
                            variant="caption"
                            fontWeight="regular"
                            fontSize={14}
                        >
                            <ul style={{ paddingLeft: 22, lineHeight: 1.7 }}>
                                <li>Health Care Surrogate</li>
                                <li>Power of Attorney</li>
                                <li>Do Not Resuscitate</li>
                                <li>Identification Documents</li>
                                <li>Vaccination Cards</li>
                                <li>Medical History</li>
                                <li>Other</li>
                            </ul>
                        </SoftTypography>
                        <SoftButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            type="button"
                            circular={true}
                            onClick={() => setOpen(true)}
                            size='medium'
                            style={{ textTransform: 'capitalize', fontSize: 16, fontWeight: 500 }}
                        >Add Documents</SoftButton>
                    </SoftBox>
                </SoftBox>
                {open ? <DragDrop open={open} setOpen={setOpen} setAlert={setAlert} setVisible={setVisible} /> : null}
            </Card>
        </SoftBox>
    )
}
