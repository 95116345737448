import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SoftTypography from "components/SoftTypography"

function AccountTab(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0.5 }}>
                    <SoftTypography>{children}</SoftTypography>
                </Box>
            )}
        </div>
    );
}

AccountTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default AccountTab