import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Footer from "layouts/authentication/components/Footer";

// NewUser page components
import Socials from "layouts/authentication/components/Socials";

// NewUser layout schemas for form and form fields
import CreateInfo from "./components/CreateInfo";
import GenerateCard from "./components/GenerateCard";
import UploadDocuments from "./components/UploadDocuments";
import { Welcome } from "./Welcome"
import ShareDocuments from "./components/ShareDocuments";
import { fetchUserInformation } from "redux/actions/users";
import SoftTypography from "components/SoftTypography";
import { request } from "redux/actions/request";

import "./stepper.css"
import ViewProfile from "./ViewProfile";

function getSteps() {
  return ["1. Contact Info", "2. Generate Card", "3. Upload Documents", "4. Share Documents"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <CreateInfo formData={formData} />;
    case 1:
      return <GenerateCard formData={formData} />;
    case 2:
      return <UploadDocuments formData={formData} />;
    case 3:
      return <ShareDocuments formData={formData} />;
    case 4:
      return <ViewProfile />
    default:
      return null;
  }
}

function Wallet() {
  const navigate = useNavigate()
  const [getStart, setGetStart] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isSubmit, setIsSubmit] = useState(false)

  const steps = getSteps()
  const isLastStep = activeStep === steps.length - 1;

  const { userInfo: { data } } = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem("authToken") === null) navigate("/login")
    else if (data === null) fetchUserInfo()
  }, [])

  const fetchUserInfo = async () => {
    let email = localStorage.getItem("Email")
    await dispatch(fetchUserInformation(email))
  }

  const submitForm = async (values, actions) => {
    setIsSubmit(true)
      request('Users/PostExternalUser', 'POST', values)
      .then(async (result) => {
      })
      .catch(error => {
      })
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      const payload = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Gender: null,
        SMSCapableNumber: values.phone.toString(),
        Street1: values.address1,
        Street2: values.address2,
        City: values.city,
        State: values.state.value,
        PostalCode: values.zip.toString(),
        EmailAddress: values.email,
        Password: values.password
      }
      submitForm(payload, actions);

    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("FullName");
    localStorage.removeItem("Email");
    navigate("/login");
    // window.location.reload(false);
  };

  return (
    <>
      <SoftBox py={3} mb={20}>
        <SoftBox>
          <SoftButton
            variant="text"
            onClick={handleLogOut}
            style={{
              position: 'absolute',
              right: 15,
              top: 15,
              marginRight: 10,
              padding: 13,
              textTransform: 'capitalize'
            }}>
            <SoftTypography textGradient={true} color="info" fontWeight="medium" fontSize={15}>Sign Out</SoftTypography>
          </SoftButton>
        </SoftBox>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={5}>
            <SoftBox mb={1}>
              <Socials />
            </SoftBox>
          </Grid>
        </Grid>
        {/* Welcome Screen */}
        {getStart ? <>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} lg={5} style={{overflowX:"scroll"}}>
              <Stepper activeStep={activeStep} alternativeLabel className="cs-stepper">
                {steps.map((label, index) => (
                  <Step key={label} className={activeStep === index ? "active-wizard" : ""}>
                    <StepLabel>
                      <SoftBox
                        fontWeight={index === activeStep ? 700 : 600}
                        fontSize={14}
                        whiteSpace="nowrap"
                        opacity={index <= activeStep ? 1 : 0.65}
                      >{label}</SoftBox>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
          {getStepContent(activeStep, {
            activeStep,
            setActiveStep
          })}
        </> : <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={5}>
            <Welcome setOpen={setGetStart} data={data} />
          </Grid>
        </Grid>}
      </SoftBox >
      <Footer />
    </>
  );
}

export default Wallet;
