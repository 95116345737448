import SoftTypography from 'components/SoftTypography'
import React from 'react'

export const Emergency = ({ data }) => {
    // debugger
    return (
        <SoftTypography display="block"
            variant="caption"
            fontWeight="light"
            fontSize={14}
            lineHeight={2.5}
        >
            <strong>Name:</strong>&nbsp; {data?.EmergencyContact?.EmergencyContact_FirstName} {data?.EmergencyContact?.EmergencyContact_LastName}<br />
            <strong>Phone Number:</strong>&nbsp;{data?.EmergencyContact ? data?.EmergencyContact?.EmergencyContact_Phone : null}<br />
            <strong>Email Address:</strong>&nbsp;{data?.EmergencyContact?.EmergencyContact_Email}<br />
            <strong>Address:</strong>&nbsp; {data?.EmergencyContact?.EmergencyContact_Street1} {" "}
            {data?.EmergencyContact?.EmergencyContact_Street2} {" "}
            {data?.EmergencyContact?.EmergencyContact_City}, {" "}
            {data?.EmergencyContact?.EmergencyContact_State} {" "}
            {data?.EmergencyContact?.EmergencyContact_PostalCode}
        </SoftTypography>
    )
}
