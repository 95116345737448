import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router";
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReusableDialog(props) {
  const {
    title,
    subtitle,
    children,
    className,
    isOpen,
    maxWidth,
    handleClose,
    handleSubmit,
    loader,
  } = props;
  const responsiveWidth = useMediaQuery("(max-width:1020px)");
  const navigate = useNavigate();

  return (
    <Dialog
      fullWidth
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullScreen={responsiveWidth}
      className={className}
    >
      {title ? (
        <SoftBox as={DialogTitle}>
          <SoftBox px={2} pt={1} pb={0}>
            <SoftTypography fontWeight="bold" color="info" textGradient={true}>
              {title}
            </SoftTypography>
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 15,
                  top: 16,
                  color: "#000000",
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </SoftBox>
        </SoftBox>
      ) : null}
      <SoftBox p={0}>
        {subtitle && (
          <SoftBox p={1} mb={0}>
            <Grid container spacing={1}>
              {responsiveWidth && (
                <>
                  <Grid item>
                    <ArrowBackIosIcon fontSize="xs" />
                    &nbsp;
                    <SoftTypography
                      color="primary"
                      variant="caption"
                      textTransform="uppercase"
                      fontWeight="bold"
                      sx={{ letterSpacing: 2 }}
                    >
                      Back
                    </SoftTypography>
                  </Grid>
                  <Grid item>
                    <Divider absolute={false} orientation="vertical">
                      -
                    </Divider>
                  </Grid>
                </>
              )}
              <Grid item>
                <SoftTypography
                  color="dark"
                  variant="caption"
                  textTransform="uppercase"
                  fontWeight="bold"
                  sx={{ letterSpacing: 2 }}
                >
                  {subtitle}
                </SoftTypography>
              </Grid>
              <Grid item>
                <Divider absolute={false} orientation="vertical">
                  -
                </Divider>
              </Grid>
            </Grid>
          </SoftBox>
        )}
      </SoftBox>
      {/* {subtitle && <Divider></Divider>} */}
      <SoftBox as={DialogContent}>
        <SoftBox mt={1} px={2}>
          {children}
        </SoftBox>
      </SoftBox>
    </Dialog>
  );
}

// Typechecking props for the Footer
ReusableDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  maxWidth: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  loader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ReusableDialog;
