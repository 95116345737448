import { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import { StateList } from "layouts/pages/wallet/components/CreateInfo/StateList";
import FormField from "../FormField";

export const ContactForm = ({ formData, isMobile }) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    fullName,
    lastName,
    email,
    facilityName,
    phone,
    address1,
    company,
    address2,
    city,
    state,
    zip,
    licenseNumber,
  } = formField;
  const {
    fullName: fullNameV,
    lastName: lastNameV,
    email: emailV,
    facilityName: facilityNameV,
    address1: address1V,
    company: companyV,
    address2: address2V,
    city: cityV,
    state: stateV,
    zip: zipV,
    phone: phoneV,
    licenseNumber: licenseNumberV,
  } = values;
  const [selectedStatus, setSelectedStatus] = useState(null);
  // const {
  //   userInfo: { data },
  // } = useSelector((state) => state);

  const statusOptions = StateList;

  async function onChangeStatus(value) {
    setSelectedStatus(value);
    setFieldValue("state", value);
  }

  return (
    <SoftBox>
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            type={fullName.type}
            label={fullName.label}
            name={fullName.name}
            value={fullNameV}
            placeholder={fullName.placeholder}
            error={errors.fullName && touched.fullName}
            success={fullNameV.length > 0 && !errors.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            type={lastName.type}
            label={lastName.label}
            name={lastName.name}
            value={lastNameV}
            placeholder={lastName.placeholder}
            error={errors.lastName && touched.lastName}
            success={lastNameV?.length > 0 && !errors.lastName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} sm={12} md={12}>
          <FormField
            type={licenseNumber.type}
            label={licenseNumber.label}
            name={licenseNumber.name}
            value={licenseNumberV}
            placeholder={licenseNumber.placeholder}
            error={errors.licenseNumber && touched.licenseNumber}
            success={licenseNumberV.length > 0 && !errors.licenseNumber}
          />
        </Grid>
      </Grid>
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            fieldType="phone"
            id={phone.name}
            type={phone.type}
            name={phone.name}
            setFieldValue={setFieldValue}
            label={phone.label}
            value={phoneV.phone}
            placeholder={phone.placeholder}
            error={errors.phone && touched.phone}
            success={values.phone.length > 0 && !errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            type={email.type}
            label={email.label}
            name={email.name}
            value={emailV}
            placeholder={email.placeholder}
            error={errors.email && touched.email}
            success={emailV.length > 0 && !errors.email}
          />
        </Grid>
      </Grid>
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} sm={12} md={12}>
          <FormField
            type={facilityName.type}
            label={facilityName.label}
            name={facilityName.name}
            value={facilityNameV}
            placeholder={facilityName.placeholder}
            error={errors.facilityName && touched.facilityName}
            success={facilityNameV.length > 0 && !errors.facilityName}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            type={address1.type}
            label={address1.label}
            name={address1.name}
            value={address1V}
            placeholder={address1.placeholder}
            error={errors.address1 && touched.address1}
            success={address1V.length > 0 && !errors.address1}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormField
            type={company.type}
            label={company.label}
            name={company.name}
            value={companyV}
            placeholder={company.placeholder}
            error={errors.company && touched.company}
            success={companyV.length > 0 && !errors.company}
          />
        </Grid>
      </Grid> */}
      {/* <Grid container spacing={1}>
        <Grid item xs={12}>
          <SoftBox>
            <FormField
              type={address2.type}
              name={address2.name}
              value={address2V}
              label=""
              placeholder={address2.placeholder}
            />
          </SoftBox>
        </Grid>
      </Grid> */}
      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} sm={12} md={4}>
          <FormField
            type={city.type}
            name={city.name}
            value={cityV}
            label=""
            placeholder={city.placeholder}
            error={errors.city && touched.city}
            success={cityV.length > 0 && !errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormField
            fieldType="select"
            type={state.type}
            label={""}
            name={state.name}
            options={statusOptions}
            defaultValue={selectedStatus}
            value={selectedStatus}
            onChange={onChangeStatus}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            placeholder={state.placeholder}
            error={errors.state && touched.state}
            success={stateV.length > 0 && !errors.state}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormField
            type={zip.type}
            name={zip.name}
            label=""
            value={zipV}
            placeholder={zip.placeholder}
            error={errors.zip && touched.zip}
            success={zipV.length > 0 && !errors.zip}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
};
