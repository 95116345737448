import { useEffect, useState } from "react";
import { Card, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

import DataTable from "examples/Tables/DataTable";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

import eyeIcon from "../../Icons/eye.svg";
import search from "../../Icons/search.svg";
import deleteIcon from "../../Icons/delete.svg";

import DeleteDocument from "./DeleteDocument";
import { DragDrop } from "../Uploads";
import { ViewDocument } from "./ViewDocument";
import ShareDocument from "./ShareDocument";
import UnshareDocument from "./UnshareDocument";

import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";

export default function MyDocument(props) {
  const [uploadOpen, setUploadOpen] = useState(false);
  const [viewDocs, setViewDocs] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [unshareOpen, setUnshareOpen] = useState(false);
  const [document, setDocument] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [alert, setAlert] = useState({
    heading: "",
    text: "",
    isError: false,
  });
  const { data, loading } = props;

  // useEffect(() => {console.log(selectedRow)}, [selectedRow])

  function singleDeleteRecord(document) {
    setSelectedData([document]);
    setDeleteOpen(true);
  }

  function deleteRecord() {
    setSelectedData(selectedRow);
    setDeleteOpen(true);
  }

  function shareDocument(document) {
    if (Object.keys(document).length) {
      setDocumentId(document);
      setShareOpen(true);
    }
  }

  function unshareDocument(document) {
    if (Object.keys(document).length) {
      setDocumentId(document);
      setUnshareOpen(true);
    }
  }

  return (
    <SoftBox mt={4}>
      {visible ? (
        <AlertPop
          isDanger={alert.isError}
          toggle={setVisible}
          timer={7000}
          isOpen={visible}
          heading={alert.heading}
        >
          {alert.text}
        </AlertPop>
      ) : null}
      <Card
        sx={{ height: "100%" }}
        style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.15)", overflow: "inherit" }}
      >
        <SoftBox p={3}>
          {selectedRow.length === 0 && (
            <SoftBox justifyContent="space-between" alignItems="center" mb={5}>
              <Grid container>
                <Grid md={10} xs={12}>
                  <SoftTypography fontSize={20} fontWeight="bold">
                    My Documents
                  </SoftTypography>
                </Grid>
                <Grid md={2} xs={12}>
                  <SoftBox>
                    {/* <Tooltip title="Evaluating for a Future Phase" placement="top-end">
                  <span>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="medium"
                      circular
                      disabled={true}
                      style={{
                        padding: "0px 15px 0px 3px",
                        marginRight: 15,
                      }}
                    >
                      <SoftBox px={1} component="img" src={search} style={{ width: 30 }} />
                      <SoftTypography
                        textGradient={true}
                        color="info"
                        fontSize={13}
                        fontWeight="medium"
                      >
                        Search
                      </SoftTypography>
                    </SoftButton>
                  </span>
                </Tooltip> */}
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="medium"
                      circular
                      onClick={() => setUploadOpen(true)}
                    // style={{ textTransform: "capitalize" }}
                    >
                      Upload Document
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          )}
          {selectedRow.length !== 0 && (
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={5}>
              <Grid container>
                <Grid md={6} xs={12}>
                  <SoftTypography fontSize={20} fontWeight="bold" marginBottom="5px">
                    {selectedRow.length} row(s) selected
                  </SoftTypography>
                </Grid>
                <Grid md={6} xs={12}>
                  <SoftBox>
                    <SoftButton
                      variant="outlined"
                      color="dark"
                      size="medium"
                      circular
                      style={{ marginRight: 10 }}
                      onClick={() => deleteRecord(selectedRow[0])}
                    >
                      <SoftBox px={1} component="img" src={deleteIcon} />
                      Delete Selected
                    </SoftButton>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="medium"
                      circular
                      style={{ marginRight: 10,marginTop:"5px", marginBottom: '5px' }}
                      onClick={() => shareDocument(selectedRow[0])}
                    >
                      <DoNotDisturbAltIcon style={{ marginRight: 3 }} />
                      Share Selected
                    </SoftButton>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="medium"
                      circular
                      onClick={() => unshareDocument(selectedRow[0])}
                    >
                      <PeopleAltIcon style={{ marginRight: 3 }} />
                      Unshare Selected
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          )}
          {loading ? (
            <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
              <CircularProgress size={25} color="info" />
            </SoftBox>
          ) : (
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              isCheckbox={true}
              onRowSelectStateChange={setSelectedRow}
              selectAll={true}
              noBorder
              table={{
                columns: [
                  { Header: "Document Type", accessor: "type", width: "20%" },
                  { Header: "File Name", accessor: "fileName", width: "18%" },
                  { Header: "Contact Info", accessor: "contact", width: "15%" },
                  {
                    Header: "Share Status",
                    accessor: "status",
                    width: "12%",
                    Cell: ({ row: { original } }) => {
                      // console.log(original)
                      switch (true) {
                        case original.status.toLowerCase() === "true":
                          return (
                            <SoftBox
                              display="flex"
                              style={{
                                background: "#E5F7FC",
                                padding: "4px 8px 0px",
                                width: 100,
                                justifyContent: "center",
                                borderRadius: 12,
                              }}
                            >
                              <PeopleAltIcon style={{ marginTop: 1 }} />
                              <SoftTypography
                                ml={0.5}
                                fontSize={12}
                                fontWeight="medium"
                                style={{ color: "#293E8F" }}
                              >
                                Shared
                              </SoftTypography>
                            </SoftBox>
                          );

                        case original.status.toLowerCase() === "false":
                          return (
                            <SoftBox
                              display="flex"
                              style={{
                                background: "#DCE3E8",
                                padding: "4px 8px 0px",
                                width: 100,
                                justifyContent: "center",
                                borderRadius: 12,
                              }}
                            >
                              <DoNotDisturbAltIcon style={{ marginTop: 1 }} />
                              <SoftTypography
                                ml={0.5}
                                fontSize={12}
                                fontWeight="medium"
                                style={{ color: "#3E5463" }}
                              >
                                Unshared
                              </SoftTypography>
                            </SoftBox>
                          );
                      }
                    },
                  },
                  { Header: "Updated On", accessor: "updateDate", width: "13%" },
                  // { Header: "Requested On", accessor: "requestDate", width: "14%" },
                  {
                    Header: "Action",
                    accessor: "id",
                    width: "10%",
                    Cell: ({ row: { original } }) => (
                      <SoftBox
                        display="flex"
                        alignItems="center" justifyContent="start"
                        className="action-btn"
                      >
                        <Tooltip title="View" placement="top">
                          <SoftBox>
                            <IconButton
                              sx={{ p: 0, mr: 1 }}
                              onClick={() => {
                                setDocument(original);
                                setViewDocs(true);
                              }}
                            >
                              <span
                                className="material-icons-outlined"
                                style={{ fontSize: 20, fontWeight: 700 }}
                              >
                                visibility
                              </span>
                            </IconButton>
                          </SoftBox>
                        </Tooltip>
                        <Tooltip title="Delete" placement="top">
                          <SoftBox>
                            <IconButton sx={{ p: 0 }}>
                              <span
                                className="material-icons-outlined"
                                style={{ fontSize: 20, fontWeight: 700 }}
                                onClick={() => singleDeleteRecord(original)}
                              >
                                delete
                              </span>
                            </IconButton>
                          </SoftBox>
                        </Tooltip>
                      </SoftBox>
                    ),
                  },
                ],
                rows: data,
              }}
            />
          )}
        </SoftBox>
        {deleteOpen ? (
          <DeleteDocument
            data={selectedData}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            setAlert={setAlert}
            setVisible={setVisible}
          />
        ) : null}
        {shareOpen ? (
          <ShareDocument
            data={selectedRow}
            open={shareOpen}
            setOpen={setShareOpen}
            setAlert={setAlert}
            setVisible={setVisible}
          />
        ) : null}
        {unshareOpen ? (
          <UnshareDocument
            data={selectedRow}
            open={unshareOpen}
            setOpen={setUnshareOpen}
            setAlert={setAlert}
            setVisible={setVisible}
          />
        ) : null}
        {uploadOpen ? (
          <DragDrop
            open={uploadOpen}
            setOpen={setUploadOpen}
            setAlert={setAlert}
            setVisible={setVisible}
            vis
          />
        ) : null}
        {viewDocs ? <ViewDocument open={viewDocs} setOpen={setViewDocs} data={document} /> : null}
      </Card>
    </SoftBox>
  );
}
