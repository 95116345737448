import * as Yup from "yup";
import checkout from "./form"
import { phoneRegExp } from "utils/constant";
const {
  formField: { fullName, lastName, email, facilityName, phone, address1, city, state, zip, company, licenseNumber },
} = checkout;

const validations = [
  Yup.object().shape({
    [fullName.name]: Yup.string().required(fullName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [lastName.name]: Yup.string().required(lastName.errorMsg).matches(/^(?!.{51})[a-zA-Z]+(?: [a-zA-Z]+(?: [a-zA-Z]+)?)?$/, "Alphabetical characters only."),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [facilityName.name]: Yup.string().required(facilityName.errorMsg),
    [phone.name]: Yup.string().required(phone.errorMsg).matches(phoneRegExp, "Phone number is not valid."),
    [licenseNumber.name]: Yup.string()
      .length(10, "License number is incorrect.")
      .matches(/^[0-9]{10}/, "License number is incorrect.")
      .required(licenseNumber.errorMsg),
    // [address1.name]: Yup.string().required(address1.errorMsg),
    [city.name]: Yup.string().required(city.errorMsg),
    [state.name]: Yup.object().required(state.errorMsg),
    // [company.name]: Yup.string().required(company.errorMsg),
    [zip.name]: Yup.string()
      .length(5, "Zip Code is invalid.")
      .matches(/^[0-9]{5}/, "Zip Code is invalid")
      .required(zip.errorMsg)
  }),
];

export default validations;
