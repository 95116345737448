import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { password, repeatPassword },
} = checkout;

const validations = [
  Yup.object().shape({
    [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/, "Password must match to the standard."),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
];

export default validations;
