import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftButton from 'components/SoftButton'
import SoftTypography from 'components/SoftTypography'
import ReusableDialog from 'examples/ReusableDialog'
import { request } from 'redux/actions/request'
import { fetchDocumentList } from 'redux/actions/wallet/documentAction'

export default function UnshareDocument(props) {
    const { data, open, setOpen, setAlert, setVisible } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    // share document 
    function unshareDocumentHandler() {
        setLoading(true)
        let payload = data.map(x => ({ Sharable: "False", Document_Id: x.id }))

        request("Document/Documents", "PUT", payload)
            .then(async () => {
                await fetchDocuments(100)
                setAlert({
                    heading: "Success!",
                    text: "Your Documents status has been successfully changed to Unshared.",
                    isError: false,
                    timer: 7000
                })
                setVisible(true)
                setLoading(false)
                setOpen(false)
            })
            .catch((err) => {
                setAlert({
                    heading: "Error",
                    text: err,
                    isError: true,
                })
                setVisible(true)
                setOpen(false)
            })
    }

    // fetch document list
    async function fetchDocuments(count) {
        return await dispatch(fetchDocumentList(count))
    }

    return (
        <ReusableDialog isOpen={open} title={data && data.length > 1 ? "Unshare Document(s)" : "Unshare Document"} handleClose={() => setOpen(false)}>
            <SoftBox>
                <SoftTypography color="dark" caption="text" fontSize={14} paragraph={true}>Are you sure you want to unshare?</SoftTypography>
                <SoftTypography color="dark" caption="text" fontSize={14} paragraph={true}>
                    <strong>Note:</strong>
                    Once you change the status to &quot;Unshared&quot;, your healthcare providers will no longer have access to your documents.
                </SoftTypography>
                <SoftBox display="flex" justifyContent="end" mt={5}>
                    <SoftButton
                        variant="text"
                        color="dark"
                        type="button"
                        onClick={() => setOpen(false)}
                        size="medium"
                        style={{ color: "#333333", textTransform: "initial", marginTop: 5, fontWeight: 500 }}
                        circular={true}>Cancel</SoftButton>
                    <SoftButton
                        variant="gradient"
                        color="info"
                        size="medium"
                        type="submit"
                        onClick={unshareDocumentHandler}
                        disabled={loading}
                        style={{ textTransform: "initial", fontWeight: 500, marginLeft: 10 }}
                        circular={true}>
                        {loading && <CircularProgress size={14} color="info" />}
                        {!loading && "Unshare Document(s)"}
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </ReusableDialog>
    )
}