// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import paymentImage from "assets/mock/payment-info1.jpg"
import paymentImage2 from "assets/mock/payment-info2.jpg"
import paymentImageA from "assets/mock/payment-info2a.jpg"
import paymentImageB from "assets/mock/payment-info2b.jpg"
import { useEffect, useState } from "react";
import SoftTypography from "components/SoftTypography";
import Checkbox from "@mui/material/Checkbox";
import { Field } from "formik";
import { Card, FormControlLabel, Grid } from "@mui/material";
import SoftButton from "components/SoftButton";
import { Form, Formik, FormikProps } from 'formik';
import FormField from "../FormField";
import { useDispatch } from "react-redux";
import { fetchProductList } from "redux/actions/enrollment/enrollmentAction";
import { useSelector } from "react-redux";
import { prototype } from "dropzone";
import { PRODUCT_VALUE_SELECTED } from "redux/constants/constants";
import { PRODUCT_VALUE_REMOVED } from "redux/constants/constants";
import { fontSize } from "@mui/system";


// NewUser page components

function PaymentInfo({ formData }) {
  const dispatch = useDispatch()
  // const { formField, values } = formData;
  // const { publicEmail, bio } = formField;
  // const { publicEmail: publicEmailV, bio: bioV } = values;
  // const [first, setFirst] = useState(true)
  // const [second, setSecond] = useState(false)
  const [productOptions, setProductOptions] = useState(null)
  const userDetails = localStorage.getItem("userDetails");
  const UserRole = userDetails ? JSON.parse(userDetails).Role : null;
  const loginErrdata = localStorage.getItem('loginErrResponse');
  const loginErrResponse = loginErrdata !== 'undefined' ? JSON.parse(loginErrdata) : null;
  // store
  const productData = useSelector(state => state.productList.productList);
  const productSelectValue = useSelector(state => state.productValue.productValue);

  
  const handleProductClick = (option) => {
    dispatch({type: PRODUCT_VALUE_SELECTED, payload: {...option}});
  }
  useEffect(() => {
    dispatch(fetchProductList())
  }, [])
  useEffect(() => {
    dispatch({type: PRODUCT_VALUE_REMOVED})
  }, [])
  useEffect(() => {
    if (productData.length > 0) {
      if(loginErrResponse?.IsInTrial) {
        const findTrailProduct = productData.filter((item) => item?.IsTrial); 
        dispatch({type: PRODUCT_VALUE_SELECTED, payload: {...findTrailProduct[0]}});
      } else {
        const findActualProduct = productData.filter((item) => !item?.IsTrial); 
        dispatch({type: PRODUCT_VALUE_SELECTED, payload: {...findActualProduct[0]}});
      }
    }

  }, [productData])

  useEffect(() => {
    if (productData?.length > 0) {
      const array = productData.map((item) => {
        return {
          ...item,
          value: item.ProductName,
          label: item.ProductType,
        }
      })
      setProductOptions(array)
    }
  }, [productData])
  return (
    <SoftBox>
      <SoftTypography variant="h5" fontWeight="bold" mb={2}>
        Payment Information
        <SoftTypography mb={2} variant="h6" fontWeight="bold" fontSize="12px"> {(loginErrResponse?.IsInTrial) ? " Try our Product for 14 days.  If you wish to cancel, please do so within the 14-days window." : "Your trial period has ended. To continue using the application, please subscribe."}</SoftTypography>
      </SoftTypography>
      <SoftBox>
        <Grid container gap={3}>
        {productOptions?.length > 0 && productOptions.map((option) =>(((option?.IsTrial === loginErrResponse?.IsInTrial)) ? <Card variant="outlined" key={option?.ProductId} sx={{ width: 170, height: 100, boxShadow: `${productSelectValue?.ProductId === option?.ProductId ? '0 2px 6px #17c1e8, 0 6px 6px #17c1e8' : "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"}`, borderRadius: '10px', display:'flex', justifyContent:'center', alignItems:'center' }} onClick={() => handleProductClick(option)}>
          <Grid style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}} padding={3}>
            {/* <SoftTypography variant="h5"   mb={0.5}>Subscription Period: {option.ProductType}</SoftTypography> */}
            {/* <SoftTypography variant="h5">$ {option.ProductPrice}</SoftTypography> */}
            <SoftTypography variant="h6">{option?.IsTrial ? 'Start Trial' : 'Pay for Subscription'} </SoftTypography>
            {/* <SoftTypography variant="h5">{option?.ProductType} </SoftTypography> */}

          </Grid>
          </Card> : ''))}</Grid>
      </SoftBox>
      {/* {fi.rst ? <SoftBox px={1} component="img" src={paymentImage} onClick={() => { setSecond(true); setFirst(false) }} style={{ width: '100%', cursor: 'pointer' }} /> : null}
      {second ?
        <>
          <SoftBox px={1} component="img" src={paymentImageA} style={{ width: '100%' }} />
          <SoftBox px={1} component="img" src={paymentImageB} style={{ width: '100%' }} />
        </> : null} */}
      {/* <SoftTypography variant="h5" fontWeight="bold">
        Profile
      </SoftTypography>
      <SoftBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormField
              type={publicEmail.type}
              label={publicEmail.label}
              name={publicEmail.name}
              value={publicEmailV}
              placeholder={publicEmail.placeholder}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={bio.type}
              label={bio.label}
              name={bio.name}
              value={bioV}
              placeholder={bio.placeholder}
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </SoftBox> */}
    </SoftBox >
  );
}

// typechecking props for Profile
PaymentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PaymentInfo;
