import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material"

import SoftBox from "components/SoftBox"
// import { StateList } from "./StateList";
import FormField from "../FormField";
import { StateList } from "./StateList";

const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]

export const PersonalForm = ({ formData }) => {
    const { userInfo: { data } } = useSelector(state => state)
    const statusOptions = StateList;
    const { formField, values, errors, setFieldValue, setErrors, touched } = formData;
    const { firstName, lastName, phone, address1, address2, city, state, gender, zip } = formField;
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedGender, setSelectedGender] = useState(null);

    useEffect(() => {
        if (data) {
            // debugger
            let state = StateList.filter(x => x.value === data.State)
            setFieldValue("firstName", data.FirstName)
            setFieldValue("lastName", data.LastName)
            setFieldValue("email", data.EmailAddress)
            setFieldValue("gender", data.Gender ? { label: data.Gender, value: data.Gender } : null)
            setFieldValue("phone", data.SMSCapableNumber)
            setFieldValue("address1", data.Street1)
            setFieldValue("address2", data.Street2)
            setFieldValue("city", data.City)
            setFieldValue("state", state.length ? { label: state[0].label, value: state[0].value } : null)
            setFieldValue("zip", data.PostalCode)
            setSelectedStatus(state.length ? { label: state[0].label, value: state[0].value } : null)
            setSelectedGender(data.Gender ? { label: data.Gender, value: data.Gender } : null)

            setTimeout(() => setErrors({}), 0)
        }
    }, [data])

    async function onChangeStatus(value) {
        setSelectedStatus(value);
        setFieldValue("state", value);
    }

    async function onChangeGender(value) {
        setSelectedGender(value);
        setFieldValue("gender", value);
    }

    return (
        <SoftBox>
            {/* <SoftBox>{JSON.stringify({ values, errors })}</SoftBox> */}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={firstName.type}
                        label={firstName.label}
                        name={firstName.name}
                        value={values.firstName}
                        placeholder={firstName.placeholder}
                        error={errors.firstName && touched.firstName}
                        success={values.firstName.length > 0 && !errors.firstName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={lastName.type}
                        label={lastName.label}
                        name={lastName.name}
                        value={values.lastName}
                        placeholder={lastName.placeholder}
                        error={errors.lastName && touched.lastName}
                        success={values.lastName.length > 0 && !errors.lastName}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FormField
                        fieldType="phone"
                        helper="US Number Only"
                        id={phone.name}
                        type={phone.type}
                        name={phone.name}
                        setFieldValue={setFieldValue}
                        label={phone.label}
                        // value={values.phone}
                        placeholder={phone.placeholder}
                        error={errors.phone && touched.phone}
                        success={values.phone.length > 0 && !errors.phone}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormField
                        fieldType="select"
                        type={gender.type}
                        label={gender.label}
                        name={gender.name}
                        options={genderOptions}
                        defaultValue={selectedGender}
                        value={selectedGender}
                        onChange={onChangeGender}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder={gender.placeholder}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormField
                        type={address1.type}
                        label={address1.label}
                        name={address1.name}
                        value={values.address1}
                        placeholder={address1.placeholder}
                        error={errors.address1 && touched.address1}
                        success={values.address1.length > 0 && !errors.address1}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SoftBox>
                        <FormField
                            type={address2.type}
                            name={address2.name}
                            value={values.address2}
                            label=""
                            placeholder={address2.placeholder}
                        />
                    </SoftBox>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <FormField
                        type={city.type}
                        name={city.name}
                        value={values.city}
                        label=""
                        placeholder={city.placeholder}
                        error={errors.city && touched.city}
                        success={values.city.length > 0 && !errors.city}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormField
                        fieldType="select"
                        type={state.type}
                        label={""}
                        name={state.name}
                        options={statusOptions}
                        defaultValue={selectedStatus}
                        value={selectedStatus}
                        onChange={onChangeStatus}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder={state.placeholder}
                        error={errors.state && touched.state}
                        success={values.state.length > 0 && !errors.state}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormField
                        type={zip.type}
                        name={zip.name}
                        label=""
                        value={values.zip}
                        placeholder={zip.placeholder}
                        error={errors.zip && touched.zip}
                        success={values.zip.length > 0 && !errors.zip}
                    />
                </Grid>
            </Grid>
        </SoftBox>
    )
}
