import * as Actions from "../../constants/constants";
import { request } from "../request";

export function saveState(data) {
    return (dispatch) => {
        dispatch({
            type: Actions.STATE_INFO,
            payload: data,
        });
    };
}

export const fetchProductList = () => (dispatch) => {
    return request(`Payment/ProductList`, "GET", {})
        .then((response) => {
            dispatch({
                type: Actions.PRODUCT_LIST_SUCCESS,
                payload: response.Data,
            });
            return Promise.resolve(response.Data)
        })
        .catch((error) => {
            dispatch({
                type: Actions.PRODUCT_LIST_FAILED,
                payload: error
            })
        });
}

export const stripeSubscriptionCancellation = (payload) => (dispatch) => {
    return request(`Payment/UpgradePaymentStatus?customerId=${payload.customerId}`, "GET", {})
        .then((response) => {
            dispatch({
                type: Actions.SCUBSCRIBE_CANCEL_SUCCESS,
                payload: response.Data,
            });
            return Promise.resolve(response.Data)
        })
        .catch((error) => {
            dispatch({
                type: Actions.SCUBSCRIBE_CANCEL_FAILED,
                payload: error
            })
        });
}