import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./redux/store";

//  Context Provider
import { SoftUIControllerProvider } from "context";
import { AuthProvider } from "context/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <SoftUIControllerProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </SoftUIControllerProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>
);
