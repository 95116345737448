/**
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/authentication/enrollment/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    // company,
    state,
    email,
    password,
    repeatPassword,
    phone,
    agree,
    address1,
    address2,
    city,
    zip,
    // twitter,
    // facebook,
    // instagram,
    // publicEmail,
    // bio,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [state.name]: "",
  [agree.name]: "",
  // [company.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [phone.name]: "",
  // [type.name]: "",
  [address1.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zip.name]: "",
  // [twitter.name]: "",
  // [facebook.name]: "",
  // [instagram.name]: "",
  // [publicEmail.name]: "",
  // [bio.name]: "",
};

export default initialValues;
