import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import FormField from "../FormField";
import SoftTypography from "components/SoftTypography";
import { decode as base64_decode } from "base-64";

function LoginInfo({ formData, rememberMe, setRememberMe }) {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { email, password } = formField;
  const { email: emailV, password: passwordV } = values;
  // const [rememberMe, setRememberMe] = useState(true);
  const [agreement, setAgremment] = useState(false);

  useEffect(() => {
    let cred = localStorage.getItem("Credentails");
    if (cred !== null) {
      let parseCred = JSON.parse(cred);
      let decodePassword = base64_decode(parseCred.password);
      setFieldValue("email", parseCred.email);
      setFieldValue("password", decodePassword);
      setRememberMe(true)
    }
  }, []);

  function handleSetRememberMe() {
    return setRememberMe(!rememberMe);
  }

  function handleSetAgremment() {
    return setAgremment(!agreement);
  }

  return (
    <>
      <SoftBox mb={2}>
        <FormField
          type={email.type}
          label={email.label}
          name={email.name}
          value={emailV}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={emailV.length > 0 && !errors.email}
        />
      </SoftBox>
      <SoftBox mb={2}>
        <FormField
          type={password.type}
          label={password.label}
          name={password.name}
          value={passwordV}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={passwordV.length > 0 && !errors.password}
          inputProps={{ autoComplete: "" }}
        />
      </SoftBox>
      <SoftBox display="flex" alignItems="center">
        <Switch checked={rememberMe} onChange={handleSetRememberMe} color={"success"} />
        <SoftTypography
          variant="caption"
          fontWeight="regular"
          fontSize={14}
          onClick={handleSetRememberMe}
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          &nbsp;&nbsp;Remember me
        </SoftTypography>
      </SoftBox>
      {/* <SoftBox display="flex" mt={2} alignItems="center">
        <Checkbox checked={agreement} onChange={handleSetAgremment} />
        <SoftTypography
          variant="button"
          fontWeight="regular"
          onClick={handleSetAgremment}
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          &nbsp;&nbsp;I agree the&nbsp;
        </SoftTypography>
        <SoftTypography component="a" href="#" variant="button" fontWeight="medium">
          Terms and Conditions
        </SoftTypography>
      </SoftBox> */}
    </>
  );
}

// typechecking props for UserInfo
LoginInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LoginInfo;
