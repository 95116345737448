import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, CircularProgress } from '@mui/material'
import { fetchDocumentList } from 'redux/actions/wallet/documentAction';
// Soft UI Dashboard PRO React examples
import DataTable from "examples/Tables/DataTable";
import SoftBox from 'components/SoftBox'
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';

export const UploadTable = (formData) => {
    const { activeStep, setActiveStep } = formData;
    const [tableList, setTableList] = useState([])
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const { documentList: { data } } = useSelector(state => state)

    // Document List
    useEffect(() => {
        if (!data) fetchDocuments(100)
        else {
            let list = data.map((x) => ({
                type: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType.toLowerCase() === "other"
                ? x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName
                : x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
                name: x.Document_Name,
                contact: x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName
            }))
            setTableList(list)
            setLoading(false)
        }
    }, [data])

    // fetch document list
    async function fetchDocuments(count) {
        return await dispatch(fetchDocumentList(count))
    }

    return (
        <SoftBox mt={2}>
            <Card sx={{ height: "100%" }} style={{ boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.15)', overflow: 'inherit' }}>
                <SoftBox p={2.5}>
                    {loading ? <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
                        <CircularProgress size={25} color="info" />
                    </SoftBox>
                        : <>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={1.8} pl={2.8}>
                                <SoftTypography fontSize={18} fontWeight="bold" textGradient={true} color="info">Uploaded Documents</SoftTypography>
                            </SoftBox>
                            <DataTable
                                showTotalEntries={false}
                                entriesPerPage={false}
                                emptyMessage={"No files to preview"}
                                table={{
                                    columns: [
                                        { Header: "Document Type", accessor: "type", width: "35%" },
                                        { Header: "File Name", accessor: "name", width: "25%" },
                                        { Header: "Contact Info", accessor: "contact", width: "35%" },
                                    ],
                                    rows: tableList
                                }}
                            />
                        </>
                    }
                    <SoftBox width="100%" display="flex" justifyContent="end" mt={2}>
                        <SoftButton
                            variant="text"
                            circular={true}
                            color="dark"
                            onClick={() => setActiveStep(activeStep - 1)}
                            style={{ marginRight: 10, padding: 13, textTransform: 'capitalize', fontWeight: 500 }}>
                            back
                        </SoftButton>
                        <SoftButton
                            type="button"
                            variant="gradient"
                            color="info"
                            circular={true}
                            disabled={!data ? true : false}
                            style={{ textTransform: 'capitalize', fontWeight: 500 }}
                            onClick={() => setActiveStep(activeStep + 1)}
                        >
                            {!data ? <CircularProgress size={14} color="info" /> : data.length ? "Continue" : "Skip & Continue"}
                        </SoftButton>
                    </SoftBox>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}
