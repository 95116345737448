
import checkout from "./form";

const {
  formField: {
    password,
    repeatPassword,
  },
} = checkout;

const initialValues = {
  [password.name]: "",
  [repeatPassword.name]: "",
};

export default initialValues;
