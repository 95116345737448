import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { Card, Menu, MenuItem } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftButton from 'components/SoftButton'
import SoftTypography from 'components/SoftTypography'
import { Logout } from 'redux/actions/loginAction'
import MenuIcon from '@mui/icons-material/Menu';
import brand from "./logo.svg"
import './style.css';
import { useState } from 'react'

export const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        dispatch(Logout())
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("FullName");
        localStorage.removeItem("Email");
        navigate("/login");
    };

    return (
        <>
            <SoftBox>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftBox display="flex" justifyContent="center">
                        <SoftBox px={1} component="img" src={brand} style={{ width: 200 }} />
                    </SoftBox>
                    <SoftBox display={{ xs: 'none', md: 'flex' }}>
                        <Card style={{ borderRadius: 50, boxShadow: 'none' }}>
                            <SoftBox display="flex" py={1} px={2}>
                                <NavLink to="/dashboard/profile" activeclassname='is-active'>
                                    <SoftButton
                                        variant="outlined"
                                        color="dark"
                                        size="small"
                                        circular
                                        style={{ textTransform: 'capitalize', border: 0, fontSize: 16, fontWeight: 600 }}
                                    >My Profile
                                    </SoftButton>
                                </NavLink>
                                <NavLink to="/dashboard/mydocs" activeclassname='is-active'>
                                    <SoftButton
                                        variant="outlined"
                                        circular
                                        size="small"
                                        color="dark"
                                        style={{ marginLeft: 8, textTransform: 'capitalize', border: 0, fontSize: 16, fontWeight: 600 }}
                                    >My Docs
                                    </SoftButton>
                                </NavLink>
                                {/* <NavLink to="/dashboard/settings" activeclassname='is-active' style={{ pointerEvents: 'none' }}>
                                <SoftButton
                                    variant="outlined"
                                    circular
                                    size="small"
                                    color="dark"
                                    disabled={true}
                                    style={{ marginLeft: 8, textTransform: 'capitalize', border: 0, fontSize: 16, fontWeight: 600 }}
                                >Settings
                                </SoftButton>
                            </NavLink> */}
                            </SoftBox>
                        </Card>
                    </SoftBox>
                    <SoftBox display={{ xs: 'none', md: 'flex' }}>
                        <SoftButton
                            variant="text"
                            onClick={handleLogOut}
                            style={{ textTransform: 'capitalize' }}>
                            <SoftTypography textGradient={true} color="info" fontWeight="medium" fontSize={16}>Sign Out</SoftTypography>
                        </SoftButton>
                    </SoftBox>
                    <SoftBox display={{ xs: 'flex', md: 'none' }}>
                        <SoftButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                            <MenuIcon />
                        </SoftButton>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <SoftBox>
                <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                    <MenuItem onClick={handleMenuClose} component={NavLink} to="/dashboard/profile">
                        My Profile
                    </MenuItem>
                    <MenuItem onClick={handleMenuClose} component={NavLink} to="/dashboard/mydocs">
                        My Docs
                    </MenuItem>
                    <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
                </Menu>
            </SoftBox>
        </>
    )
}
