import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import RequestDocument from "./component/RequestDocument";

const Requestdata = () => {
  return (
    <SoftBox>
      <Grid container justifyContent="center" sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <RequestDocument />
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default Requestdata;
