import checkout from "./form";

const {
  formField: {
    fullName,
    state,
    email,
    facilityName,
    phone,
    address1,
    company,
    address2,
    city,
    zip,
    licenseNumber
  },
} = checkout;

const initialValues = {
  [fullName.name]: "",
  [state.name]: "",
  [email.name]: "",
  [facilityName.name]: "",
  [phone.name]: "",
  [address1.name]: "",
  [company.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zip.name]: "",
  [licenseNumber.name]: "",
};

export default initialValues;
