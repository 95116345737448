// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import SoftSelect from "components/SoftSelect";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// import SoftInput from "components/SoftInput";

// NewUser page components
import { StateList } from "./StateList";
// import { saveState } from "redux/actions/enrollment/enrollmentAction";
import { useState } from "react";
import FormField from "../FormField";

function PersonalInfo({ formData, isHide, role, userRole }) {
  // console.log(formData)
  // const [state, setState] = useState(null);
  // const [gender, setGender] = useState(null);
  // const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { type, phone, address1, address2, city, state, zip } = formField;
  const {
    address1: address1V,
    address2: address2V,
    city: cityV,
    state: stateV,
    zip: zipV,
    phone: phoneV,
    type: typeV,
  } = values;

  // const handleSetState = (event) => setState(event.target.value);
  const statusOptions = StateList;
  const typeOptions = [
    { label: "Phone", value: "phone" },
    { label: "Mobile", value: "mobile" },
  ];

  async function onChangeStatus(value) {
    setSelectedStatus(value);
    setFieldValue("state", value);
  }

  // function onChangeType(value) {
  //   setSelectedType(value);
  //   setFieldValue("type", value.value);
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold" mb={0.5}>
          Personal Information
        </SoftTypography>
        <SoftBox mb={1.5}>
          <SoftTypography
            variant="caption"
            fontWeight="light"
            fontSize={14}
            style={{ color: "#94A3B8" }}
          >
            {isHide
              ? `Enter ${
                  userRole &&
                  userRole.toLowerCase() === "superadmin" &&
                  role &&
                  role.toLowerCase() === "administrator"
                    ? "Admin's"
                    : "Member's"
                } contact information below.`
              : "Enter your contact information so that we know how to reach you."}
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftTypography
            variant="caption"
            fontWeight="regular"
            fontStyle="italic"
            fontSize={10.5}
            display="block"
          >
            (All fields are required unless specified optional)
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={2.8}>
        <Grid container spacing={3}>
          {/* <Grid item xs={4}>
            <FormField
              fieldType="select"
              type={type.type}
              label={type.label}
              name={type.name}
              options={typeOptions}
              defaultValue={selectedType}
              value={selectedType}
              onChange={onChangeType}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder={type.placeholder}
              error={errors.type && touched.type}
              success={typeV.length > 0 && !errors.type}
            />
          </Grid> */}
          <Grid item xs={12}>
            {/* <FormField
              type={phone.type}
              name={phone.name}
              label={phone.label}
              value={phoneV}
              placeholder={phone.placeholder}
              error={errors.phone && touched.phone}
              success={phoneV.length > 0 && !errors.phone}
            /> */}
            <FormField
              fieldType="phone"
              id={phone.name}
              helper="US Number Only"
              type={phone.type}
              name={phone.name}
              setFieldValue={setFieldValue}
              label={phone.label}
              // value={values.phone}
              placeholder={phone.placeholder}
              error={errors.phone && touched.phone}
              success={values.phone.length > 0 && !errors.phone}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormField
              type={address1.type}
              label={address1.label}
              name={address1.name}
              value={address1V}
              placeholder={address1.placeholder}
              error={errors.address1 && touched.address1}
              success={address1V.length > 0 && !errors.address1}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SoftBox>
              <FormField
                type={address2.type}
                name={address2.name}
                value={address2V}
                label=""
                placeholder={address2.placeholder}
              />
            </SoftBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type={city.type}
              name={city.name}
              value={cityV}
              label=""
              placeholder={city.placeholder}
              error={errors.city && touched.city}
              success={cityV.length > 0 && !errors.city}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormField
              fieldType="select"
              type={state.type}
              label={""}
              name={state.name}
              options={statusOptions}
              defaultValue={values.state}
              value={values.state}
              onChange={onChangeStatus}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              placeholder={state.placeholder}
              error={errors.state && touched.state}
              success={stateV.length > 0 && !errors.state}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormField
              type={zip.type}
              name={zip.name}
              label=""
              value={zipV}
              placeholder={zip.placeholder}
              error={errors.zip && touched.zip}
              success={zipV.length > 0 && !errors.zip}
            />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Address
PersonalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PersonalInfo;
