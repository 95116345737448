export const First = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
            <path d="M11.2 18H13.76V5.952H13.568L8.56 7.904V10.48L11.2 9.408V18Z" fill="url(#paint0_linear_3067_4157)" />
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint1_linear_3067_4157)" />
            <defs>
                <linearGradient id="paint0_linear_3067_4157" x1="8" y1="0" x2="22.4" y2="4.8" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B5E2" />
                    <stop offset="1" stopColor="#032FCA" />
                </linearGradient>
                <linearGradient id="paint1_linear_3067_4157" x1="0" y1="0" x2="24" y2="24" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B5E2" />
                    <stop offset="1" stopColor="#032FCA" />
                </linearGradient>
            </defs>
        </svg>
    )
}