/* eslint-disable react/prop-types */


import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-phone-input-2 components
import PhoneInput from 'react-phone-input-2'

//  base styles
import colors from "assets/theme/base/colors";
import 'react-phone-input-2/lib/style.css'

// Custom styles for SoftPhoneInput
// import styles from "components/SoftPhoneInput/styles";

const SoftPhoneInput = forwardRef(({ onChange, required, setFieldValue, name, size, error, touched, success, ...rest }, ref) => {
    const { light } = colors;

    const onChangePhone = (value, country, event, formattedValue) => {
        setFieldValue(name, formattedValue)
    }

    const handleKeyPress = (event) => {
        if (event.ctrlKey && event.keyCode === 65 && event.target.value.startsWith('+1')) {
            event.preventDefault();
        }
        if (event.key === 'Backspace' && event.target.value.length <= 3) {
            event.preventDefault();
        }
        // Prevent deletion of the "+1" prefix
        if (event.target.value.startsWith('+1') && event.target.selectionStart < 3) {
        return;
        }
    }


    return (
        <PhoneInput
            {...rest}
            ref={ref}
            name={name}
            specialLabel={''}
            onChange={onChangePhone}
            onKeyDown={handleKeyPress}
            onFocus={handleKeyPress}
            containerClass={touched && error ? 'error' : ''}
            disableDropdown
            // styles={styles(size, error, success)}
            country={'us'}
            inputStyle={{
                ...light,
                borderColor: (touched && error) && "red",
                width: '100%',
                height: 40
            }}
            inputProps={{
                name: name,
                required: required ?? false,
                // autoFocus: true
            }}
        />
    );
});

// Setting default values for the props of SoftPhoneInput
SoftPhoneInput.defaultProps = {
    size: "medium",
    error: false,
    success: false,
};

// Typechecking props for the SoftPhoneInput
SoftPhoneInput.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    error: PropTypes.bool,
    success: PropTypes.bool,
};

export default SoftPhoneInput;
