import { useState } from "react";
import { Form, Formik } from "formik";
import { CircularProgress, Grid } from "@mui/material";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

//  components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import initialValues from "../schemas/initialValues";
import validations from "../schemas/validations";
import form from "../schemas/form";
import SignUp from "../components/Signup";
import Footer from "layouts/authentication/components/Footer";
import { request } from "redux/actions/request";
import AlertPop from "layouts/authentication/sign-in/basic/components/LoginInfo/Alert";

function Basic() {
  const [timer, setTimer] = useState(7000);
  const [isDanger, setIsDanger] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [agree, setAgree] = useState(false);
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [successHeading, setSuccessHeading] = useState("");

  function signup(formData) {
    return <SignUp formData={formData} />;
  }

  const submitForm = (values, actions) => {
    setLoading(true);
    const payload = {
      FirstName: values.firstName,
      LastName: values.lastName,
      EmailId: values.email,
      State: values.state.value,
    };
    request("SignUp", "POST", payload)
      .then(() => {
        setSuccessHeading("Your Signup Verification was Successful!");
        setOpen(false);
        setIsSubscribed(true);
        setEmail(values.email);
        setIsDanger(false);
        actions.resetForm();
        setLoading(false);
        setTimer(7000);
      })
      .catch((err) => {
        setIsSubscribed(false);
        setSuccessHeading("Error");
        setOpen(true);
        setIsDanger(true);
        setError(err);
        setTimer(null);
        setLoading(false);
      });
  };

  function ResentEmail() {
    request("SignUp/SendActivationLinkToEmailAddress", "POST", { EmailId: email })
      .then(() => {
        setSuccessHeading(`Email Verification Successfully Resent`);
        setIsDanger(false);
        setOpen(true);
      })
      .catch((err) => {
        setSuccessHeading("Error");
        setError(err);
        setOpen(true);
        setIsDanger(true);
      });
  }

  return (
    <SoftBox>
      <SoftBox style={{ minHeight: "calc(100vh - 50px)" }}>
        <BasicLayout>
          <AlertPop
            isDanger={isDanger ?? false}
            toggle={setOpen}
            isOpen={open}
            timer={timer}
            heading={successHeading}
          >
            {isDanger ? (
              error
            ) : (
              <SoftBox>
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize={13}
                  display="block"
                >
                  We sent an email to {email} with a link. Please click the link to complete the
                  verification.
                </SoftTypography>
              </SoftBox>
            )}
          </AlertPop>
          <SoftBox mt={10}>
            <Card style={{ boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2)", overflow: "inherit" }}>
              {!isSubscribed && (
                <SoftBox px={8} py={5}>
                  <SoftBox mb={1} textAlign="center">
                    <SoftTypography variant="h3" fontWeight="bold" fontSize={32} style={{wordWrap:"normal"}}>
                      Subscribe to Our Newsletter!
                    </SoftTypography>
                    <SoftTypography
                      variant="caption"
                      fontWeight="regular"
                      fontSize={14.5}
                      display="block"
                    >
                      Get free tips, articles, news, and resources in your inbox every week
                    </SoftTypography>
                    <SoftBox mt={4} mb={2.5} textAlign="left">
                      <SoftTypography
                        variant="caption"
                        fontWeight="regular"
                        fontStyle="italic"
                        fontSize={12.5}
                        display="block"
                        style={{ color: "rgba(0, 0, 0, 0.8)" }}
                      >
                        (All fields are required)
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={currentValidation}
                      onSubmit={submitForm}
                    >
                      {({ values, errors, touched, setFieldValue }) => (
                        <Form id={formId} autoComplete="off">
                          <SoftBox>
                            {signup({
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                              submitForm,
                              agree,
                              setAgree,
                            })}
                            <Grid container spacing={2} justifyContent="center">
                              <Grid item sm={6} xs={12}>
                                <SoftBox mb={1}>
                                  <Link to="/login">
                                    <SoftButton
                                      variant="outlined"
                                      color="info"
                                      type="submit"
                                      fullWidth
                                      disabled={loading}
                                      circular={true}
                                      style={{
                                        textTransform: "capitalize",
                                        fontSize: 16,
                                        fontWeight: 500,
                                      }}
                                    >
                                      Cancel
                                    </SoftButton>
                                  </Link>
                                </SoftBox>
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <SoftBox mb={1}>
                                  <SoftButton
                                    variant="gradient"
                                    color="info"
                                    type="submit"
                                    disabled={!agree || loading}
                                    circular={true}
                                    fullWidth
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {!loading && "Subscribe"}
                                    {loading && <CircularProgress size={23} color="white" />}
                                  </SoftButton>
                                </SoftBox>
                              </Grid>
                            </Grid>
                          </SoftBox>
                        </Form>
                      )}
                    </Formik>
                  </SoftBox>
                </SoftBox>
              )}
              {isSubscribed && (
                <SoftBox px={6} py={5}>
                  <SoftTypography variant="h5" fontWeight="bold" mb={2}>
                    Thanks for subscribing! Read below for one last step:
                  </SoftTypography>
                  <SoftBox mb={4}>
                    <SoftTypography mb={2.5} variant="caption" fontSize={14} display="block">
                      An email was sent to{" "}
                      <SoftTypography
                        fontSize={14}
                        variant="caption"
                        color="info"
                        fontWeight="bold"
                      >
                        {email}
                      </SoftTypography>{" "}
                      with a link to verify your email address. Please{" "}
                      <strong>click the link</strong> to complete your verification.
                    </SoftTypography>
                    <SoftTypography variant="caption" fontSize={14} display="block">
                      If you don’t see the email within a couple of minutes, make sure to check your
                      promotions/spam folder.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={5}>
                    <SoftTypography variant="caption" fontSize={14} display="block">
                      Did not receive the email? &nbsp;
                      <Link to="#" onClick={ResentEmail}>
                        <SoftTypography
                          style={{ textDecoration: "underline" }}
                          fontSize={14}
                          variant="caption"
                          fontWeight="medium"
                          color="info"
                        >
                          Click here to resend
                        </SoftTypography>
                      </Link>
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox px={6}>
                    <Link to="/enrollment">
                      <SoftButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        size="large"
                        circular={true}
                        style={{ textTransform: "capitalize", fontWeight: 500 }}
                      >
                        Continue to Enrollment
                      </SoftButton>
                    </Link>
                  </SoftBox>
                </SoftBox>
              )}
            </Card>
            {!isSubscribed && (
              <SoftBox pt={2} textAlign="center">
                <SoftTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize={14}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;Interested in one of our paid services?&nbsp;
                </SoftTypography>
                <Link to="/enrollment" onClick={()=> localStorage.removeItem('loginErrResponse')}>
                  <SoftTypography
                    variant="caption"
                    color="info"
                    fontWeight="bold"
                    fontSize={14}
                    textGradient={true}
                    style={{ zIndex: 0 }}
                  >
                    &nbsp;Enroll Now
                  </SoftTypography>
                </Link>
              </SoftBox>
            )}
          </SoftBox>
        </BasicLayout>
      </SoftBox>
      <Footer />
    </SoftBox>
  );
}

export default Basic;
