import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { format } from "date-fns";

import { Welcome } from "./Welcome";
import MyIdCard from "./component/MyIdCard";
import MyAccount from "./component/MyAccount";

import SoftBox from "components/SoftBox";

import { fetchDocumentList } from "redux/actions/wallet/documentAction";
import { fetchUserInformation } from "redux/actions/users";
import MyDocument from "./component/MyDocument";
import { useNavigate } from "react-router-dom";

const MyDocs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentList, userInfo } = useSelector((state) => state);
  const [tableList, setTableList] = useState(null);

  const userData = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (userData && userData.Role && (userData.Role.toLowerCase() === "administrator" || userData.Role.toLowerCase() === "superadmin"))
      navigate("/dashboard/admin");
    if (!userInfo.data) fetchUserInfo();
  }, [userInfo.data]);

  useEffect(() => {
    if (documentList.data) {
      let list = [];
      if (documentList.data.length > 5) {
        for (let i = 0; i < 5; i++) {
          const x = documentList.data[i];
          list.push({
            id: x.Document_Id,
            status: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.Sharable,
            type:
              x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType === "Other"
                ? x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName
                : x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
            fileName: x.Document_Name,
            contact:
              x.UserDocumentContact.Contact_FirstName +
              " " +
              x.UserDocumentContact.Contact_LastName,
            updateDate: x.Created_On ? format(new Date(x.Created_On), "MMM d, y") : null,
            requestDate: x.Updated_On ? format(new Date(x.Updated_On), "MMM d, y") : null,
            extension: x.DocumentStorage.DocumentFiles[0].DocumentFileExtension,
            name:
              x.UserDocumentContact.Contact_FirstName +
              " " +
              x.UserDocumentContact.Contact_LastName,
            phone: x.UserDocumentContact.Contact_Phone,
            email: x.UserDocumentContact.Contact_Email,
            address:
              x.UserDocumentContact.Contact_Email.Contact_Street1 +
              ", " +
              x.UserDocumentContact.Contact_Email.Contact_Street2 +
              ", " +
              x.UserDocumentContact.Contact_Email.Contact_State +
              " " +
              x.UserDocumentContact.Contact_Email.Contact_PostalCode,
          });
        }
        setTableList(list);
      } else {
        list = documentList.data.map((x) => ({
          id: x.Document_Id,
          status: x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.Sharable,
          type:
            x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType === "Other"
              ? x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentTypeOtherName
              : x.DocumentStorage.DocumentFiles[0].DocumentFileMetaData.DocumentType,
          fileName: x.Document_Name,
          contact:
            x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName,
          updateDate: x.Created_On ? format(new Date(x.Created_On), "MMM d, y") : null,
          requestDate: x.Updated_On ? format(new Date(x.Updated_On), "MMM d, y") : null,
          extension: x.DocumentStorage.DocumentFiles[0].DocumentFileExtension,
          name:
            x.UserDocumentContact.Contact_FirstName + " " + x.UserDocumentContact.Contact_LastName,
          phone: x.UserDocumentContact.Contact_Phone,
          email: x.UserDocumentContact.Contact_Email,
          address:
            x.UserDocumentContact.Contact_Email.Contact_Street1 +
            ", " +
            x.UserDocumentContact.Contact_Email.Contact_Street2 +
            ", " +
            x.UserDocumentContact.Contact_Email.Contact_State +
            " " +
            x.UserDocumentContact.Contact_Email.Contact_PostalCode,
        }));
        // console.log(documentList.data[0].UserDocumentContact)
        setTableList(list);
      }
    } else fetchDocuments(100);
  }, [documentList.data]);

  // fetch document list
  async function fetchDocuments(count) {
    return await dispatch(fetchDocumentList(count));
  }

  // Fetch UserInfo
  async function fetchUserInfo() {
    let email = localStorage.getItem("Email");
    return await dispatch(fetchUserInformation(email));
  }

  return (
    <SoftBox>
      <Grid container spacing={5} justifyContent="center">
        <Grid item lg ={6} xs={12}>
          <Welcome data={userInfo.data} />
          <MyIdCard />
        </Grid>
        <Grid item lg ={6} xs={12}>
          <MyAccount />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <MyDocument data={tableList} loading={tableList === null ? true : false} />
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default MyDocs;
